import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { BiDonateHeart } from "react-icons/bi";
import { BsDot } from "react-icons/bs";
import "./BirthFundPage.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TimeAgo from 'timeago-react';

const DonationCard = ({ donation }) => {
  return (
    <div className="bfp_donation">
      <BiDonateHeart />
      <div className="bfpd_info">
        <p>{donation.name}</p>
        <p>
          ${+(donation.amount)/100} <BsDot />  <TimeAgo datetime={new Date(donation.time * 1000)} />
        </p>
      </div>
    </div>
  );
};

const BirthFundPage = () => {
  const [client, setClient] = useState();
  const { id } = useParams();
  const [amountRaised, setAmountRaised] = useState(0);
  const [amountToRaise, setAmountToRaise] = useState(0);
  const onCopy = async (link) => {
    navigator.clipboard.writeText(link);
    toast.success("Copied Doantion Link!");
  };
  const getClientData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/getClient/${id}`
      );
      setClient(response.data);
      setAmountRaised(response.data.donation.amount_received/100);
      setAmountToRaise(response.data.donation.amount/100);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getClientData();
  }, [id]);

  // const [donations, setDonations] = useState([
  //   {
  //     name: "Deep Agarwal",
  //     amount: "500",
  //     days: "5",
  //   },
  //   {
  //     name: "Deep Agarwal",
  //     amount: "500",
  //     days: "5",
  //   },
  //   {
  //     name: "Deep Agarwal",
  //     amount: "500",
  //     days: "5",
  //   },
  //   {
  //     name: "Deep Agarwal",
  //     amount: "500",
  //     days: "5",
  //   },
  //   {
  //     name: "Deep Agarwal",
  //     amount: "500",
  //     days: "5",
  //   },
  //   {
  //     name: "Deep Agarwal",
  //     amount: "500",
  //     days: "5",
  //   },
  //   {
  //     name: "Deep Agarwal",
  //     amount: "500",
  //     days: "5",
  //   },
  // ]);

  return (
    <div>
      <Navbar />
      <div className="birth_fund_page">
        <div className="bfp_left">
          <img src={client?.headshot} alt="" />
          <p>
            Name: <span className="bfp_green">{client?.name}</span>{" "}
          </p>
          <p>
            Due Date: <span className="bfp_green">{client?.dueDate}</span>{" "}
          </p>
          <p>
            Location: <span className="bfp_green">{client?.location}</span>{" "}
          </p>
        </div>
        <div className="bfp_right">
          <p className="bfp_title">{client?.donation.title}</p>
          <p className="bfp_desc">{client?.donation.description}</p>
        </div>
        <div className="bfp_fund_details">
          <div className="bfp_progress">
            <p className="bfp_amount_raised">
              <span>${amountRaised}</span> raised of ${amountToRaise}
            </p>
            <div className="bfp_progress_bar">
              <div className="pb_left" style={{ flex: amountRaised }}></div>
              <div
                className="pb_right"
                style={{ flex: amountToRaise - amountRaised }}
              ></div>
            </div>
          </div>
          <button
            className="bfp_share"
            onClick={() => onCopy(`${window.location.origin}/birthfund/${id}`)}
          >
            Share
          </button>
          <a href={client?.donation?.paymentLink} target="_blank" className="bfp_donate">
            Donate Now
          </a>
          <div className="bfp_donations">
            <p className="bfp_rd">Recent Donations:</p>
            {client?.donationReceived.map((donation) => (
              <DonationCard donation={donation} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthFundPage;
