import React, { useContext, useEffect, useState } from "react";
import "./ReviewModal.css";
import { MdClose } from "react-icons/md";
import axios from "axios";
import authContext from "../../contexts/Login";

const questions = [
  { question: "Choose Your Nearest Location", parameter: "location" },
  {
    question: "Will you be a first-time parent?",
    parameter: "firstTimeParent",
  },
  { question: "Age", parameter: "age" },
  { question: "Gender", parameter: "gender" },
  { question: "Affirming Pronouns", parameter: "pronouns" },
  { question: "Relationship Status", parameter: "relationshipStatus" },
  {
    question:
      "Significant Past or Current High Risk Pregnancy Factors (Select All That Apply)",
    parameter: "highRiskPregnancyFactors",
  },
  {
    question:
      "Significant Past or Current Obstetric / Gynecological Health History ( Select All That Apply)",
    parameter: "gynecologicalHealthHistory",
  },
  {
    question: "Any Medical Conditions Developed During Pregnancy:",
    parameter: "medicalConditions",
  },
  {
    question: "How do you feel about your weight and nutrition",
    parameter: "weightNutrition",
  },
  {
    question: "Amount of uninterrupted nightly rest",
    parameter: "sleep",
  },
  {
    question:
      "Do you have someone you can count on for help/support during this pregnancy?",
    parameter: "helpSupport",
  },
  {
    question:
      "Do you have someone you can talk to about stressful things in your life?",
    parameter: "talkTo",
  },
  {
    question: "How are you feeling about being pregnant?",
    parameter: "feeling",
  },
  {
    question: "In the last month, have you felt down, depressed, or hopeless?",
    parameter: "lmFeeling",
  },
  {
    question:
      "During the past month, have you often been bothered by little interest or pleasure in doing things?",
    parameter: "lmBothered",
  },
  {
    question: "I’m seeking support with... ( Select All That Apply )",
    parameter: "supportWith",
  },
  {
    question: "Where do you see yourself giving birth?",
    parameter: "seeYourself",
  },
];

const QuizResultsModal = ({ setQuizResultsModal, clientId }) => {
  const auth = useContext(authContext);
  const [quizResults, setQuizResults] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/client/getQuizResults/${clientId}`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setQuizResults(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="reviews_modal_container">
      <div className="reviews_modal">
        <div className="rm_head">
          <h2 className="rm_title">Quiz Results:</h2>
          <MdClose onClick={() => setQuizResultsModal(false)} />
        </div>
        {quizResults?.map((quiz) => {
          return (
            <>
              {questions.map((entry, index) => {
                let list = false;
                if (Array.isArray(quiz[entry.parameter])) list = true;
                return (
                  <div className="rm_card" key={index}>
                    <p className="rm_name">{entry.question}</p>
                    {list &&
                      quiz[entry.parameter].map((value, index) => {
                        return (
                          <p key={index} className="rm_review">
                            {value || "N/A"}
                          </p>
                        );
                      })}
                    {list && quiz[entry.parameter].length === 0 && "N/A"}
                    {!list && (
                      <p className="rm_review">
                        {quiz[entry.parameter] || "N/A"}
                      </p>
                    )}
                  </div>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default QuizResultsModal;
