import Home from "./pages/Home.js";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login.js";
import Services from "./pages/Service/Services";
import Education from "./pages/Education/Education";
import Shop from "./pages/Shop.js";
import Signup from "./pages/Signup.js";
import QP1 from "./pages/Quiz/QP1.js";
import QP2 from "./pages/Quiz/QP2.js";
import QP3 from "./pages/Quiz/QP3.js";
import QP4 from "./pages/Quiz/QP4.js";
import QP5 from "./pages/Quiz/QP5.js";
import QP6 from "./pages/Quiz/QP6.js";
import QP7 from "./pages/Quiz/QP7.js";
import QP8 from "./pages/Quiz/QP8.js";
import QP9 from "./pages/Quiz/QP9.js";
import QP10 from "./pages/Quiz/QP10.js";
import QP11 from "./pages/Quiz/QP11.js";
import QP12 from "./pages/Quiz/QP12.js";
import QP13 from "./pages/Quiz/QP13.js";
import QP14 from "./pages/Quiz/QP14.js";
import QP15 from "./pages/Quiz/QP15.js";
import QP16 from "./pages/Quiz/QP16.js";
import QP17 from "./pages/Quiz/QP17.js";
import QuizIntro from "./pages/Quiz/QuizIntro.js";
import QuizDesc from "./pages/Quiz/QuizDesc.js";
import OnBoardingChoice from "./pages/SpecialistOnboarding/OnBoardingChoice.js";
import SpecialistAccount from "./pages/SpecialistOnboarding/SpecialistAccount.js";
import SpecialistProfile from "./pages/SpecialistOnboarding/SpecialistProfile.js";
import SpecialistBusiness from "./pages/SpecialistOnboarding/SpecialistBusiness.js";
import SpecialistInquiries from "./pages/SpecialistOnboarding/SpecialistInquiries.js";
import SpecialistClients from "./pages/SpecialistOnboarding/SpecialistClients.js";
import ClientAccount from "./pages/ClientOnboarding/ClientAccount.js";
import ClientProfile from "./pages/ClientOnboarding/ClientProfile.js";
import ClientFindMatches from "./pages/ClientOnboarding/ClientFindMatches.js";
import ClientInquiries from "./pages/ClientOnboarding/ClientInquiries.js";
import ClientBirthTeam from "./pages/ClientOnboarding/ClientBirthTeam.js";
import ForProviders from "./pages/ForProviders.js";
import Impact from "./pages/Impact.js";
import ClientEducation from "./pages/ClientOnboarding/ClientEducation.js";
import CreateCourse from "./pages/Admin/CreateCourse.jsx";
import AdminCourses from "./pages/Admin/AdminCourses.jsx";
import CoursePage from "./pages/Education/CoursePage.jsx";
import CourseModal from "./pages/Admin/CourseModal.jsx";
import { Toaster } from "react-hot-toast";
import ChatPage from "./pages/Chat/ClientChatPage.js";
import ClientChatPage from "./pages/Chat/ClientChatPage.js";
import Subscribe from "./pages/Subscribe.js";
import StripeSuccess from "./pages/StripeSuccess.jsx";
import StripeError from "./pages/StripeError.jsx";
import ClientHelp from "./pages/ClientOnboarding/ClientHelp.js";
import ClientDocuments from "./pages/ClientOnboarding/ClientDocuments.js";
import QuizResults from "./pages/Quiz/QuizResults.js";
import SpecialistDocuments from "./pages/SpecialistOnboarding/SpecialistDocuments.js";
import SpecialistHelp from "./pages/SpecialistOnboarding/SpecialistHelp.js";
import AdminSpecialists from "./pages/Admin/AdminSpecialists.jsx";
import AdminClients from "./pages/Admin/AdminClients.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import AuthProtect from "./contexts/Login/AuthProtect.jsx";
import BirthFundPage from "./pages/BirthFundPage.jsx";
import AdminMessages from "./pages/Admin/AdminMessages.jsx";
import TermsOfService from "./pages/TermsOdService.js";
import ClientCart from "./pages/ClientOnboarding/ClientCart.js";
import AdminOrders from "./pages/Admin/AdminOrders.jsx";
import SmallScreenModal from "./components/SmallScreenModal.jsx";
import AdminPl from "./pages/Admin/AdminPl.jsx";
import QP18 from "./pages/Quiz/QP18.js";
import AdminProtect from "./contexts/Login/AdminProtect.jsx";
import SpecialistEducation from "./pages/SpecialistOnboarding/SpecialistEducation.js";
import { useContext } from "react";
import authContext from "./contexts/Login/index.js";
import AdminQuizStatistics from "./pages/Admin/AdminQuizStatistics.jsx";

function App() {
  const QuizDescTitles = ["Demographics", "Physical", "Social", "Emotional"];

  const QuizDescDesc = [
    "In-person local support from professionals who are familiar with navigating city-specific  resources, rights and culture positively influence birth outcomes.",
    "Physical health conditions seriously impact what options are available and what birth and recovery personnel should be involved for optimal pregnancy outcome and postpartum recovery.",
    "Pregnancy and parenthood aren’t meant to be faced alone. What routines and people did you have in place or wish you had in place to prepare for an empowered, informed, low-stress experience pregnancy through postpartum?",
    "The journey to parenthood is regarded as a mind and body connection. What ideas have you been told about birth? Breastfeeding? What noise did you have to silence? What ideas did you have to affirm to enter this journey confidently?",
  ];

  const auth = useContext(authContext);

  return (
    <Router>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            fontFamily: "Montserrat",
          },
        }}
      />
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Home />
              </>
            }
          />
          <Route
            exact
            path="/specialist/login"
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            exact
            path="/client/login"
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            exact
            path="/choose"
            element={
              <>
                <OnBoardingChoice />
              </>
            }
          />
          <Route
            exact
            path="/specialist/profile"
            element={
              <AuthProtect specialistOnly>
                <SpecialistProfile />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/specialist/education"
            element={
              <AuthProtect specialistOnly>
                <SpecialistEducation />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/specialist/account"
            element={
              <AuthProtect specialistOnly>
                <SpecialistAccount />
              </AuthProtect>
            }
          />

          <Route
            exact
            path="/services"
            element={
              <>
                <Services />
              </>
            }
          />
          <Route
            exact
            path="/quiz/intro"
            element={
              <AuthProtect clientOnly>
                <QuizIntro />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/demographic"
            element={
              <AuthProtect clientOnly>
                <QuizDesc
                  title={QuizDescTitles[0]}
                  desc={QuizDescDesc[0]}
                  page={1}
                />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/physical"
            element={
              <AuthProtect clientOnly>
                <QuizDesc
                  title={QuizDescTitles[1]}
                  desc={QuizDescDesc[1]}
                  page={7}
                />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/social"
            element={
              <AuthProtect clientOnly>
                <QuizDesc
                  title={QuizDescTitles[2]}
                  desc={QuizDescDesc[2]}
                  page={12}
                />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/emotional"
            element={
              <AuthProtect clientOnly>
                <QuizDesc
                  title={QuizDescTitles[3]}
                  desc={QuizDescDesc[3]}
                  page={14}
                />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page1"
            element={
              <AuthProtect clientOnly>
                <QP1 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page2"
            element={
              <AuthProtect clientOnly>
                <QP2 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page3"
            element={
              <AuthProtect clientOnly>
                <QP3 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page4"
            element={
              <AuthProtect clientOnly>
                <QP4 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page5"
            element={
              <AuthProtect clientOnly>
                <QP5 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page6"
            element={
              <AuthProtect clientOnly>
                <QP6 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page7"
            element={
              <AuthProtect clientOnly>
                <QP7 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page8"
            element={
              <AuthProtect clientOnly>
                <QP8 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page9"
            element={
              <AuthProtect clientOnly>
                <QP9 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page10"
            element={
              <AuthProtect clientOnly>
                <QP10 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page11"
            element={
              <AuthProtect clientOnly>
                <QP11 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page12"
            element={
              <AuthProtect clientOnly>
                <QP12 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page13"
            element={
              <AuthProtect clientOnly>
                <QP13 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page14"
            element={
              <AuthProtect>
                <QP14 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page15"
            element={
              <AuthProtect clientOnly>
                <QP15 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page16"
            element={
              <AuthProtect clientOnly>
                <QP16 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page17"
            element={
              <AuthProtect clientOnly>
                <QP17 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/quiz/page18"
            element={
              <AuthProtect clientOnly>
                <QP18 />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/chat"
            element={
              <AuthProtect clientOnly>
                <ClientChatPage />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/cart"
            element={
              <AuthProtect clientOnly>
                <ClientCart />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/chat/:id"
            element={
              <AuthProtect>
                <ChatPage />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/specialist/chat"
            element={
              <AuthProtect specialistOnly>
                <ChatPage />
              </AuthProtect>
            }
          />

          <Route
            exact
            path="/specialist/chat/:id"
            element={
              <AuthProtect key={"hafdas"} specialistOnly clientOnly={false}>
                <ChatPage />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/specialist/business"
            element={
              <AuthProtect specialistOnly>
                <SpecialistBusiness />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/specialist/documents"
            element={
              <>
                <SpecialistDocuments />
              </>
            }
          />
          <Route
            exact
            path="/specialist/inquiries"
            element={
              <AuthProtect specialistOnly>
                <SpecialistInquiries />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/specialist/help"
            element={
              <AuthProtect specialistOnly>
                <SpecialistHelp />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/specialist/clients"
            element={
              <AuthProtect specialistOnly>
                <SpecialistClients />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/account"
            element={
              <AuthProtect clientOnly>
                <ClientAccount />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/profile"
            element={
              <AuthProtect clientOnly>
                <ClientProfile />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/find-matches"
            element={
              <AuthProtect clientOnly>
                <ClientFindMatches />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/inquiries"
            element={
              <AuthProtect clientOnly>
                <ClientInquiries />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/my-birth-team"
            element={
              <AuthProtect clientOnly>
                <ClientBirthTeam />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/education"
            element={
              <AuthProtect clientOnly>
                <ClientEducation />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/client/documents"
            element={
              <AuthProtect clientOnly>
                <ClientDocuments />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/admin/create-course"
            element={
              <AdminProtect>
                <CreateCourse />
              </AdminProtect>
            }
          />
          <Route
            exact
            path="/admin/courses"
            element={
              <AdminProtect>
                <AdminCourses />
              </AdminProtect>
            }
          />
          <Route
            exact
            path="/admin/orders"
            element={
              <AdminProtect>
                <AdminOrders />
              </AdminProtect>
            }
          />
          <Route
            exact
            path="/admin/messages"
            element={
              <AdminProtect>
                <AdminMessages />
              </AdminProtect>
            }
          />
          <Route
            exact
            path="/admin/specialists"
            element={
              <AdminProtect>
                <AdminSpecialists />
              </AdminProtect>
            }
          />
          <Route
            exact
            path="/admin/clients"
            element={
              <AdminProtect>
                <AdminClients />
              </AdminProtect>
            }
          />
          <Route
            exact
            path="/admin/quiz-statistics"
            element={
              <AdminProtect>
                <AdminQuizStatistics />
              </AdminProtect>
            }
          />
          <Route
            exact
            path="/admin/course/:courseId"
            element={
              <AdminProtect>
                <CourseModal />
              </AdminProtect>
            }
          />
          <Route
            exact
            path="/course/:courseId/:lectureIndex"
            element={
                <>
                  <CoursePage />
                </>

            }
          />
          <Route
            exact
            path="/for-providers"
            element={
              <>
                <ForProviders />
              </>
            }
          />
          <Route
            exact
            path="/education"
            element={
              <>
                <Education />
              </>
            }
          />
          <Route
            exact
            path="/impact"
            element={
              <>
                <Impact />
              </>
            }
          />
          <Route
            exact
            path="/shop"
            element={
              <>
                <Shop />
              </>
            }
          />
          <Route
            exact
            path="/specialist/signup"
            element={
              <>
                <Signup />
              </>
            }
          />
          <Route
            exact
            path="/client/signup"
            element={
              <>
                <Signup />
              </>
            }
          />
          <Route
            exact
            path="/subscribe"
            element={
              <AuthProtect>
                <Subscribe />
              </AuthProtect>
            }
          />
          <Route
            exact
            path="/stripe/success"
            element={
              <>
                <StripeSuccess />
              </>
            }
          />
          <Route
            exact
            path="/stripe/error"
            element={
              <>
                <StripeError />
              </>
            }
          />
          <Route
            exact
            path="/client/help"
            element={
              <>
                <ClientHelp />
              </>
            }
          />
          <Route
            exact
            path="/client/quizResult"
            element={
              <>
                <QuizResults />
              </>
            }
          />
          <Route
            exact
            path="/termsandconditions"
            element={
              <>
                <QuizResults />
              </>
            }
          />
          <Route
            exact
            path="/privacypolicy"
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />
          <Route
            exact
            path="/termsofservice"
            element={
              <>
                <TermsOfService />
              </>
            }
          />
          <Route
            exact
            path="/birthfund/:id"
            element={
              <>
                <BirthFundPage />
              </>
            }
          />
        </Routes>
      </div>
      <SmallScreenModal />
    </Router>
  );
}

export default App;
