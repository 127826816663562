import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP12.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP12 = () => {
  const navigate = useNavigate();
  const [optionAge, setOptionAge] = useState(0);
  const [optionText, setOptionText] = useState("");
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setOptionText(event.currentTarget.textContent);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        helpSupport: optionText,
      };
    });
    navigate("/quiz/page13");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-12">
        <h1 className="qp12-title">
          Do you have someone you can count on for help/support during this
          pregnancy?{" "}
        </h1>
        <div className="qp12-options">
          <div
            className={`qp12-option ${optionAge === 1 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(1);
              handleClick(e);
            }}
          >
            Yes
          </div>
          <div
            className={`qp12-option ${optionAge === 2 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(2);
              handleClick(e);
            }}
          >
            No
          </div>
          <div
            className={`qp12-option ${optionAge === 3 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(3);
              handleClick(e);
            }}
          >
            Unsure
          </div>
        </div>

        <div className="qp12-buttons">
          <button
            className="qp12-button"
            onClick={() => navigate("/quiz/page11")}
          >
            Previous Page
          </button>
          <button className="qp12-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP12;
