import React, { useContext, useEffect, useState } from "react";
// import "./ProfilePageModal.css";
import "./AdminSpecialistProfileModal.css";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RejectionsModal from "./RejectionsModal";
import QuizResultsModal from "./QuizResultsModal";

const AdminClientProfileModal = ({ id, setProfileModal }) => {
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();

  const getClientData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getClientData/${id}`
      );
      setProfile(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [rejectionsModal, setRejectionsModal] = useState(false);
  const [quizResultsModal, setQuizResultsModal] = useState(false);

  useEffect(() => {
    getClientData();
  }, []);

  if (profile) {
    return (
      <div className="profile_modal">
        <div className="pm_left">
          <div
            className="pm_picture"
            style={{ background: `url(${profile.headshot})` }}
          ></div>
          <div className="pm_info">
            <p className="pmi_head">Location</p>
            <p className="pmi_info">{profile?.location}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Pronouns</p>
            <p className="pmi_info">{profile?.pronouns}</p>
          </div>
          <button
            className="pm_add pm_approve"
            style={{ display: "block" }}
            onClick={() => setRejectionsModal(true)}
          >
            See Rejections
          </button>
          <button
            className="pm_add pm_approve"
            onClick={() => setQuizResultsModal(true)}
          >
            See Quiz Results
          </button>
        </div>
        <div className="pm_right">
          <div className="pr_head">
            <p className="pm_name">{profile?.name}</p>
            <MdClose onClick={() => setProfileModal(false)} />
          </div>
          <p className="pm_specialty">Due Date: {profile?.dueDate}</p>
          <div className="pm_info">
            <p className="pmi_head">Bio</p>
            <p className="pmi_info">{profile?.bio}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Mailing Address</p>
            <p className="pmi_info">{profile?.mailingAddress}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Contact</p>
            <p className="pmi_info">{profile?.contact}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Email</p>
            <p className="pmi_info">{profile?.email}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Documents</p>
            {profile?.documents.map((doc) => (
              <p
                className="pmi_info pmi_doc"
                onClick={() => {
                  window.open(doc.url);
                }}
              >
                {doc.name}
              </p>
            ))}
          </div>
          {/* {!(profile?.approved) && <button className="pm_add" onClick={handleApprove}>Approve</button>} */}
          {rejectionsModal && (
            <RejectionsModal
              client={true}
              clientId={id}
              setRejectionsModal={setRejectionsModal}
            />
          )}
          {quizResultsModal && (
            <QuizResultsModal
              clientId={id}
              setQuizResultsModal={setQuizResultsModal}
            />
          )}
        </div>
      </div>
    );
  }
};

export default AdminClientProfileModal;
