import React from "react";
import "./ChatWarning.css";
import { useNavigate } from "react-router-dom";

const ChatWarning = ({ onCloseModal }) => {

  return (
    <div className="chat_warning">
      <div className="warning_container">
        <p className="warn_head">WARNING!</p>
        <p className="warn_desc">
          For your protection, we do not recommend making service purchases on
          other platforms. All initial communication and booking is done through
          the secure MyBumpteam.com website. Any service purchases that occur
          outside of those booked on www.mybumpteam.com, immediately voids the
          contract, releases My Bump Team from any liability , and voids all
          stated agreements.
        </p>
        <button onClick={() => onCloseModal()} className="warn_button">
          Okay! I will keep it in mind.
        </button>
      </div>
    </div>
  );
};

export default ChatWarning;
