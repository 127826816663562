import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP13.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP13 = () => {
  const navigate = useNavigate();
  const [optionAge, setOptionAge] = useState(0);
  const [optionText, setOptionText] = useState("");
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setOptionText(event.currentTarget.textContent);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        talkTo: optionText,
      };
    });
    navigate("/quiz/emotional");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-13">
        <h1 className="qp13-title">
          Do you have someone you can talk to about stressful things in your
          life?{" "}
        </h1>
        <div className="qp13-options">
          <div
            className={`qp13-option ${optionAge === 1 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(1);
              handleClick(e);
            }}
          >
            Yes
          </div>
          <div
            className={`qp13-option ${optionAge === 2 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(2);
              handleClick(e);
            }}
          >
            No
          </div>
          <div
            className={`qp13-option ${optionAge === 3 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(3);
              handleClick(e);
            }}
          >
            Unsure
          </div>
        </div>

        <div className="qp13-buttons">
          <button
            className="qp13-button"
            onClick={() => navigate("/quiz/page12")}
          >
            Previous Page
          </button>
          <button className="qp13-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP13;
