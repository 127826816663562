import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./QP9.css";
import { toast } from "react-hot-toast";
import quizContext from "../../contexts/QuizResult";
import authContext from "../../contexts/Login";
import axios from "axios";
import QuizRedirect from "../../helpers/quizRedirect";

const QP9 = () => {
  const navigate = useNavigate();

  const options = [];
  const quiz = useContext(quizContext);
  const auth = useContext(authContext);

  const addSuggestion = async (suggestion) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/client/addToQuizSuggestions`,
        {
          suggestion: suggestion,
        },
        {
          headers: { authorization: auth.jwt },
        }
      );
      console.log(response.data);
    } catch (error) {}
  };


  const handleNext = async () => {
    if (options.length > 0) {
      const suggestions = [
        "Health & Wellness Coach",
        "Midwife",
        "Doula",
        "Reiki Practitioner",
      ];
      suggestions.map(async (suggestion) => {
        await addSuggestion(suggestion)
      });

      toast.success(
        "Added Health & Wellness Coach, Doula, Midwife and Reiki Practitioner based on inputs!"
      );
    }
    if (
      options.includes("Severe Insomnia") ||
      options.includes("Anxiety") ||
      options.includes("Depression")
    ) {
      toast.success("Added Sound Bath Practitioner based on inputs!");
      await addSuggestion("Sound Bath Practitioner")
    }
    if (options.includes("Back Injury/Pain") || options.includes("Headaches")) {
      toast.success("Added Webster Certified Chiropractor based on inputs!");
    }
    quiz.setResults((old) => {
      return {
        ...old,
        medicalConditions: options,
      };
    });
    navigate("/quiz/page10");
  };

  const Risks = [
    "Gestational Diabetes",
    "Group B Strep",
    "Severe Insomnia",
    "Anxiety",
    "Depression",
    "Hyperemesis Gravidarum(severe morning sickness)",
    "Anemia",
    "Heartburn",
    "Headaches",
    "Pica",
    "Back Injury/Pain",
    "Preeclampsia",
    "NONE"
  ];

  const handleChange = (e, risk) => {
    if (e.target.checked) {
      if (!options.includes(risk)) {
        options.push(risk);
      }
    } else {
      if (options.includes(risk)) {
        options.splice(options.indexOf(risk), 1);
      }
    }
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-9">
        <h1 className="qp9-title">
          Any Medical Conditions Developed During Pregnancy:
        </h1>
        <div className="qp9-options">
          {Risks.map((risk) => (
            <div className="qp9-option">
              <input type="checkbox" onChange={(e) => handleChange(e, risk)} />
              <p>{risk}</p>
            </div>
          ))}
        </div>

        <div className="qp9-buttons">
          <button
            className="qp9-button"
            onClick={() => navigate("/quiz/page8")}
          >
            Previous Page
          </button>
          <button className="qp9-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP9;
