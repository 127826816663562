import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP6.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP6 = () => {
  const navigate = useNavigate();
  const [optionRelationship, setRelationship] = useState(0);
  const [optionRelationshipText, setRelationshipText] = useState(0);
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setRelationshipText(event.currentTarget.textContent);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        relationshipStatus: optionRelationshipText,
      };
    });
    navigate("/quiz/physical");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-6">
        <h1 className="qp6-title">Relationship Status</h1>
        <div className="qp6-options">
          <div
            className={`qp6-option ${
              optionRelationship === 1 && "yellow-background"
            }`}
            onClick={(e) => {
              setRelationship(1);
              handleClick(e);
            }}
          >
            Widow
          </div>
          <div
            className={`qp6-option ${
              optionRelationship === 2 && "yellow-background"
            }`}
            onClick={(e) => {
              setRelationship(2);
              handleClick(e);
            }}
          >
            Single Parent
          </div>
          <div
            className={`qp6-option ${
              optionRelationship === 3 && "yellow-background"
            }`}
            onClick={(e) => {
              setRelationship(3);
              handleClick(e);
            }}
          >
            Separated & Positively Co-parenting
          </div>
          <div
            className={`qp6-option ${
              optionRelationship === 4 && "yellow-background"
            }`}
            onClick={(e) => {
              setRelationship(4);
              handleClick(e);
            }}
          >
            Committed Relationship
          </div>
          <div
            className={`qp6-option ${
              optionRelationship === 5 && "yellow-background"
            }`}
            onClick={(e) => {
              setRelationship(5);
              handleClick(e);
            }}
          >
            Married
          </div>
        </div>

        <div className="qp6-buttons">
          <button
            className="qp6-button"
            onClick={() => navigate("/quiz/page5")}
          >
            Previous Page
          </button>
          <button className="qp6-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP6;
