import React from "react";
import "./Impact.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Impact = () => {
  return (
    <>
      <Navbar />
      <div className="impact">
        <h1 className="impact-header">My Bump Team IMPACT</h1>
        <div className="i-info">
          <p className="ii-desc">
            At My Bump Team., we know transformative maternal health care does
            not start and stop in the delivery room. To this effect, My Bump
            Team developed the first digital home that uses data science to
            guide first time parents in assembling a personalized birth team.
            <br />
            <br />
            Like you, we’ve read the alarming statistics of fatalities due in
            part to race and medical negligence, we’ve heard the stories of
            traumatic birth experiences and we’ve seen the faces of families
            trying to sort through emotions after an untimely passing. As a team
            of expert practitioners we set out on a mission to combat this
            devastating, largely avoidable, public health crisis. Research and
            experience shows all pregnancy-related deaths can be prevented with
            collaborative health care, consistent parent-provider communication
            and stronger support the entire 9 months and beyond. Unfortunately,
            to access these life-saving efforts parents have been required to
            download several phone apps, sifting through websites, making
            appointments with offices around the city and joining judgemental
            online mom groups.
            <br />
            <br />
            There is a better way—a way that honors you, respects you and puts
            you at the center of your childbirth experience.
            <br />
            <br />
            As a one-stop solution, My Bump Team boasts collaborative health
            teams, on-demand communication features and local support that
            starts from pregnancy and stays with you through the first year of
            parenthood. Through our data driven platform we begin by assessing
            your risk factors that could impact your ideal birth experience.
            Beyond the medical diagnoses, we look at social, geographical,
            financial, emotional and physical health to determine the best team
            members that could promote a positive birth experience and a healthy
            outcome.
            <br />
            <br />
            Providers on our platform share our passion and fierce advocacy for
            maternal health and well-being. Together, we close gaps in care so
            high quality maternity care is geographically accessible, informed,
            empowered and low-stress. Together, we are honored to join you on
            this exciting journey.
          </p>
          <div className="i-signature"></div>
          <p className="i-owner-details">
            Kanisha Ffriend, Founder & CEO of My Bump Team{" "}
          </p>
        </div>
        <h1 className="i-footer">POWER TO THE PREGNANT PEOPLE ™</h1>
      </div>
      <Footer />
    </>
  );
};

export default Impact;
