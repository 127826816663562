import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import ClientSideNav from "../../components/ClientSideNav";
import "./ClientDocuments.css";
import LoadingScreen from "../../components/Loading";
import axios from "axios";
import authContext from "../../contexts/Login";
import { useNavigate } from "react-router-dom";
import ClientNeedSubscription from "./ClientNeedSubscription";

const ClientDocuments = () => {
  const [file, setFile] = useState("");
  const [filePrev, setFilePrev] = useState("");
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState();
  const auth = useContext(authContext);
  const navigate = useNavigate();
  if (!auth.loading && !auth.user?.quizDone) {
    navigate("/quiz/intro");
  }

  const changeFileHandler = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFilePrev(reader.result);
      setFile(file);
    };
    setLoading(false);
  };

  const getClientData = async () => {
    // setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/getClientData`,
        {
          headers: { authorization: auth.jwt },
        }
      );
      setClientData(response.data);
    } catch (err) {
      console.error(err);
    }
    // setLoading(false)
  };

  const addFile = async () => {
    setLoading(true);
    try {
      var formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/client/addDocument`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // authorization: auth.jwt,
            Authorization: `${auth.jwt}`,
          },
        }
      );
      setFile("");
    } catch (err) {}

    setLoading(false);
  };

  useEffect(() => {
    getClientData();
  }, [loading]);

  const subscribed = true;

  return (
    <>
      <Navbar />
      <div className="client_documents_container">
        {subscribed ? (
          <div className="client_documents">
            <h2 className="cd_title">Your Documents:</h2>
            <div className="cd_all">
              {clientData?.documents?.map((doc, index) => (
                <p
                  onClick={() => {
                    window.open(doc.url);
                  }}
                >
                  {index + 1}. {doc.name}
                </p>
              ))}
            </div>
            {/* <button className="add_doc_button">Add Document</button> */}
            <label htmlFor="file-upload" className="custom-file-upload">
              <input
                type="file"
                accept="application/pdf, application/msword, image/*"
                onChange={changeFileHandler}
                className="file-input"
                id="file-upload"
              />
              Add File
            </label>
            {file && (
              <div className="cd_file">
                <p>{file.name}</p>
                <button onClick={addFile}>ADD</button>
              </div>
            )}
          </div>
        ) : (
          <ClientNeedSubscription />
        )}

        <ClientSideNav />
      </div>
      {loading && <LoadingScreen />}
    </>
  );
};

export default ClientDocuments;
