import React, { useContext, useState } from "react";
import Navbar from "../components/Navbar";
import "./ForProviders.css";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import authContext from "../contexts/Login";
import SmallScreenModal from "../components/SmallScreenModal";

const ForProviders = () => {
  const [feature, setFeature] = useState("Invoices");
  const [imgUrl, setImgUrl] = useState(
    "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404770/Invoicing_rch9ld.png"
  );
  const [mFeatures, setMFeatures] = useState([
    {
      name: "Invoices",
      desc: "Easily create & send invoices with reminders to get paid on time.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404770/Invoicing_rch9ld.png",
    },
    {
      name: "Contracts",
      desc: "Lawyer verified easy-to-use templates. Ready to sign. All online.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Contracts_lctq5o.png",
    },
    {
      name: "Scheduling",
      desc: "Share availability. Secure contracts without the back and forth. Confirm.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404771/schedule_w2ovuf.png",
    },
    {
      name: "Integrations",
      desc: "Onboard and manage your clients quickly with tools like video, emailing, e-signing and more all within your provider portal.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Integrations_yqmmcf.png",
    },
    {
      name: "Courses",
      desc: "Certifications and continuing education to diversify your business in a competitive market at no additional cost.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Education_Courses_nzapuf.png",
    },
    {
      name: "Branding",
      desc: "Impress clients and potential-clients with contracts and proposals that look like your brand, not ours.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404772/Branding_h8p31o.png",
    },
    // Add other features here
  ]);
  const navigate = useNavigate();
  const auth = useContext(authContext);

  return (
    <>
      <Navbar />
      <div className="for-providers">
        <div className="fp-opening">
          <div className="fpo-left">
            <p>
              <strong>LEAN-IN</strong> to close gaps in maternal care
            </p>
            <p>
              <strong>DO</strong> the most, in less time
            </p>
            <p>
              <strong>GROW</strong> your business
            </p>
          </div>
          <div className="fpo-right"></div>
        </div>
        <div className="fp-main">
          {/* <h1 className="fpm-title">BOLD HEADING</h1> */}
          <div className="fpm-features-section">
            <div className="fpm-feature">
              <img
                src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686605027/Lean-in_to_close_the_gaps_wqashn.svg"
                className="fpmf-photo"
                alt=""
              />
              <p className="fpmf-title">lean-in to close gaps</p>
              <p className="fpmf-desc">
                Like-minded Maternal Health equity professionals of their field
                collaborate on client care plans for positive birth experiences
                and healthy birth outcomes
              </p>
            </div>
            <div className="fpm-feature">
              <img
                src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686604949/Do_the_most_in_less_time_sjfolj.svg"
                className="fpmf-photo"
                alt=""
              />
              <p className="fpmf-title">DO the most. in less time</p>
              <p className="fpmf-desc">
                Using client management tools within the provider portal,
                professionals manage births safely and efficiently while
                achieving work life balance.{" "}
              </p>
            </div>
            <div className="fpm-feature">
              <img
                src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686604968/Grow_your_business_dlxpm7.svg"
                className="fpmf-photo"
                alt=""
              />
              <p className="fpmf-title">GROW your business</p>
              <p className="fpmf-desc">
                My Bump Team providers are leading transformative Maternal
                Health Care, able to reach more clients and increase their
                income while creating valuable impact in their community.
              </p>
            </div>
          </div>
          <p className="fpm-bold-sentence">
            Create powerful birth experiences and increase healthy birth
            outcomes for parents everywhere
          </p>
        </div>
        <div className="jap_button_container">
          <button
            onClick={() =>
              navigate(
                auth.isAuthenticated
                  ? "/specialist/profile"
                  : "/specialist/signup"
              )
            }
            className="jap_button"
          >
            Join as Provider
          </button>
        </div>
        <div className="provider-features-section">
          <div className="pfs-left">
            <div className="pfs-service-options">
              {/* <span className="invisible">dcdcdasddawede</span> */}
              <div
                className={`pfs-feature-info ${
                  "Invoices" === feature && "pfs-feature-box-shadow"
                }`}
                onMouseOver={(e) => {
                  setFeature("Invoices");
                  setImgUrl(
                    "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404770/Invoicing_rch9ld.png"
                  );
                }}
              >
                <p
                  className={`feature-name-head ${
                    "Invoices" === feature && "pfs-feature-bold"
                  }`}
                >
                  Invoices
                </p>
                <p className="pfs-feature-desc">
                  Easily create & send invoices with reminders to get paid on
                  time.
                </p>
              </div>
              <div
                className={`pfs-feature-info ${
                  "Contracts" === feature && "pfs-feature-box-shadow"
                }`}
                onMouseOver={(e) => {
                  setFeature("Contracts");
                  setImgUrl(
                    "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Contracts_lctq5o.png"
                  );
                }}
              >
                <p className={"Contracts" === feature && "pfs-feature-bold"}>
                  Contracts
                </p>
                <p className="pfs-feature-desc">
                  Lawyer verified easy-to-use templates. Ready to sign. All
                  online.
                </p>
              </div>
              <div
                className={`pfs-feature-info ${
                  "Scheduling" === feature && "pfs-feature-box-shadow"
                }`}
                onMouseOver={(e) => {
                  setFeature("Scheduling");
                  setImgUrl(
                    "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404771/schedule_w2ovuf.png"
                  );
                }}
              >
                <p className={"Scheduling" === feature && "pfs-feature-bold"}>
                  Scheduling
                </p>
                <p className="pfs-feature-desc">
                  Share availability. Secure contracts without the back and
                  forth. Confirm.
                </p>
              </div>
              <div
                className={`pfs-feature-info ${
                  "Integrations" === feature && "pfs-feature-box-shadow"
                }`}
                onMouseOver={(e) => {
                  setFeature("Integrations");
                  setImgUrl(
                    "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Integrations_yqmmcf.png"
                  );
                }}
              >
                <p className={"Integrations" === feature && "pfs-feature-bold"}>
                  Integrations
                </p>
                <p className="pfs-feature-desc">
                  Onboard and manage your clients quickly with tools like video,
                  emailing, e-signing and more all within your provider portal
                </p>
              </div>
              <div
                className={`pfs-feature-info ${
                  "Courses" === feature && "pfs-feature-box-shadow"
                }`}
                onMouseOver={(e) => {
                  setFeature("Courses");
                  setImgUrl(
                    "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Education_Courses_nzapuf.png"
                  );
                }}
              >
                <p className={"Courses" === feature && "pfs-feature-bold"}>
                  Courses
                </p>
                <p className="pfs-feature-desc">
                  Certifications and continuing education to diversify your
                  business in a competitive market at no additional cost
                </p>
              </div>
              <div
                className={`pfs-feature-info ${
                  "Branding" === feature && "pfs-feature-box-shadow"
                }`}
                onMouseOver={(e) => {
                  setFeature("Branding");
                  setImgUrl(
                    "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404772/Branding_h8p31o.png"
                  );
                }}
              >
                <p className={"Branding" === feature && "pfs-feature-bold"}>
                  Branding
                </p>
                <p className="pfs-feature-desc">
                  Impress clients and potential-clients with contracts and
                  proposals that look like your brand, not ours.
                </p>
              </div>
              {/* <span className="invisible">dcdcdasddawede</span> */}
            </div>
          </div>
          <div
            className="pfs-right"
            // style={{ background: `url(${imgUrl})`, backgroundSize: "cover" }}
          >
            <img src={imgUrl} alt="" />
          </div>
        </div>
        <div className="m-provider-features-section">
        <h1 className="title">Features</h1>

          {mFeatures.map((feature) => {
            return (
              <div className="m_feature">
                <h3>{feature.name}</h3>
                <p>{feature.desc}</p>
                <img src={feature.img} alt="" />
              </div>
            );
          })}
        </div>
        <div className="provider-statistic-section">
          <div className="pss-statistic">
            <h1>88%</h1>
            <p>Spend less on marketing</p>
          </div>
          <div className="pss-statistic">
            <h1>91%</h1>
            <p>2x their income in less than 5 months</p>
          </div>
          <div className="pss-statistic">
            <h1>97%</h1>
            <p>
              agree the MyBumpTeam provider platform reduces stress by helping
              with work-life balance
            </p>
          </div>
        </div>
        {/* <div className="provider-reviews-video-section">
          <video autoPlay loop muted className="actual-video">
            <source
              src="https://media.istockphoto.com/id/1190928923/video/overjoyed-woman-make-videocall-chatting-with-relatives-feels-happy.mp4?s=mp4-640x640-is&k=20&c=jj7u9cUblPHv3AkpRdquyOQ1PkpZg0tSv7ZpUt4ofUs="
              type="video/mp4"
            />
          </video>
          <p>
            Provider 1, Provider 2, and Provider 3 talk about their experiences
            having their business listed with HeirbloomCo.
          </p>
        </div> */}
        <Footer />

      </div>
    </>
  );
};

export default ForProviders;
