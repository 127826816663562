import React from "react";
import "./SMCard.css";

const SMCard = ({ picture, title, desc }) => {
  return (
    <div className="sm-card">
      {/* <div
        className="smc-picture"
        style={{
          background: `url(${picture})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div> */}
      <div className="smc-info">
        <p className="smc-title">{title}</p>
        <p className="smc-desc">{desc}</p>
      </div>
    </div>
  );
};

export default SMCard;
