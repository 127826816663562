import React, { useContext, useEffect, useState } from "react";
import "./ClientBirthTeam.css";
import "./ClientInquiries.css";
import "./ClientCart.css";
import Navbar from "../../components/Navbar";
import SideNav from "../../components/ClientSideNav";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import axios from "axios";
import { Controller, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import authContext from "../../contexts/Login";
import ClientNeedSubscription from "./ClientNeedSubscription";
import ProfilePageModal from "../../components/ProfilePageModal";
import EmptyRegionModal from "../../components/EmptyRegionModal";
import { MdPermIdentity } from "react-icons/md";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useNavigate } from "react-router-dom";

const ClientCart = () => {
  const [birthTeam, setBirthTeam] = useState([]);
  const auth = useContext(authContext);
  const [id, setId] = useState("");
  const [profileModal, setProfileModal] = useState(false);
  const navigate = useNavigate();
  if (!auth.loading && !auth.user?.quizDone) {
    navigate("/quiz/intro");
  }

  const purchaseBirthTeam = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/stripe/purchase-birth-team`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/getBirthTeam`, {
        headers: {
          authorization: auth.jwt,
        },
      })
      .then((res) => {
        setBirthTeam(res.data.birthTeam);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleProfileClick = (id) => {
    setId(id);
    setProfileModal(true);
  };
  const fee =
    birthTeam.reduce((fee, profile) => {
      console.log(fee);
      return profile.fee + fee;
    }, 0) / 100;

  return (
    <>
      <Navbar />
      <div className="cbt-container">
        {auth?.user?.subscriptionStatus !== "active" ? (
          <ClientNeedSubscription />
        ) : (
          <div className="client-birth-team">
            <h2
              style={{
                padding: "5vh",
                fontFamily: "Montserrat",
                paddingBottom: "0",
              }}
            >
              Your Bump Team:
            </h2>
            <div className="ci-cards-section">
              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                modules={[Controller, Navigation]}
                navigation={true}
              >
                {birthTeam?.map((specialist) => {
                  return (
                    <SwiperSlide key={specialist._id}>
                      <div className="ci-card-section">
                        <div className="ci-card">
                          <div
                            className="cic-photo"
                            style={{
                              background: `url(${specialist.headshot})`,
                            }}
                          ></div>
                          <div className="ci-name-age">{specialist.name}</div>
                          <div className="ci-credentials">
                            <div className="cic-intro cic-option">
                              <PermIdentityIcon />
                              <p>{specialist.pronouns}</p>
                            </div>
                            <div className="cic-education cic-option">
                              <WorkOutlineIcon />
                              <p>{specialist.specialty}</p>
                            </div>
                            <div className="cic-location cic-option">
                              <LocationOnOutlinedIcon />
                              <p>{specialist.location}</p>
                            </div>
                          </div>
                          {/* <div className="cic-bottom-nav">
                          <p>
                            <CloseIcon />
                          </p>
                          <p>
                            <StarRateRoundedIcon />
                          </p>
                          <p>
                            <FavoriteRoundedIcon />
                          </p>
                        </div> */}
                        </div>
                        <div
                          className="see-more"
                          onClick={() => handleProfileClick(specialist._id)}
                        >
                          See Full Profile
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            {/* <div className="cbt-options">
            <div className="cbt-care-team">
              <p className="cbt-option">Care Team:</p>
              <div className="cbt-ct-avatars">
                <div className="cbt-ct-avatar"></div>
                <div className="cbt-ct-avatar"></div>
                <div className="cbt-ct-avatar"></div>
              </div>
            </div>
            <p className="cbt-option">Appointments</p>
            <p className="cbt-option">Care Plan</p>
            <p className="cbt-option">Private Notes</p>
            <p className="cbt-option">Invoices</p>
            <p className="cbt-option">Contracts</p>
            <p className="cbt-option">Suggested Courses By Specialist</p>
          </div> */}

            <div className="pm_info_cart">
              <p className="pmi_head">Fee</p>
              <p className="pmi_info">$ {fee}</p>
            </div>
            <button className="cart_finalize" onClick={purchaseBirthTeam}>
              Finalize Bump Team
            </button>
          </div>
        )}
        {profileModal && (
          <ProfilePageModal id={id} setProfileModal={setProfileModal} />
        )}

        {birthTeam.length === 0 && (
          <EmptyRegionModal message={"No Birth Team"} />
        )}
        <SideNav />
      </div>
    </>
  );
};

export default ClientCart;
