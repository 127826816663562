import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import AdminSideBar from "./AdminSideBar";
import "./AdminMessages.css"
import axios from "axios";

const MessageRow = ({ message }) => {
  return (
    <div className="message_row">
      <p>
        <span>Name: </span>
        {message.name}
      </p>
      <p>
        <span>Email: </span>
        {message.email}
      </p>
      <p>
        <span>Role: </span>
        {message.from}
      </p>
      <p>
        <span>Message: </span>
        {message.message}
      </p>   
    </div>
  );
};

const AdminMessages = () => {
  const [messages, setMessages] = useState([]);

  const getAllMessages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getAllMessages`
      );
      setMessages(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllMessages();
  }, [])

  return (
    <div>
      <Navbar />
      <div className="admin_messages">
        <div className="am_main">
          <div className="am_messages">
            {messages.map((message) => (
              <MessageRow message={message} />
            ))}
          </div>
        </div>
        <AdminSideBar />
      </div>
    </div>
  );
};

export default AdminMessages;
