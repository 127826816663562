import { createContext, useState } from "react";

const quizContext = createContext();

export default quizContext;

export const QuizContext = ({ children }) => {
  const [results, setResults] = useState({});
  return (
    <quizContext.Provider value={{ results, setResults }}>
      {children}
    </quizContext.Provider>
  );
};
