import React from "react";
import "./OnBoardingChoice.css";
import { useNavigate } from "react-router-dom";

const OnBoardingChoice = () => {
  const navigate = useNavigate();
  return (
    <div className="obc">
      <div className="obc-title">
        <p>Which one best describes you?</p>
        <div className="underline"></div>
      </div>

      <div className="choices">
        <div
          className="choice-client"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/client/signup")}
        >
          {/* <div
            className="c-photo"

          ></div> */}
          <img
            className="c-photo"
            src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1691489983/client_ICON_for_Portal_cg8odb.png"
            alt=""
          />
          <p className="choice-name">Parent</p>
        </div>
        <div
          className="choice-specialist"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/specialist/signup")}
        >
          {/* <div
            className="s-photo"

          ></div> */}
          <img
            className="s-photo"
            src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1691489983/Provider_ICON_for_Portal_ojswvy.png"
            alt=""
            
          />
          <p className="choice-name">Provider</p>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingChoice;
