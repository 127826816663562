import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./QP17.css";
import quizContext from "../../contexts/QuizResult";
import authContext from "../../contexts/Login";
import axios from "axios";
import QuizRedirect from "../../helpers/quizRedirect";

const QP17 = () => {
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const options = [];
  const quiz = useContext(quizContext);

  const Risks = ["Pregnancy", "Postpartum", "Lactation"];

  const handleChange = (e, risk) => {
    if (e.target.checked) {
      if (!options.includes(risk)) {
        options.push(risk);
      }
    } else {
      if (options.includes(risk)) {
        options.splice(options.indexOf(risk), 1);
      }
    }
  };

  const handleNext = async () => {
    quiz.setResults((old) => {
      return {
        ...old,
        supportWith: options,
      };
    });
    navigate("/quiz/page18");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-17">
        <h1 className="qp17-title">
          I’m seeking support with... ( Select All That Apply )
        </h1>
        <div className="qp17-options">
          {Risks.map((risk) => (
            <div className="qp17-option">
              <input type="checkbox" onChange={(e) => handleChange(e, risk)} />
              <p>{risk}</p>
            </div>
          ))}
        </div>

        <div className="qp17-buttons">
          <button
            className="qp17-button"
            onClick={() => navigate("/quiz/page16")}
          >
            Previous Page
          </button>
          <button className="qp17-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP17;
