import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import EclCard from "./EclCard";
import "./Education.css";
import axios from "axios";
import authContext from "../../contexts/Login";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/Loading";

const Education = () => {
  const auth = useContext(authContext);
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [noOfVideos, setNoOfVideos] = useState(4);
  const isSpecialist = auth?.specialist;

  const getAllCourses = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/courses`
      );
      setCourses(data.courses);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log(auth?.client);
    console.log(auth?.specialist);
    getAllCourses();
  }, []);

  return (
    <>
      <Navbar />
      <div className="education">
        <div className="e-header">
          <p>
            When even just one parent increases their <span>EDUCATION</span> the
            entire family benefits, for generations. Traditions, myth busting,
            evidence-based teaching, cultural practices and more available in
            on-demand courses and classes to help you and your heir bloom from
            pregnancy through the first year of parenthood .
          </p>
          {!isSpecialist ? (
            <button onClick={() => navigate("/client/education")}>
              {auth.isAuthenticated ? "Go To My Education" : "SIGN IN"}
            </button>
          ) : (
            <button onClick={() => navigate("/specialist/education")}>
              {auth.isAuthenticated ? "Go To My Education" : "SIGN IN"}
            </button>
          )}
        </div>
        <p className="eq-text">Search Courses</p>
        <input
          className="e-query"
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        <div className="e-course-list">
          {courses
            ?.filter((course) => {
              {
                /* course.title.toLowerCase().includes(query.toLowerCase()) */
              }
              const lowerCaseQuery = query.toLowerCase();
              const lowerCaseTitle = course.title.toLowerCase();
              const lowerCaseTags = course.tags.map((tag) => tag.toLowerCase());

              return (
                lowerCaseTitle.includes(lowerCaseQuery) ||
                lowerCaseTags.some((tag) => tag.includes(lowerCaseQuery))
              );
            })
            //.slice(!query ? undefined : 0, !query ? undefined : 4)
            .slice(0, noOfVideos)
            .map((course) => {
              if (course.lectures.length > 0) {
                return (
                  <EclCard
                    title={course?.title}
                    desc={course?.description}
                    posterUrl={course?.poster.url}
                    id={course._id}
                    creator={course.createdBy}
                  />
                );
              }
            })}
        </div>
        {noOfVideos <
          courses?.filter((course) => {
            {
              /* course.title.toLowerCase().includes(query.toLowerCase()) */
            }
            const lowerCaseQuery = query.toLowerCase();
            const lowerCaseTitle = course.title.toLowerCase();
            const lowerCaseTags = course.tags.map((tag) => tag.toLowerCase());

            return (
              lowerCaseTitle.includes(lowerCaseQuery) ||
              lowerCaseTags.some((tag) => tag.includes(lowerCaseQuery))
            );
          })?.length && (
          <button
            className="e-vm"
            onClick={() => setNoOfVideos((prev) => prev + 4)}
          >
            VIEW MORE
          </button>
        )}
        {loading && <LoadingScreen />}
      </div>
    </>
  );
};

export default Education;
