import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP5.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP5 = () => {
  const navigate = useNavigate();
  const [optionPronouns, setOptionPronouns] = useState(0);
  const [optionPronounsText, setOptionPronounsText] = useState("");
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setOptionPronounsText(event.currentTarget.textContent);
  };

  const handleNext= () => {
    quiz.setResults((old) => {
      return {
        ...old,
        pronouns: optionPronounsText,
      };
    });
    navigate("/quiz/page6");
  }

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-5">
        <h1 className="qp5-title">Affirming Pronouns</h1>
        <div className="qp5-options">
          <div
            className={`qp5-option ${
              optionPronouns === 1 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionPronouns(1);
              handleClick(e);
            }}
          >
            She / Her / Hers
          </div>
          <div
            className={`qp5-option ${
              optionPronouns === 2 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionPronouns(2);
              handleClick(e);
            }}
          >
            He / Him / His
          </div>
          <div
            className={`qp5-option ${
              optionPronouns === 3 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionPronouns(3);
              handleClick(e);
            }}
          >
            They / Them / Theirs
          </div>
          <div
            className={`qp5-option ${
              optionPronouns === 4 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionPronouns(4);
              handleClick(e);
            }}
          >
            Other
          </div>
        </div>

        <div className="qp5-buttons">
          <button
            className="qp5-button"
            onClick={() => navigate("/quiz/page4")}
          >
            Previous Page
          </button>
          <button className="qp5-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP5;
