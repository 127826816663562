import React, { useContext, useEffect, useState } from "react";
import ClientSideNav from "../../components/ClientSideNav";
import Navbar from "../../components/Navbar";
import "./QuizResults.css";
import authContext from "../../contexts/Login";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const QuizResults = () => {
  const auth = useContext(authContext);
  const [client, setClient] = useState();

  const getClientData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/getClientData`,
      {
        headers: { authorization: auth.jwt },
      }
    );
    setClient(response.data);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getClientData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="quizResultsContainer">
        {client?.quizDone ? (
          <div className="quiz_results">
            <h2>Quiz Results:</h2>
            <p>
              Thank you for your vulnerability and taking the time to answer
              honestly. Our comprehensive assessment considers your
              demographics, physical health, social support, emotional balance
              and desires before generating a bump team for your ideal birth
              experience. Based on your responses, we have identified a few
              factors that could make this birth difficult. With this in mind,
              we highly suggest your Bump Team to include a …
            </p>
            <ul>
              {client?.quizSuggestions?.map((suggestion) => (
                <li>{suggestion}</li>
              ))}
            </ul>
            <a
              className="qr_button"
              href="/client/find-matches"
            >
              Find Matches
            </a>
          </div>
        ) : (
          <div className="quiz_results">
            <h2>Quiz Results:</h2>
            <p>Please take the quiz in order to generate suggestions.</p>
            <button
              className="qr_button"
              onClick={() => navigate("/quiz/intro")}
            >
              Take Quiz!
            </button>
          </div>
        )}
        <ClientSideNav />
      </div>
    </>
  );
};

export default QuizResults;
