import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import AdminSideBar from "./AdminSideBar";
import "./AdminCourses.css";
import { AiFillDelete } from "react-icons/ai";
import CourseModal from "./CourseModal";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/Loading";
import { toast } from "react-hot-toast";

const TableRowHead = () => {
  return (
    <div className="table-row table-row-head">
      <p className="tr-title">TITLE</p>
      <p className="tr-creator">CREATOR</p>
      {/* <p>VIEWS</p> */}
      <p>LECTURES</p>
      <div className="course-action">ACTION</div>
    </div>
  );
};

const TableRow = ({ title, creator, views, lectures, id }) => {
  const [loading, setLoading] = useState(false)


  const navigate = useNavigate()

  const deleteCourse = async(id) => {
    try {
      setLoading(true)
      const config = {
        withCredentials: true,
      };
      const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/course/${id}`, {
        config,
      });
      setLoading(false)
      toast.success("Course deleted successfully")
    
    } catch (error) {
      setLoading(false)
      toast.error("Something went wrong, please try again later.")
    }
  }

  return (
    <div className="table-row">
      <p className="tr-title">{title}</p>
      <p className="tr-creator">{creator}</p>
      {/* <p>{views}</p> */}
      <p>{lectures}</p>
      <div className="course-action">
        <button className="view-lectures" onClick={() => navigate(`/admin/course/${id}`)}>View Lectures</button>
        <button className="delete-course" onClick={() => deleteCourse(id)}>
          <AiFillDelete />
        </button>
      </div>
      {loading && <LoadingScreen />}
    </div>
  );
};

const AdminCourses = () => {
  const [courses, setCourses] = useState([]);

  const getAllCourses = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/courses`
      );
      setCourses(data.courses)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllCourses()
  }, []);

  return (
    <>
      <Navbar />
      <div className="ac-container">
        <div className="admin-courses">
          <h2 className="ac-title">All Courses</h2>
          <div className="ac-table">
            <TableRowHead />
            {/* <TableRow
              title={"How to Handle"}
              creator={"Kanisha"}
              views={"125"}
              lectures={"5"}
            /> */}
            {courses.map(course => {
              
              return (
                <TableRow
              title={course.title}
              creator={course.createdBy}
              views={course.views}
              lectures={course.numOfVideos}
              id={course._id}
            />
              )
            })}
          </div>
        </div>
        <AdminSideBar />
      </div>
    </>
  );
};

export default AdminCourses;
