import React, { useContext, useEffect, useState } from "react";
import "./ReviewModal.css";
import { MdClose } from "react-icons/md";
import authContext from "../../contexts/Login";
import axios from "axios";

const RejectionsModal = ({ setRejectionsModal, speciliastId, clientId,client=false }) => {
  const auth = useContext(authContext);
  const [rejections, setRejections] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${
          client ? "client/getRejectReasons" : "specialist/getRejectReasons"
        }/${client?clientId:speciliastId}`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .then((res) => {
        setRejections(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="reviews_modal_container">
      <div className="reviews_modal">
        <div className="rm_head">
          <h2 className="rm_title">Rejections:</h2>
          <MdClose onClick={() => setRejectionsModal(false)} />
        </div>
        {rejections?.map((review, key) => {
          const name = client?review.specialist.name:review.client.name
          return (
            <div key={key} className="rm_card">
              <p className="rm_name">{name}</p>
              {review.reasons.map((review, index) => {
                return (
                  <p key={index} className="rm_review">
                    {review}
                  </p>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RejectionsModal;
