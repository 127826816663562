import React from "react";
import Navbar from "../components/Navbar";
import "./Subscribe.css";
import AuthProtect from "../contexts/Login/AuthProtect";
import SubscribeButton from "../components/SubscribeButton";
const Subscribe = () => {
  return (
    <div className="subscribe_page">
      <Navbar />
      <AuthProtect>
        <div className="subscribe">
          <h2 className="sub_title">Subscribe to My Bump Team</h2>
          <SubscribeButton />
        </div>
      </AuthProtect>
    </div>
  );
};

export default Subscribe;
