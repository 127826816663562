import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import AdminSideBar from "./AdminSideBar";
import "./AdminSpecialists.css";
import { AiFillDelete } from "react-icons/ai";
import CourseModal from "./CourseModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/Loading";
import { toast } from "react-hot-toast";
import { FiArrowUpRight } from "react-icons/fi";
import OrderModal from "./OrderModal";
import { MdClose } from "react-icons/md";
import authContext from "../../contexts/Login";

const TableRowHead = () => {
  return (
    <div className="table-row table-row-head">
      <p className="tr-title">Client Name</p>
      <p className="tr-creator">Amount</p>
      {/* <p>VIEWS</p> */}
      <p>Status</p>
      <div className="course-action">No of Providers</div>
    </div>
  );
};

const TableRow = ({ order }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [orderModal, setOrderModal] = useState(false);

  const handleProfileClick = () => {
    setId(order._id);
    setOrderModal(true);
  };

  return (
    <div className="table-row" onClick={handleProfileClick}>
      <p className="tr-title">{order?.clientId.name}</p>
      <p className="tr-creator">{order?.amount / 100}</p>
      {/* <p>{views}</p> */}
      <p>{order?.status}</p>
      <div className="course-action">{order?.specialists?.length}</div>
      {loading && <LoadingScreen />}
      {orderModal && (
        <OrderModal
          id={id}
          setOrderModal={setOrderModal}
          orderModal={orderModal}
        />
      )}
    </div>
  );
};

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);

  const auth = useContext(authContext);

  const getAllOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/transfers/orders`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      );
      console.log(response.data);
      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  return (
    <>
      <Navbar />
      <div className="ac-container">
        <div className="admin-courses">
          <h2 className="ac-title">All Orders</h2>
          <div className="ac-table">
            <TableRowHead />
            {/* <TableRow
              title={"How to Handle"}
              creator={"Kanisha"}
              views={"125"}
              lectures={"5"}
            /> */}
            {orders?.map((order) => {
              return <TableRow order={order} />;
            })}
          </div>
        </div>
        <AdminSideBar />
      </div>
    </>
  );
};

export default AdminOrders;
