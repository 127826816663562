import React, { useContext, useEffect, useState } from "react";
import "./SpecialistInquiries.css";
import Navbar from "../../components/Navbar";
import SideNav from "../../components/SpecialistSideNav";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import authContext from "../../contexts/Login";
import { Controller, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "axios";
import ClientProfilePageModal from "../../components/ClientProfilePageModal";
import EmptyRegionModal from "../../components/EmptyRegionModal";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

const SpecialistInquiries = () => {
  const [inquiries, setInquiries] = useState([]);
  const auth = useContext(authContext);

  const [id, setId] = useState("");
  const [profileModal, setProfileModal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/specialist/getInquiryClients`, {
        headers: {
          authorization: auth.jwt,
        },
      })
      .then((res) => {
        setInquiries(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleProfileClick = (id) => {
    setId(id);
    setProfileModal(true);
  };

  return (
    <>
      <Navbar />
      <div className="si-container">
        <div className="specialist-inquiries">
          <div className="si-cards-section">
            <Swiper
              spaceBetween={5}
              slidesPerView={3}
              modules={[Controller, Navigation]}
              navigation={true}
            >
              {inquiries?.map((client) => {
                return (
                  <SwiperSlide key={client._id}>
                    <div className="si-card-section">
                      <div className="si-card">
                        <div
                          className="sic-photo"
                          style={{ background: `url(${client?.headshot})` }}
                        ></div>
                        <div className="si-name-age">{client.name}</div>
                        <div className="si-credentials">
                        <div className="scc-education scc-option">
                            <PermIdentityIcon />
                            <p>{client?.pronouns}</p>
                          </div>
                          <div className="scc-intro scc-option">
                            <CalendarMonthIcon />
                            <p>{client?.dueDate}</p>
                          </div>

                          <div className="scc-location scc-option">
                            <LocationOnOutlinedIcon />
                            <p>{client?.location}</p>
                          </div>
                        </div>
                        {/* <div className="sic-bottom-nav">
                          <p>
                            <CloseIcon />
                          </p>
                          <p>
                            <StarRateRoundedIcon />
                          </p>
                          <p>
                            <FavoriteRoundedIcon />
                          </p>
                        </div> */}
                      </div>
                      <div
                        className="see-more"
                        onClick={() => handleProfileClick(client._id)}
                      >
                        See Full Profile
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          {/* <div className="si-buttons">
            <p className="si-button">Deny</p>
            <p className="si-button">Refer</p>
          </div>
          <div className="si-steps">
            <div className="sis-1">
              Step 1: Personalized Greeting Email with writing
            </div>
            <div className="sis-1">Step 2: Invitation to video chat</div>
            <div className="sis-1"> Step 3: Follow up to the video chat</div>
            <div className="sis-1">Step 4: ACCEPT CLIENT</div>
          </div> */}
          {profileModal && (
            <ClientProfilePageModal id={id} setProfileModal={setProfileModal} inquiry={true} />
          )}
        </div>
        {inquiries.length === 0 && (
          <EmptyRegionModal message={"No Inquiries"} />
        )}
        <SideNav />
      </div>
    </>
  );
};

export default SpecialistInquiries;
