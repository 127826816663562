import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP15.css";
import QuizModal from "./QuizModal";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP15 = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [quizModal, setQuizModal] = useState(false);
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setLocation(event.currentTarget.textContent);
    setQuizModal(true);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        lmFeeling: location,
      };
    });
    navigate("/quiz/page16");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-15">
        <h1 className="qp15-title">
          In the last month, have you felt down, depressed, or hopeless?
        </h1>
        <div className="qp15-places">
          <div
            className={`qp15-place ${location === "Yes" && "yellow-border"}`}
            onClick={(e) => handleClick(e)}
          >
            Yes
          </div>
          <div
            className={`qp15-place ${location === "No" && "yellow-border"}`}
            onClick={(e) => handleClick(e)}
          >
            No
          </div>
        </div>
        <div className="qp14-buttons">
          <button
            className="qp14-button"
            onClick={() => navigate("/quiz/page14")}
          >
            Previous Page
          </button>
          <button className="qp14-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
        {quizModal && <QuizModal setQuizModal={setQuizModal} />}
      </div>
    </div>
  );
};

export default QP15;
