import React, { useContext, useState } from "react";
import "./ChooseRejectReason.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import authContext from "../contexts/Login";

const reasons = [
  "I do not have availability to support your family.",
  " I do not accept your insurance at this time. However, if you are willing to enter a structured payment plan and explore other options, I can support you.",
  "Unfortunately, your needs are not in my scope of practice.",
  "Your location is out of my service area.",
];

const ChooseRejectClientReason = ({ msg, onCloseModal,client }) => {
  const [selectedReasons, setSelectedReasons] = useState(
    new Array(reasons.length).fill(false)
  );
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const setReason = (index, isSelected) => {
    const selections = [...selectedReasons];
    selections[index] = isSelected;
    setSelectedReasons(selections);
  };


  const rejectSpecialist = async () => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/specialist/rejectClient`,
      {
        client,
        reasons: reasons.filter((_reason, index) => {
          return selectedReasons[index];
        }),
      },
      {
        headers: {
          authorization: auth.jwt,
        },
      }
    );
    navigate(0)
  };


  return (
    <div className="choose_reason">
      <div className="cr_container">
        <p className="cr_head">Why did you reject this provider?</p>
        <div className="reject_reasons">
          {reasons.map((reason, index) => {
            return (
              <div key={index} className="reason_container">
                <input
                  onChange={(evt) => {
                    setReason(index, evt.target.checked);
                  }}
                  type="checkbox"
                />
                <p>{reason}</p>
              </div>
            );
          })}
        </div>
        <div className="cr_buttons">
          <button onClick={() => onCloseModal()} className="cr_button cr_red">
            Cancel
          </button>
          <button onClick={() => {

              rejectSpecialist();
              toast.success("Thanks for your response");
          }} className="cr_button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseRejectClientReason;
