import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./QP8.css";
import { toast } from "react-hot-toast";
import quizContext from "../../contexts/QuizResult";
import authContext from "../../contexts/Login";
import axios from "axios";
import QuizRedirect from "../../helpers/quizRedirect";

const QP8 = () => {
  const navigate = useNavigate();

  const options = [];

  const quiz = useContext(quizContext);

  const auth = useContext(authContext);

  const addSuggestion = async (suggestion) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/client/addToQuizSuggestions`,
        {
          suggestion: suggestion,
        },
        {
          headers: { authorization: auth.jwt },
        }
      );
      console.log(response.data);
    } catch (error) {}
  };

  const handleNext = async () => {
    if (options.length > 0) {
      const suggestions = [
        "Health & Wellness Coach",
        "Midwife",
        "Doula",
        "Reiki Practitioner",
      ];
      suggestions.map(async (suggestion) => {
        await addSuggestion(suggestion);
      });

      toast.success(
        "Added Health & Wellness Coach, Doula, Midwife and Reiki Practitioner based on inputs!"
      );
    }
    quiz.setResults((old) => {
      return {
        ...old,
        gynecologicalHealthHistory: options,
      };
    });
    navigate("/quiz/page9");
  };

  const Risks = [
    "Placenta Previa",
    "Placenta Abruption",
    "C-Section",
    "Pre-Eclampsia",
    "Eclampsia",
    "Ectopic Pregnancy",
    "Difficulty Conceiving",
    "Abortion",
    "Miscarriage",
    "OTHER",
    "NONE",
  ];

  const handleChange = (e, risk) => {
    if (e.target.checked) {
      if (!options.includes(risk)) {
        options.push(risk);
      }
    } else {
      if (options.includes(risk)) {
        options.splice(options.indexOf(risk), 1);
      }
    }
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-8">
        <h1 className="qp8-title">
          Significant Past or Current Obstetric / Gynecological Health History (
          Select All That Apply)
        </h1>
        <div className="qp8-options">
          {Risks.map((risk) => (
            <div className="qp8-option">
              <input type="checkbox" onChange={(e) => handleChange(e, risk)} />
              <p>{risk}</p>
            </div>
          ))}
        </div>

        <div className="qp8-buttons">
          <button
            className="qp8-button"
            onClick={() => navigate("/quiz/page7")}
          >
            Previous Page
          </button>
          <button className="qp8-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP8;
