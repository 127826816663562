import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP11.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP11 = () => {
  const navigate = useNavigate();
  const [hours, setHours] = useState(7);
  const quiz = useContext(quizContext);

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        sleep: hours,
      };
    });
    navigate("/quiz/social");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-11">
        <h1 className="qp11-title">Amount of uninterrupted nightly rest</h1>
        <div className="qp11-options">
          <p>Hours of Sleep: {hours}</p>
          <div className="range-slider">
            <p>3</p>
            <input
              type="range"
              min="3"
              max="12"
              className="slider"
              value={hours}
              onChange={(e) => setHours(e.target.value)}
            />
            <p>12</p>
          </div>
        </div>

        <div className="qp11-buttons">
          <button
            className="qp11-button"
            onClick={() => navigate("/quiz/page10")}
          >
            Previous Page
          </button>
          <button className="qp11-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP11;
