import React from "react";
import { MdClose } from "react-icons/md";
import "./QuizModal.css";
import QuizRedirect from "../../helpers/quizRedirect";

const QuizModal = ({setQuizModal}) => {
  return (
    <div className="quiz_modal">
      <QuizRedirect />
      <div className="quiz_card">
        <div className="qm_head">
          <h2>Hi there!</h2>
          <MdClose onClick={() => setQuizModal(false)} />
        </div>
        <p className="qm_desc">
          Thank you for taking the time to tell us about how you’ve been
          feeling.
          <br />
          <br />
          If you or a loved one is at risk of harming themselves or someone
          else, please call 911 or go to your nearest hospital’s emergency room.
          <br />
          <br />
          In the meantime, we’re always here if you have questions. If you need
          immediate support, call 988, available 24/7 .
          <br />
          <br />
          Best,
          <br />
          My Bump Team.
        </p>
      </div>
    </div>
  );
};

export default QuizModal;
