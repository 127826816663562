import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import SideNav from "../../components/ClientSideNav";
import "./ClientProfile.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";
import authContext from "../../contexts/Login";
import { toast } from "react-toastify";
import LoadingScreen from "../../components/Loading";
import SubscribeButton from "../../components/SubscribeButton";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useNavigate } from "react-router-dom";

const ClientProfile = () => {
  const [headshot, setHeadshot] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [contact, setContact] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [bio, setBio] = useState("");
  const [languages, setLanguages] = useState([]);
  const [changePassword, setChangePassword] = useState("");
  const [file, setFile] = useState("");
  const navigate = useNavigate();
  const [filePrev, setFilePrev] = useState("");
  const [loading, setLoading] = useState(false);
  const [donationInput, setDonationInput] = useState({
    title: "",
    description: "",
    amount: 0,
  });
  const updateInput = (evt) => {
    const { name, value } = evt.target;
    setDonationInput((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };
  const auth = useContext(authContext);
  if (!auth.loading && !auth.user?.quizDone) {
    navigate("/quiz/intro");
  }

  const details = {
    name: name,
    location: location,
    dueDate: dueDate,
    bio: bio,
    languages: languages,
    contact: contact,
    mailingAddress: mailingAddress,
    location: location,
    pronouns: pronouns,
  };

  const changeFileHandler = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFilePrev(reader.result);
      setFile(file);
      setHeadshot(reader.result);
    };
    setLoading(false);
  };

  const uploadHeadshot = async () => {
    setLoading(true);
    try {
      var formData = new FormData();
      formData.append("file", file);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/client/updateHeadshot`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${auth.jwt}`,
          },
        }
      );
      setFile("");
      toast.success("Picture Updated");
    } catch (err) {}

    setLoading(false);
  };

  const onCopy = async (link) => {
    navigator.clipboard.writeText(link);
    toast.success("Copied to clipboard");
  };

  const submitClicked = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/client/infoupdate`,
      details,
      {
        headers: { authorization: auth.jwt },
      }
    );
    toast.success("Info Updated");
  };

  const getClientData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/getClientData`,
      {
        headers: { authorization: auth.jwt },
      }
    );
    const client = response.data;
    setName(client?.name);
    setHeadshot(client?.headshot);
    setBio(client?.bio);
    setDueDate(client?.dueDate);
    setLocation(client?.location);
    setMailingAddress(client?.mailingAddress);
    setContact(client?.contact);
    setPronouns(client?.pronouns);
  };

  const connectToStripe = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/stripe/connect-stripe`,
        {},
        {
          headers: { authorization: auth.jwt },
        }
      );
      window.location.href = response.data.url;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getClientData();
  }, []);

  const createDonation = async () => {
    setLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/stripe/create-donation`,
        donationInput,
        {
          headers: { authorization: auth.jwt },
        }
      );
      window.location.reload();

      // await getClientData();
      // toast.success("Please Refresh your page to update details")
    } catch (error) {
      console.log(error);
      console.log("An error occurred, please try again later.");
    }

    setLoading(false);
  };

  return (
    <>
      <Navbar />
      <div className="cp-container">
        <div className="cp-preview">
          <div
            className="cp-image-container"
            style={{
              background: `url(${headshot})`,
            }}
          ></div>
          <div className="cp-info">
            <div className="cp-name-age">{name || "Name"}</div>
            <div className="cp-credentials">
              <div className="cpc-intro cpc-option">
                <PermIdentityIcon />
                <p>{pronouns || "Pronouns"}</p>
              </div>
              <div className="cpc-education cpc-option">
                <CalendarMonthIcon />
                <p>{dueDate || "Due Date"}</p>
              </div>
              <div className="cpc-location cpc-option">
                <LocationOnOutlinedIcon />
                <p>{location || "New York"}</p>
              </div>
            </div>
            <div className="line"></div>
            <div className="cp-desc">
              {bio ||
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique eaque nemo cupiditate laborum doloremque suscipit praesentium sunt nesciunt beatae architecto? Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae quam libero, amet est officia exercitationem modi autem culpa eveniet corrupti ipsam ut fugit porro impedit soluta vitae! Veritatis aliquam officiis, perferendis esse possimus itaque est dignissimos debitis sed, expedita similique? Eveniet vel quisquam ipsum, animi rerum ratione vero, culpa odio laborum autem reiciendis! Rerum corrupti tempora dolore! Sint porro rerum temporibus doloribus eligendi qui. Optio iste eius minima corporis, nemo, vitae quo facilis odio est asperiores voluptatibus at dolor iure dignissimos molestias porro eveniet minus placeat repellat, eaque iusto temporibus veritatis incidunt. Itaque ullam dolorum, omnis aperiam nisi cumque porro.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et nam voluptatum veniam doloribus a vel, asperiores ea aliquam iure. Ut saepe voluptatem reprehenderit cupiditate minima. Repellendus maxime commodi, rem quod dolorem eveniet nesciunt fuga non optio adipisci libero est facere expedita quo eos repudiandae alias reprehenderit nostrum, neque, voluptatibus ut. Adipisci doloribus sint vitae, totam cum quas nulla! Inventore aperiam, nihil dolores saepe vitae neque harum iste facilis libero beatae officia autem! Dolores assumenda illo libero maiores, nemo quia eos enim, voluptate iusto fugiat incidunt commodi harum tenetur dicta odit optio quaerat? Necessitatibus odit molestiae dolorum quae natus hic voluptates!"}
            </div>
          </div>
          {/* <div className="bottom-nav">
            <p>
              <CloseIcon />
            </p>
            <p>
              <StarRateRoundedIcon />
            </p>
            <p>
              <FavoriteRoundedIcon />
            </p>
          </div> */}
        </div>
        <div className="specialist-profile">
          <div className="cp-input">
            <p>Headshot:</p>
            {file ? (
              <button className="finalize-headshot" onClick={uploadHeadshot}>
                Finalize
              </button>
            ) : (
              <label htmlFor="file-upload" className="custom-file-upload">
                <input
                  type="file"
                  accept="application/pdf, application/msword, image/*"
                  onChange={changeFileHandler}
                  className="file-input"
                  id="file-upload"
                />
                Add Image
              </label>
            )}
          </div>
          <div className="cp-input">
            <p>Name:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
          </div>
          <div className="cp-input">
            <form
              action="#"
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            >
              <label htmlFor="lang">Location:</label>
              <select value={location} name="location" id="lang">
                <option value="None">None</option>
                <option value="Houston">Houston</option>
                <option value="Dallas">Dallas</option>
                <option value="Boston">Boston</option>
                <option value="New York">New York</option>
                <option value="Maryland">Maryland</option>
                <option value="Washington D.C">Washington D.C</option>
              </select>
              {/* <input type="submit" value="Submit" /> */}
            </form>
          </div>
          <div className="cp-input">
            <form action="#" onChange={(e) => setPronouns(e.target.value)}>
              <label htmlFor="lang">Pronouns:</label>
              <select value={pronouns} name="pronouns" id="lang">
                <option value="None">None</option>

                <option value="He/Him">He/Him</option>
                <option value="She/Her">She/Her</option>
                <option value="They/Them">They/Them</option>
                <option value="Other">Other</option>
              </select>
              {/* <input type="submit" value="Submit" /> */}
            </form>
          </div>
          <div className="cp-input">
            <p>Due Date:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => {
                setDueDate(e.target.value);
              }}
              value={dueDate}
            />
          </div>
          <div className="cp-input">
            <p>Mailing Address:</p>
            <input
              type="text"
              value={mailingAddress}
              placeholder=""
              onChange={(e) => setMailingAddress(e.target.value)}
            />
          </div>
          <div className="cp-input">
            <p>Contact:</p>
            <input
              type="text"
              placeholder=""
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="cp-input">
            <p>Change Password:</p>
            <input
              type="password"
              placeholder=""
              value={changePassword}
              onChange={(e) => setChangePassword(e.target.value)}
            />
            <button
              className="sub_button"
              style={{ marginTop: "0px", marginLeft: "5px" }}
              onClick={async () => {
                try {
                  const { data } = await axios.post(
                    `${process.env.REACT_APP_API_URL}/client/changePassword`,
                    { password: changePassword },
                    {
                      headers: { authorization: auth.jwt },
                    }
                  );
                  toast.success("Password changed successfully");
                  setChangePassword("");
                  auth.setJwt(data);
                } catch (err) {
                  console.error(err);
                  toast.error("Error changing password");
                }
              }}
            >
              Save
            </button>
          </div>
          <div className="cp-input">
            <p>Bio:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => {
                setBio(e.target.value);
              }}
              value={bio}
            />
          </div>
          <div className="cp-input cp-submit" onClick={() => submitClicked()}>
            <p>Submit</p>
          </div>
          <div
            onClick={() => auth.logout()}
            className="cp-input cp-submit cp-logout"
          >
            <p>Logout</p>
          </div>
          <div>
            <SubscribeButton />
          </div>
          <div className="birth-fund-section">
            {auth?.user?.donation && auth.user.donation.paymentLink ? (
              <>
                <div className="bfs-top">
                  <p className="bfs-title">{auth?.user?.donation.title}</p>
                  <p className="bfs-info">{auth?.user?.donation.description}</p>
                </div>

                <div className="bfs-bottom">
                  <p className="amount-raised">
                    <span className="raised">
                      ${Number(auth.user.donation.amount_received) / 100}
                    </span>{" "}
                    raised of ${Number(auth.user.donation.amount) / 100}
                  </p>
                  <div className="bfs-progress-bar">
                    <div
                      className="bfspb-left"
                      style={{
                        flex: Number(auth.user.donation.amount_received) / 100,
                      }}
                    ></div>
                    <div
                      className="bfspb-right"
                      style={{
                        flex:
                          Number(auth.user.donation.amount) / 100 -
                          Number(auth.user.donation.amount_received) / 100,
                      }}
                    ></div>
                  </div>
                  <div className="donate-buttons">
                    <button
                      onClick={() =>
                        onCopy(
                          `${window.location.origin}/birthfund/${auth.user._id}`
                        )
                      }
                    >
                      Copy Donation Link
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="start_donation">
                <h2>Bump Fund</h2>
                <p style={{ marginTop: "1vh", fontSize: "0.8rem" }}>
                  Beyond the clothes and pampers, we make it easy for friends
                  and family to help you afford the team you need for a stress
                  free experience from bump to baby. Set up your Bump Fund
                  Registry below. Then, share your fund link with family and
                  friends to meet your goals!
                </p>
                {!auth?.user?.payoutsEnabled ? (
                  <button onClick={connectToStripe} className="connect_button">
                    Connect to Stripe
                  </button>
                ) : (
                  <>
                    <p className="sd_head">
                      Title of Bump Fund ( Ex. Alana and Derek's Homebirth )
                    </p>
                    <input
                      name="title"
                      onChange={updateInput}
                      value={donationInput.title}
                      placeholder="Enter Title"
                      className="sd_input"
                    />
                    <p className="sd_head">
                      Description ( Share your story! ){" "}
                    </p>
                    <input
                      name="description"
                      onChange={updateInput}
                      value={donationInput.description}
                      placeholder="Enter Description"
                      className="sd_input"
                    />
                    <p className="sd_head">Goal Amount</p>
                    <input
                      name="amount"
                      onChange={updateInput}
                      value={donationInput.amount}
                      placeholder="Enter Amount"
                      type="number"
                      className="sd_input"
                    />
                    <button
                      className="sd_button"
                      // onClick={() => {
                      //   setLoading(true);
                      //   axios.post(
                      //     `${process.env.REACT_APP_API_URL.replace(
                      //       "/api",
                      //       ""
                      //     )}/stripe/create-donation`,
                      //     donationInput,
                      //     {
                      //       headers: { authorization: auth.jwt },
                      //     }
                      //   );
                      //   getClientData();
                      //   setLoading(false);
                      // }}
                      onClick={() => createDonation()}
                    >
                      Start Bump Fund
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {loading && <LoadingScreen />}
        <SideNav />
      </div>
    </>
  );
};

export default ClientProfile;
