import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP4.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP4 = () => {
  const navigate = useNavigate();
  const [optionGender, setOptionGender] = useState(0);
  const [optionGenderText, setOptionGenderText] = useState("");
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setOptionGenderText(event.currentTarget.textContent);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        gender: optionGenderText,
      };
    });
    navigate("/quiz/page5");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-4">
        <h1 className="qp4-title">Gender</h1>
        <div className="qp4-options">
          <div
            className={`qp4-option ${
              optionGender === 1 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionGender(1);
              handleClick(e);
            }}
          >
            Woman
          </div>
          <div
            className={`qp4-option ${
              optionGender === 2 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionGender(2);
              handleClick(e);
            }}
          >
            Trans
          </div>
          <div
            className={`qp4-option ${
              optionGender === 3 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionGender(3);
              handleClick(e);
            }}
          >
            Non-Binary / Genderqueer / Gender fluid
          </div>
          <div
            className={`qp4-option ${
              optionGender === 4 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionGender(4);
              handleClick(e);
            }}
          >
            Two Spirit 
          </div>
          <div
            className={`qp4-option ${
              optionGender === 5 && "yellow-background"
            }`}
            onClick={(e) => {
              setOptionGender(5);
              handleClick(e);
            }}
          >
            Prefer to self-describe
          </div>

        </div>

        <div className="qp4-buttons">
          <button
            className="qp4-button"
            onClick={() => navigate("/quiz/page3")}
          >
            Previous Page
          </button>
          <button className="qp4-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP4;
