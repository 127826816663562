import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP10.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP10 = () => {
  const navigate = useNavigate();
  const [optionAge, setOptionAge] = useState(0);
  const [optionText, setOptionText] = useState("");
  const quiz = useContext(quizContext);


  const handleClick = (event) => {
    setOptionText(event.currentTarget.textContent);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        weightNutrition: optionText,
      };
    });
    navigate("/quiz/page11");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-10">
        <h1 className="qp10-title">
          How do you feel about your weight and nutrition
        </h1>
        <div className="qp10-options">
          <div
            className={`qp10-option ${optionAge === 1 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(1);
              handleClick(e);
            }}
          >
            Very unsatisfied
          </div>
          <div
            className={`qp10-option ${optionAge === 2 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(2);
              handleClick(e);
            }}
          >
            Unsatisfied
          </div>
          <div
            className={`qp10-option ${optionAge === 3 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(3);
              handleClick(e);
            }}
          >
            Neutral
          </div>
          <div
            className={`qp10-option ${optionAge === 4 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(4);
              handleClick(e);
            }}
          >
            Satisfied
          </div>
          <div
            className={`qp10-option ${optionAge === 5 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(5);
              handleClick(e);
            }}
          >
            Very Satisfied
          </div>
        </div>

        <div className="qp10-buttons">
          <button
            className="qp10-button"
            onClick={() => navigate("/quiz/page9")}
          >
            Previous Page
          </button>
          <button className="qp10-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP10;
