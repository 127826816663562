import React from 'react'
import {FcOk, FcCancel} from "react-icons/fc"
import { useNavigate } from 'react-router-dom'
import "./StripeSuccess.css"


function StripeError() {
  const navigate = useNavigate()
  return (
    <div className='stripe_success'>
      <div className="stripe_box">
      <FcCancel className='stripe_icon' />
        <p>Payment Failed!</p>
        <button className='stripe_home' onClick={() => navigate("/")}>Back to Home Page</button>
      </div>
    </div>
  )
}

export default StripeError