import React from "react";
import Navbar from "../components/Navbar";
import "./privacypolicy.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy_policy">
        <h2 className="pp_header">Privacy Policy</h2>
        <p className="pp_desc">
          My Bump Team cares about your privacy and the safety of your personal
          data.
          <br />
          <br />
          The aim of this privacy statement is to provide you with clear, simple
          and complete information about how we process the personal data you
          give My Bump Team (“we”, “us”, “our”, “Bump Team” “ MBT” .) or the
          data we may collect while you are browsing our website
          (www.MyBumpTeam.com), and how that information is used. As used in
          this statement, the words “you” and “your” refer to you, the user of
          our website, as the party agreeing to this privacy statement.
          <br />
          <br />
          As used in this privacy statement, “Personal Information” means any
          information about an individual such as a name, postal or other
          address, telephone number, credit card or other financial information,
          birthday, screen name, social networking site user account names or
          email address or other information that can be used to directly or
          indirectly identify that individual.
          <br />
          <br />
          In addition to this statement, we have established Terms & Conditions
          that set forth the general rules and policies governing your use of
          this Web Site. Our Terms & Conditions statement can be read here.
        </p>
        <h2 className="pp_secondary">PERSONAL INFORMATION</h2>
        <p className="pp_desc">
          My Bump Team is the sole owner of the information collected on this
          site. Only we have access to/collect any information that you provide
          us with . We will not sell or rent this information to anyone.
          <br />
          <br />
          We collect information from you when you register on our site, place
          an order, subscribe to a newsletter, or enter information on our site.
          <br />
          <br />
          When ordering or registering on our site you may be asked to enter
          your name, email address, mailing address, phone number, credit card
          information or other details to help you with your experience.We use
          this information to fulfill your order, communicate with you, screen
          our orders for potential risk or fraud, and when in line with the
          preferences you have shared with us, provide you with information or
          advertising relating to our products or services.
          <br />
          <br />
          You may opt out of any future contacts from us, at any time, by
          sending us an email: Hello@mybumpteam.com . You also have the option
          to do any of the following by contacting us via email:
          <br />
          <br />
          + Change/revise any data we have about you.
          <br />
          <br />+ Express any concern(s) you have about how we use your data.
        </p>
        <h2 className="pp_secondary">COOKIES</h2>
        <p className="pp_desc">
          We use "cookies" on our site. The cookies we use are necessary for the
          proper functioning of the website, enabling basic features, like page
          navigation. We also use cookies to get a better understanding of how
          visitors interact with our site and their preferences by recording the
          pages they access and visit. The use of a cookie is not linked to any
          personally identifiable information on our site.
          <br />
          <br />
          If you do not wish to use cookies, you may disable them on your web
          browser. Similarly, if you have consented to using cookies on our
          site, but would like to withdraw your consent, you may also do so by
          disabling cookies on your web browser.
        </p>
        <h2 className="pp_secondary">THIRD PARTIES</h2>
        <p className="pp_desc">
          Third-party cookies may be placed on your device by anyone providing a
          service for My Bump Team, for instance to help us analyze and
          understand visitor use of the site. My Bump Team’s privacy policy does
          not apply to, and cannot control, the activities of other third party
          sites.
          <br />
          <br />
          This website also contains links to other sites. Please be aware that
          we are not responsible for the content or privacy practices of such
          other sites.
          <br />
          <br />
          We encourage you to review the privacy policies of these third-party
          sites for information on their practices and how you may opt-out.
        </p>
        <h2 className="pp_secondary">ACTIVITY LOG</h2>
        <p className="pp_desc">
          We also make use of activity logs. These are files that record
          visitors to the site and include information such as IP addresses,
          date/time stamps, and page visited. The information included in the
          activity log files is used to gather geographic information, analyze
          site traffic/traffic sources and other trends.
        </p>
        <h2 className="pp_secondary">SECURITY</h2>
        <p className="pp_desc">
          Your privacy is important to us and we take protecting your
          information seriously. When you submit any sensitive information via
          the website, your information is protected both online and offline.
          <br />
          <br />
          The information we collect from you when placing an order is encrypted
          and transmitted to us securely. PCI-DSS compliance is a security
          standard for companies and organizations that handle payment
          information from consumers. My Bump Team‘s payment processors are PCI
          compliant.
          <br />
          <br />
          If you have any questions or concerns about our privacy policy, or if
          you require more information please contact us via email
          (hello@mybumpteam.com).
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
