import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP16.css";
import QuizModal from "./QuizModal";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP16 = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [quizModal, setQuizModal] = useState(false);
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setLocation(event.currentTarget.textContent);
    setQuizModal(true);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        lmBothered: location,
      };
    });
    navigate("/quiz/page17");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-16">
        <h1 className="qp16-title">
          During the past month, have you often been bothered by little interest
          or pleasure in doing things?
        </h1>
        <div className="qp16-places">
          <div
            className={`qp16-place ${location === "Yes" && "yellow-border"}`}
            onClick={(e) => handleClick(e)}
          >
            Yes
          </div>
          <div
            className={`qp16-place ${location === "No" && "yellow-border"}`}
            onClick={(e) => handleClick(e)}
          >
            No
          </div>
        </div>
        <div className="qp14-buttons">
          <button
            className="qp14-button"
            onClick={() => navigate("/quiz/page15")}
          >
            Previous Page
          </button>
          <button className="qp14-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
      {quizModal && <QuizModal setQuizModal={setQuizModal} />}
    </div>
  );
};

export default QP16;
