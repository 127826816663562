import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP3.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP3 = () => {
  const navigate = useNavigate();
  const [optionAge, setOptionAge] = useState(0);
  const [optionAgeText, setOptionAgeText] = useState(0);
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setOptionAgeText(event.currentTarget.textContent);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        age: optionAgeText,
      };
    });
    navigate("/quiz/page4");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-3">
        <h1 className="qp3-title">Age</h1>
        <div className="qp3-options">
          <div
            className={`qp3-option ${optionAge === 1 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(1);
              handleClick(e);
            }}
          >
            18-23 y/o
          </div>
          <div
            className={`qp3-option ${optionAge === 2 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(2);
              handleClick(e);
            }}
          >
            24-29 y/o
          </div>
          <div
            className={`qp3-option ${optionAge === 3 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(3);
              handleClick(e);
            }}
          >
            30-35 y/o
          </div>
          <div
            className={`qp3-option ${optionAge === 4 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(4);
              handleClick(e);
            }}
          >
            35+ y/o
          </div>
        </div>

        <div className="qp3-buttons">
          <button
            className="qp3-button"
            onClick={() => navigate("/quiz/page2")}
          >
            Previous Page
          </button>
          <button className="qp3-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP3;
