import React from "react";
import { useNavigate } from "react-router-dom";
import "./SpecialistSideNav.css";

const SpecialistSideNav = () => {
  const navigate = useNavigate();
  return (
    <div className="sideNav">
      {/* <div
        className="sn-option"
        onClick={() => navigate("/specialist/account")}
      >
        My Account
      </div> */}
      <div
        className="sn-option"
        onClick={() => navigate("/specialist/profile")}
      >
        Profile
      </div>
      <div
        className="sn-option"
        onClick={() => navigate("/specialist/business")}
      >
        My Business
      </div>
      <div
        className="sn-option"
        onClick={() => navigate("/specialist/inquiries")}
      >
        Client Inquiries
      </div>
      <div className="sn-option" onClick={() => navigate("/specialist/chat")}>
        Chat
      </div>
      <div
        className="sn-option"
        onClick={() => navigate("/specialist/clients")}
      >
        Potential Clients
      </div>
      {/* <div className="sn-option">Education</div> */}
      <div
        className="sn-option"
        onClick={() => navigate("/specialist/documents")}
      >
        Documents
      </div>
      <div
        className="sn-option"
        onClick={() => navigate("/specialist/education")}
      >
        Education
      </div>
      <div className="sn-option" onClick={() => navigate("/specialist/help")}>
        Help
      </div>
    </div>
  );
};

export default SpecialistSideNav;
