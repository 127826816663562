import React, { useContext, useEffect, useState } from "react";
import "./ReviewModal.css";
import { MdClose } from "react-icons/md";
import axios from "axios";
import authContext from "../../contexts/Login";

const ReviewsModal = ({ setReviewsModal, speciliastId }) => {
  const auth = useContext(authContext);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/reviews/specialist/${speciliastId}`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .then((res) => {
        setReviews(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="reviews_modal_container">
      <div className="reviews_modal">
        <div className="rm_head">
          <h2 className="rm_title">Reviews:</h2>
          <MdClose onClick={() => setReviewsModal(false)} />
        </div>
        {reviews?.map((review) => {
          return (
            <div className="rm_card">
              <p className="rm_name">{review.name}</p>
              <p className="rm_review">{review.review}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewsModal;
