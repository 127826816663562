import React, { useContext, useEffect, useState } from "react";
import "./ProfilePageModal.css";
import { MdClose } from "react-icons/md";
import axios from "axios";
import authContext from "../contexts/Login";
import { useNavigate } from "react-router-dom";
import ChooseRejectClientReason from "./ChooseRejectClientReason";

const ClientProfilePageModal = ({ id, setProfileModal, inquiry }) => {
  const auth = useContext(authContext);
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();
  const [availability, setAvailability] = useState();
  const [contract, setContract] = useState();
  const [rejectModal, setRejectModal] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const getClientData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/getClientData/${id}`
    );
    setProfile(response.data);
    if (auth.user.approvedClients.includes(id)) {
      setWaiting(true);
    }
  };

  const uploadFile = async (e) => {
    if (!e.target?.value) return;
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("client", id);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/contracts/create-contract`,
      formData,
      {
        headers: {
          authorization: auth.jwt,
        },
      }
    );
    setContract(data);
  };

  const addToClient = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/specialist/addClient`,
      {
        client: id,
      },
      {
        headers: {
          authorization: auth.jwt,
        },
      }
    );
    if (!response?.data?.added) {
      setWaiting(true);
    } else {
      navigate("/specialist/clients");
    }
  };

  const getAvailability = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/availability/get-availability`,
      {
        headers: {
          authorization: auth.jwt,
        },
        params: {
          specialist: auth.user._id,
          client: id,
        },
      }
    );
    setAvailability(response.data);
  };
  const getContract = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/contracts/get-contract`,
      {
        headers: {
          authorization: auth.jwt,
        },
        params: {
          specialist: auth.user._id,
          client: id,
        },
      }
    );
    setContract(response.data);
  };
  const setWaitingValue = () => {
    if (auth.user.approvedClients && auth.user.approvedClients.includes(id)) {
      setWaiting(true);
    }
  };

  useEffect(() => {
    getClientData();
    getAvailability();
    getContract();
    setWaitingValue();
  }, []);

  if (profile) {
    return (
      <div className="profile_modal">
        <div className="pm_left">
          <div
            className="pm_picture"
            style={{ background: `url(${profile.headshot})` }}
          ></div>
          <div className="pm_info">
            <p className="pmi_head">Location</p>
            <p className="pmi_info">{profile?.location}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Pronouns</p>
            <p className="pmi_info">{profile?.pronouns}</p>
          </div>
        </div>
        <div className="pm_right">
          <div className="pr_head">
            <p className="pm_name">{profile?.name}</p>
            <MdClose onClick={() => setProfileModal(false)} />
          </div>
          <p className="pm_specialty">Due Date: {profile?.dueDate}</p>
          <a href={`/client/chat/${id}`}>
            <button className="pm_chat_button">Chat Now</button>
          </a>
          <div className="pm_info">
            <p className="pmi_head">Bio</p>
            <p className="pmi_info">{profile?.bio}</p>
          </div>
          <div className="pm_bottom">
            <p>
              Please discuss your mutual availability with the client on chat so
              they can set one.
            </p>
            <div className="pm_inputs">
              <p className="pminp_head">Meet Date & Time: </p>
              <p>
                {availability
                  ? new Date(availability.timestamp).toLocaleDateString() +
                    " " +
                    new Date(availability.timestamp).toLocaleTimeString()
                  : "To be set by client"}
              </p>
            </div>
            {availability && new Date(availability.timestamp) < new Date() && (
              <div className="pm_inputs">
                <p className="pminp_head">{!contract && "Upload"} Contract: </p>
                {!contract && (
                  <input
                    className="pminp_in"
                    type="file"
                    multiple={false}
                    onChange={uploadFile}
                  />
                )}
                {contract && (
                  <a href={contract.document} target="_blank">
                    View Contract
                  </a>
                )}
              </div>
            )}
            {contract?.status === 1 &&
              !(
                profile.birthTeam.includes(auth.user._id) ||
                profile.finalisedBirthTeam.includes(auth.user._id)
              ) &&
              (waiting ? (
                <p>Waiting from approval from client side</p>
              ) : (
                <div className="pm_buttons">
                  <button onClick={addToClient} className="pm_chat_button">
                    Add To Clients
                  </button>
                  <button
                    onClick={() => setRejectModal(true)}
                    className="pm_chat_button cr_red"
                  >
                    Reject Client
                  </button>
                </div>
              ))}
            {contract?.status === 0 && <p>Waiting for contract approval</p>}
            {contract?.status === -1 && (
              <p>Contract was declined by the client</p>
            )}
          </div>
        </div>
        {rejectModal && (
          <ChooseRejectClientReason
            client={id}
            onCloseModal={() => setRejectModal(false)}
          />
        )}
      </div>
    );
  }
};

export default ClientProfilePageModal;
