import React, { useContext, useEffect, useState } from "react";
import "./ClientInquiries.css";
import Navbar from "../../components/Navbar";
import SideNav from "../../components/ClientSideNav";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { Controller, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "axios";
import authContext from "../../contexts/Login";
import ClientNeedSubscription from "./ClientNeedSubscription";
import ProfilePageModal from "../../components/ProfilePageModal";
import EmptyRegionModal from "../../components/EmptyRegionModal";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useNavigate } from "react-router-dom";


const ClientInquiries = () => {
  const [birthTeam, setBirthTeam] = useState([]);
  const auth = useContext(authContext);
  const [id, setId] = useState("")
  const [profileModal, setProfileModal] = useState(false)
  const navigate = useNavigate()
if (!auth.loading && !auth.user?.quizDone) {
  navigate("/quiz/intro");
}
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/getSpecialistInquiry`, {
        headers: {
          authorization: auth.jwt,
        },
      })
      .then((res) => {
        setBirthTeam(res.data.specialistInquiry);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleProfileClick = (id) => {
    setId(id);
    setProfileModal(true);
  }

  return (
    <>
      <Navbar />
      {auth?.user?.subscriptionStatus !== "active" ? (
        <ClientNeedSubscription />
      ) : (
        <div className="ci-container">
          <div className="client-inquiries">
          <h2 style={{padding: "5vh", fontFamily: "Montserrat", paddingBottom: "0"}}>Specialist Enquiries:</h2>
            <div className="ci-cards-section">
              <Swiper
                spaceBetween={5}
                slidesPerView={3}
                modules={[Controller, Navigation]}
                navigation={true}
              >
                {birthTeam?.map((specialist) => {
                  return (
                    <SwiperSlide key={specialist._id}>
                      <div className="ci-card-section">
                        <div className="ci-card">
                          <div
                            style={{
                              background: `url(${specialist.headshot})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                            className="cic-photo"
                          ></div>
                          <div className="ci-name-age">{specialist.name}</div>
                          <div className="ci-credentials">
                            <div className="cic-intro cic-option">
                              <PermIdentityIcon />
                              <p>{specialist.pronouns}</p>
                            </div>
                            <div className="cic-education cic-option">
                              <WorkOutlineIcon />
                              <p>{specialist.specialty}</p>
                            </div>
                            <div className="cic-location cic-option">
                              <LocationOnOutlinedIcon />
                              <p>{specialist.location}</p>
                            </div>
                          </div>
                          {/* <div className="cic-bottom-nav">
                            <p>
                              <CloseIcon />
                            </p>
                            <p>
                              <StarRateRoundedIcon />
                            </p>
                            <p>
                              <FavoriteRoundedIcon />
                            </p>
                          </div> */}
                        </div>
                        <div
                          className="see-more"
                          onClick={() => handleProfileClick(specialist._id)}
                        >
                          See Full Profile
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          {profileModal && (
            <ProfilePageModal id={id} setProfileModal={setProfileModal} />
          )}
          {/* <div className="ci-buttons">
            <p className="ci-button">Deny</p>
            <p className="ci-button">Refer</p>
          </div> */}
          {/* <div className="ci-steps">
            <div className="cis-1">Step 1: Complete your availability</div>
            <div className="cis-1">
              Step 2: Video chat invitation or no-cost in-person consultation
              date
            </div>
            <div className="cis-1">
              {" "}
              Step 3: Sign contract and add provider to birth team
            </div>
            <div className="cis-1">Step 4: ACCEPT CLIENT</div>
          </div> */}
        </div>
      )}

      {birthTeam.length === 0 && <EmptyRegionModal message={"No Inquiries"} />}
      <SideNav />
    </>
  );
};

export default ClientInquiries;
