import React, { useContext } from "react";
import authContext from "../contexts/Login";
import axios from "axios";

function SubscribeButton() {
  const auth = useContext(authContext);

  const onSubscribe = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/stripe/create-checkout-session`,
        {},
        {
          headers: { authorization: auth.jwt },
        }
      );
      const stripeURL = res.data;
      if (!stripeURL) throw new Error("No stripe url provided");

      window.location.href = stripeURL;
    } catch (err) {
      console.error(err);
    }
  };
  const onManagePortal = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/stripe/create-portal-session`,
        {},
        {
          headers: { authorization: auth.jwt },
        }
      );
      const stripeURL = res.data;
      if (!stripeURL) throw new Error("No stripe url provided");

      window.location.href = stripeURL;
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      {auth?.user?.subscriptionStatus !== "active" ? (
        <button onClick={onSubscribe} className="sub_button">
          Subscribe Now
        </button>
      ) : (
        <button onClick={onManagePortal} className="sub_button">
          Manage Subscriptions
        </button>
      )}
    </>
  );
}

export default SubscribeButton;
