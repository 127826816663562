import React, { useContext, useEffect, useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import "./SpecialistBusiness.css";
import Navbar from "../../components/Navbar";
import SideNav from "../../components/SpecialistSideNav";
import axios from "axios";
import authContext from "../../contexts/Login";
import { Controller, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ClientProfilePageModal from "../../components/ClientProfilePageModal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const SpecialistBusiness = () => {
  const [clients, setClients] = useState([]);
  const auth = useContext(authContext);

  const [id, setId] = useState("");
  const [profileModal, setProfileModal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/specialist/getFinalisedClients`, {
        headers: {
          authorization: auth.jwt,
        },
      })
      .then((res) => {
        setClients(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleProfileClick = (id) => {
    setId(id);
    setProfileModal(true);
  };

  return (
    <>
      <Navbar />
      <div className="sb-container">
        <div className="specialist-business">
          {/* <div className="sb-logo"></div> */}
          <div className="sb-main-stuff">
            <div className="sms-1">{clients.length} Clients</div>
            <div className="sms-2">
              Payout Pending: ${Number(auth.user.payoutPending) / 100}
            </div>
            <div className="sms-3">
              Payout Received: ${Number(auth.user.payoutReceived) / 100}
            </div>
          </div>

          <div className="sc-cards-section">
            <Swiper
              spaceBetween={5}
              slidesPerView={3}
              modules={[Controller, Navigation]}
              navigation={true}
            >
              {clients?.map((client) => {
                return (
                  <SwiperSlide key={client._id}>
                    <div className="sc-card-section">
                      <div className="sc-card">
                        <div
                          className="scc-photo"
                          style={{ background: `url(${client?.headshot})` }}
                        ></div>
                        <div className="sc-name-age">{client.name}</div>
                        <div className="sc-credentials">
                          <div className="scc-education scc-option">
                            <PermIdentityIcon />
                            <p>{client?.pronouns}</p>
                          </div>
                          <div className="scc-intro scc-option">
                            <CalendarMonthIcon />
                            <p>{client?.dueDate}</p>
                          </div>

                          <div className="scc-location scc-option">
                            <LocationOnOutlinedIcon />
                            <p>{client?.location}</p>
                          </div>
                        </div>
                        {/* <div className="scc-bottom-nav">
                          <p>
                            <CloseIcon />
                          </p>
                          <p>
                            <StarRateRoundedIcon />
                          </p>
                          <p>
                            <FavoriteRoundedIcon />
                          </p>
                        </div> */}
                      </div>
                      <div
                        className="see-more"
                        onClick={() => handleProfileClick(client._id)}
                      >
                        See Full Profile
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          {profileModal && (
            <ClientProfilePageModal id={id} setProfileModal={setProfileModal} />
          )}
        </div>
        <SideNav />
      </div>
    </>
  );
};

export default SpecialistBusiness;
