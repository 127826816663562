import React, { useEffect } from "react";
import "./SmallScreen.css";
import { useNavigate, useLocation } from "react-router-dom";

const excludedPaths = [
  "/",
  "/services",
  "/impact",
  "/for-providers",
  "/termsofservice",
  "/privacypolicy",
];

const SmallScreenModal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("body--modal-active");
  }, []);

  for (let i = 0; i < excludedPaths.length; i++) {
    if (
      location.pathname === excludedPaths[i] ||
      location.pathname === excludedPaths[i] + "/"
    ) {
      return <></>;
    }
  }

  return (
    <div className="small_screen_container">
      <div className="small_screen">
        <p className="ss_line">
          Please open mybumpteam.com on your laptop/PC to move forward.
        </p>
        <button className="ss_button" onClick={() => navigate("/")}>
          Back to Home Page
        </button>
      </div>
    </div>
  );
};

export default SmallScreenModal;
