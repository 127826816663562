import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import AdminSideBar from "./AdminSideBar";
import "./AdminSpecialists.css";
import { AiFillDelete } from "react-icons/ai";
import CourseModal from "./CourseModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/Loading";
import { toast } from "react-hot-toast";
import {FiArrowUpRight} from "react-icons/fi"
import AdminClientProfileModal from "./AdminClientProfileModal";

const TableRowHead = () => {
  return (
    <div className="table-row table-row-head">
      <p className="tr-title">Name</p>
      <p className="tr-creator">Due Date</p>
      {/* <p>VIEWS</p> */}
      <p>Location</p>
      <div className="course-action">Email</div>
    </div>
  );
};

const TableRow = ({
  clientId,
  name,
  email,
  location,
  dueDate,
}) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [id, setId] = useState("")
  const [profileModal, setProfileModal] = useState(false)

  const handleProfileClick = () => {
    setId(clientId);
    setProfileModal(true);
  }

  return (
    <div className="table-row">
      <p className="tr-title">{name}</p>
      <p className="tr-creator">{dueDate}</p>
      {/* <p>{views}</p> */}
      <p>{location}</p>
      <div className="course-action">{email} <FiArrowUpRight onClick={handleProfileClick} /></div>
      {loading && <LoadingScreen />}
      {profileModal && <AdminClientProfileModal id={id} setProfileModal={setProfileModal} />}
    </div>
  );
};

const AdminSpecialists = () => {
  const [clients, setClients] = useState([]);

  const getAllClients = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/getAllClients`
    );
    setClients(response.data);
  };

  useEffect(() => {
    getAllClients();
  }, []);

  return (
    <>
      <Navbar />
      <div className="ac-container">
        <div className="admin-courses">
          <h2 className="ac-title">All Clients</h2>
          <div className="ac-table">
            <TableRowHead />
            {/* <TableRow
              title={"How to Handle"}
              creator={"Kanisha"}
              views={"125"}
              lectures={"5"}
            /> */}
            {clients.map((client) => {
              return (
                <TableRow
                  name={client.name}
                  email={client.email}
                  location={client.location}
                  dueDate={client.dueDate}
                  clientId={client._id}
                />
              );
            })}
          </div>
        </div>
        <AdminSideBar />
      </div>
    </>
  );
};

export default AdminSpecialists;
