import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP1.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP1 = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setLocation(event.currentTarget.textContent);
  };

  const handleNext = async () => {
    quiz.setResults((old) => {
      return {
        ...old,
        location,
      };
    });
    navigate("/quiz/page2");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-1">
        <h1 className="qp1-title">Choose Your Nearest Location</h1>
        <div className="qp1-places">
          <div
            className={`qp1-place ${location === "Houston" && "yellow-border"}`}
            onClick={(e) => handleClick(e)}
          >
            Houston
          </div>
          <div
            className={`qp1-place ${location === "Dallas" && "yellow-border"}`}
            onClick={(e) => handleClick(e)}
          >
            Dallas
          </div>
          <div
            className={`qp1-place ${location === "Boston" && "yellow-border"}`}
            onClick={(e) => handleClick(e)}
          >
            Boston
          </div>
          <div
            className={`qp1-place ${
              location === "New York" && "yellow-border"
            }`}
            onClick={(e) => handleClick(e)}
          >
            New York
          </div>
          <div
            className={`qp1-place ${
              location === "Maryland" && "yellow-border"
            }`}
            onClick={(e) => handleClick(e)}
          >
            Maryland
          </div>
          <div
            className={`qp1-place ${
              location === "Washington D.C" && "yellow-border"
            }`}
            onClick={(e) => handleClick(e)}
          >
            Washington D.C
          </div>
        </div>
        <button className="qp1-button" onClick={handleNext}>
          Next Page
        </button>
      </div>
    </div>
  );
};

export default QP1;
