import React, { useContext, useEffect, useState } from "react";
import "./OrderModal.css";
import { MdClose } from "react-icons/md";
import axios from "axios";
import authContext from "../../contexts/Login";

const OrderModal = ({ id, setOrderModal, orderModal }) => {

  const auth = useContext(authContext);
  const [order, setOrder]= useState({})

  const getOrderById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/transfers/getOneOrder/${id}`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      );
      console.log(response.data);
      setOrder(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getOrderById();
  }, []);



  if (orderModal) {
    return (
      <div className="order_modal">
        <div className="om_head">
          <p>{order?.clientId?.name}</p>
          <MdClose
            onClick={(e) => {
              e.stopPropagation();
              setOrderModal(false);
            }}
          />
        </div>
        <div className="om_specialists">
          {order?.specialists?.map((specialist, index) => (
            <div className="om_specialist">
              <p>{index + 1}.</p>
              <p>
                {specialist.name}({specialist.specialty})
              </p>
              {/* <p></p> */}
              <p>Fee: {specialist.fee / 100}</p>
            </div>
          ))}
        </div>
        <p className="amount">
          Amount: {order?.amount / 100}
        </p>
      </div>
    );
  }
};

export default OrderModal;
