import React, { useState } from "react";
import SpecialistSideNav from "../../components/SpecialistSideNav";
import Navbar from "../../components/Navbar";
// import "./ClientHelp.css";
import "../ClientOnboarding/ClientHelp.css";
import ClientNeedSubscription from "../ClientOnboarding/ClientNeedSubscription";
import axios from "axios";
import { toast } from "react-toastify";

const SpecialistHelp = () => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const from = "Specialist";

  const details = {
    message: message,
    name: name,
    email: email,
    from: from,
  };

  const sendMessage = async() => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/specialist/addMessage`,
        details,
        // {
        //   headers: { authorization: auth.jwt },
        // }
      );
      console.log(response.data);
      toast.success("Info Updated!");
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>
      <Navbar />
      <div className="ch-container">
        {/* <ClientNeedSubscription /> */}
        <div className="client_help">
          <h2>Contact Us</h2>
          <p>
            Enter your query and our team will get back to you in less than 24
            hours!
          </p>
          <div className="ch_input_container">
            <p>Enter Name</p>
            <input
              type="text"
              placeholder="Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="ch_input_container">
            <p>Enter Email</p>
            <input
              type="text"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="ch_input_container">
            <p>Enter Message</p>
            <textarea
              rows={10}
              type="text"
              placeholder="Enter query here"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>
          <button className="ch_submit" onClick={sendMessage}>Submit</button>
        </div>
        <SpecialistSideNav />
      </div>
    </>
  );
};

export default SpecialistHelp;
