import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Section from "../components/Section";
import "./Home.css";
import authContext from "../contexts/Login";
import { BiArrowBack } from "react-icons/bi";
import InputModal from "./Home/InputModal";
import SmallScreenModal from "../components/SmallScreenModal";

const Home = () => {
  const [feature, setFeature] = useState("invoices");
  const [imgUrl, setImgUrl] = useState("");
  const [providerHeight, setProviderHeight] = useState("100%");
  const [clientHeight, setClientHeight] = useState("100%");
  const [clientFlex, setClientFlex] = useState(1);
  const [providerFlex, setProviderFlex] = useState(1);
  const [cpwidth, setcpwidth] = useState("0vw");
  const [spwidth, setspwidth] = useState("0vw");
  const [showBack, setShowBack] = useState(false);
  const [userName, setUserName] = useState("");
  const [openingWord, setOpeningWord] = useState("Empowering");
  const [name, setName] = useState("");

  const words = ["Empowering", "Stress-free", "Memorable"];

  const videoRef = useRef(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const navigate = useNavigate();
  const auth = useContext(authContext);
  const [inputModal, setInputModal] = useState(false);

  const handleBackClick = () => {
    setProviderHeight("100%");
    setClientHeight("100%");
    setClientFlex(1);
    setProviderFlex(1);
    setcpwidth("0vw");
    setspwidth("0vw");
    setShowBack(false);
    setImgUrl("")
    setFeature("")
  };

  const handleProviderSectionClick = () => {
    setClientFlex(0);
    setClientHeight(0);
    setspwidth("100%");
    setShowBack(true);
    // setcpwidth("60vw")
  };

  const handleClientSectionClick = () => {
    setProviderFlex(0);
    setProviderHeight(0);
    setcpwidth("100%");
    setShowBack(true);
  };

  const mClientFeatures = [
    {
      name: "Flexible Payments",
      desc: "Flexible payment options so you and baby can enjoy the benefits as soon as possible.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Flexible_payments_kcz8dc.png",
    },
    {
      name: "Personalized Team",
      desc: "We emphasize that you know your body best, but still value personalized, expert vetted information to guide you from pregnancy to postpartum. Your body. Your birth. Your Bump team.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404770/Personalized_team_btupwa.png",
    },
    {
      name: "Birth Fund Registry",
      desc: "Beyond the clothes and pampers, we make it easy for friends and family to help you afford the team you need for a stress free experience from bump to baby. Share your fund link with family and friends to meet your goals!",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404771/Bump_Fund_y4aohu.png",
    },
    {
      name: "One-Stop Solution",
      desc: "Doing it all is possible with My Bump Team. Instead of having several apps and website memberships, managing your birth and postpartum experience has never been simpler and stress-free with My Bump Team.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404770/One_stop_solution_cbqafy.png",
    },
    {
      name: "Community",
      desc: "The real parent-hood! My Bump Team. Maternal Health Equity partners host local curated events for parents and professionals to form a village before and after the birth of your baby.",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404771/Community_jcb58c.png",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVideoVisible(true);
            entry.target.play(); // Play the video when it enters the viewport
          } else {
            setIsVideoVisible(false);
            entry.target.pause(); // Pause the video when it leaves the viewport
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold value as needed
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const updateOpeningWord = () => {
    const currentIndex = words.indexOf(openingWord);
    const nextIndex = (currentIndex + 1) % words.length;
    setOpeningWord(words[nextIndex]);
  };

  useEffect(() => {
    // Start the infinite traversal using setInterval
    const interval = setInterval(updateOpeningWord, 500); // Change the interval time as desired (2000ms = 2 seconds)

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [openingWord]);

  // useEffect(() => {
  //   const n = localStorage.getItem("name");
  //   if(n){
  //     setInputModal(false);
  //     setUserName(JSON.parse(n));
  //   }
  // }, [inputModal])

  useEffect(() => {
    const n = localStorage.getItem("name");

    if (auth.user?.name) {
      setInputModal(false);
      // Extract the first word from the string
      const firstName = auth.user?.name.split(" ")[0];
      setUserName(firstName);
    } else if (n) {
      setInputModal(false);
      // Extract the first word from the string
      const firstName = JSON.parse(n).split(" ")[0];
      setUserName(firstName);
    } else {
      setInputModal(true);
    }
  }, [inputModal]);

  return (
    <>
      <Navbar />
      <div className="home">
        <div className="opening">
          {/* <div className="opening-left"> */}
          <p className="l1">
            Hey <span style={{ textDecoration: "underline" }}>{userName}</span>
            {!userName && (
              <span style={{ textDecoration: "underline" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            )}
            , start planning your{" "}
            <span style={{ textDecoration: "underline" }}>{openingWord}</span>{" "}
            birth!
          </p>
          <p className="l2">
            We get it—having a baby can be as overwhelming as it is exciting.
            That’s why My Bump Team ® uses data and tech to connect expert
            providers with first-time parents to have the best birth experience
            possible.
          </p>
          <button
            onClick={() =>
              navigate(
                auth.isAuthenticated
                  ? auth.specialist
                    ? "/specialist/profile"
                    : "/quiz/intro"
                  : "/choose"
              )
            }
          >
            Build your bump team now
          </button>
          {/* </div> */}
          {/* <div className="opening-right">
            <div className="opening-image"></div>
          </div> */}
        </div>

        <div className="worked-with">
          {/* <img src="../../public/Picture1.png" alt="cds" /> */}
          <p>Featured in</p>
          <div className="ww_images">
            <img
              src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686593099/1_kj73ni.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686593099/2_ejtpdj.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686593098/8_xk77g3.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686593098/4_ho4j23.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686593098/5_ggn2wo.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686593098/6_btrbbu.png"
              alt=""
            />
          </div>
        </div>

        <div className="tagline">
          <p>Planning your best Birth is our business</p>
        </div>

        <div className="illustration_video_section">
          <a
            href={auth.isAuthenticated ? "/client/profile" : "/client/signup"}
            className="ivs_parent"
          >
            Join as a Parent !
          </a>
          <div className="illustration-video">
            <video
              ref={videoRef}
              // autoPlay={true}
              controls={isVideoVisible}
              loop
              muted={false}
              className="actuali-video"
            >
              <source
                src="https://res.cloudinary.com/dhhjtuhrd/video/upload/v1686594775/MyBumpTeam-com_Animated_Explainer_Final_e05xwz.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <a
            href={
              auth.isAuthenticated
                ? "/specialist/profile"
                : "/specialist/signup"
            }
            className="ivs_provider"
          >
            Join as a Provider !
          </a>
        </div>

        <div className="m_features">
          <h1 className="title">Features</h1>
          {mClientFeatures.map((feature) => {
            return (
              <div className="m_feature">
                <h3>{feature.name}</h3>
                <p>{feature.desc}</p>
                <img src={feature.img} alt="" />
              </div>
            );
          })}
        </div>

        <div className={`features`} style={{}}>
          <div
            className="provider"
            // onClick={() => handleProviderSectionClick()}
            style={{
              flex: `${providerFlex}`,
              opacity: `${providerFlex}`,
              // scale: `${providerFlex}`,
              pointerEvents: `${providerFlex === 0 ? "none" : "auto"}`,
              // width: `${providerFlex}px`,
              // height: `${providerHeight}px`,
              display: `${providerFlex === 0 ? "none" : "flex"}`,
            }}
          >
            <div className="features-head">
              {showBack && (
                <BiArrowBack className="fh-icon" onClick={handleBackClick} />
              )}
              <h1 className="title">PROVIDERS</h1>
            </div>

            <div className={`feature-body`}>
              <div className="service-options">
                <span className="invisible">dcdcdasddawede</span>
                <div
                  className={`feature-info ${
                    "Invoices" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleProviderSectionClick();
                    setFeature("Invoices");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404770/Invoicing_rch9ld.png"
                    );
                  }}
                >
                  <p
                    className={`feature-name-head ${
                      "Invoices" === feature && "feature-bold"
                    }`}
                  >
                    Invoices
                  </p>
                  <p className="feature-desc">
                    Easily create & send invoices with reminders to get paid on
                    time.
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "Contracts" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleProviderSectionClick();
                    setFeature("Contracts");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Contracts_lctq5o.png"
                    );
                  }}
                >
                  <p className={"Contracts" === feature && "feature-bold"}>
                    Contracts
                  </p>
                  <p className="feature-desc">
                    Easy-to-use templates. Ready to sign online.
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "Scheduling" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleProviderSectionClick();
                    setFeature("Scheduling");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404771/schedule_w2ovuf.png"
                    );
                  }}
                >
                  <p className={"Scheduling" === feature && "feature-bold"}>
                    Scheduling
                  </p>
                  <p className="feature-desc">
                    Share availability. Get booked without the back and forth.
                    Confirm meetings quickly.
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "Integrations" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleProviderSectionClick();
                    setFeature("Integrations");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Integrations_yqmmcf.png"
                    );
                  }}
                >
                  <p className={"Integrations" === feature && "feature-bold"}>
                    Integrations
                  </p>
                  <p className="feature-desc">
                    Onboard and manage your clients quickly with tools like
                    video, instant messaging, e-signing and more all within your
                    provider portal.
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "Courses" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleProviderSectionClick();
                    setFeature("Courses");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Education_Courses_nzapuf.png"
                    );
                  }}
                >
                  <p className={"Courses" === feature && "feature-bold"}>
                    Courses
                  </p>
                  <p className="feature-desc">
                    Certifications and continuing education to diversify your
                    business in a competitive market at no additional cost.
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "Branding" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleProviderSectionClick();
                    setFeature("Branding");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404772/Branding_h8p31o.png"
                    );
                  }}
                >
                  <p className={"Branding" === feature && "feature-bold"}>
                    Branding
                  </p>
                  <p className="feature-desc">
                    Impress clients and potential-clients with contracts and
                    proposals that look like your brand, not ours.
                  </p>
                </div>

                <span className="invisible">dcdcdasddawede</span>
              </div>

              <div
                className="feature-picture"
                // style={{ backgroundImage: `url(${imgUrl})` }}
                style={{ backgroundImage: `url(${imgUrl})`, width: spwidth }}
              ></div>
            </div>
            <p
              className="button"
              onClick={() =>
                navigate(
                  auth.isAuthenticated
                    ? "/specialist/profile"
                    : "/specialist/signup"
                )
              }
            >
              Join as a Provider
            </p>
          </div>
          <div className="feature-line"></div>
          <div
            className="client"
            // onClick={() => handleClientSectionClick()}
            style={{
              flex: `${clientFlex}`,
              opacity: `${clientFlex}`,
              // scale: `${clientFlex}`,
              pointerEvents: `${clientFlex === 0 ? "none" : "auto"}`,
              display: `${clientFlex === 0 ? "none" : "flex"}`,
              // width: `${clientFlex}px`,
              // height: `${clientHeight}px`,
            }}
          >
            <div className="features-head">
              {showBack && (
                <BiArrowBack className="fh-icon" onClick={handleBackClick} />
              )}
              <h1 className="title">CLIENTS</h1>
            </div>

            <div className={`feature-body`}>
              <div
                className="feature-picture"
                // style={{ backgroundImage: `url(${imgUrl})` }}
                style={{ backgroundImage: `url(${imgUrl})`, width: cpwidth }}
              ></div>

              <div className="service-options so-2">
                <span className="invisible">dcdcdasddawede</span>
                <div
                  className={`feature-info ${
                    "Flexible Payments" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleClientSectionClick();
                    setFeature("Flexible Payments");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404769/Flexible_payments_kcz8dc.png"
                    );
                  }}
                >
                  <p
                    className={`feature-name-head ${
                      "Flexible Payments" === feature && "feature-bold"
                    }`}
                  >
                    Flexible Payments
                  </p>
                  <p className="feature-desc">
                    Flexible payment options so you and baby can enjoy the
                    benefits as soon as possible.
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "Personalized Team" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleClientSectionClick();
                    setFeature("Personalized Team");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404770/Personalized_team_btupwa.png"
                    );
                  }}
                >
                  <p
                    className={
                      "Personalized Team" === feature && "feature-bold"
                    }
                  >
                    Personalized Team
                  </p>
                  <p className="feature-desc">
                    We emphasize that you know your body best, but still value
                    personalized, expert vetted information to guide you from
                    pregnancy to postpartum. Your body. Your birth. Your Bump
                    team.
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "Birth Fund Registry " === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleClientSectionClick();
                    setFeature("Birth Fund Registry ");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404771/Bump_Fund_y4aohu.png"
                    );
                  }}
                >
                  <p
                    className={
                      "Birth Fund Registry " === feature && "feature-bold"
                    }
                  >
                    Birth Fund Registry
                  </p>
                  <p className="feature-desc">
                    Beyond the clothes and pampers, we make it easy for friends
                    and family to help you afford the team you need for a stress
                    free experience from bump to baby. Share your fund link with
                    family and friends to meet your goals!
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "One-Stop Solution" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleClientSectionClick();
                    setFeature("One-Stop Solution");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404770/One_stop_solution_cbqafy.png"
                    );
                  }}
                >
                  <p
                    className={
                      "One-Stop Solution" === feature && "feature-bold"
                    }
                  >
                    One-Stop Solution
                  </p>
                  <p className="feature-desc">
                    Doing it all is possible with My Bump Team. Instead of
                    having several apps and website memberships, managing your
                    birth and postpartum experience has never been simpler and
                    stress-free with My Bump Team.
                  </p>
                </div>
                <div
                  className={`feature-info ${
                    "Community" === feature && "feature-box-shadow"
                  }`}
                  onMouseOver={(e) => {
                    handleClientSectionClick();
                    setFeature("Community");
                    setImgUrl(
                      "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1687404771/Community_jcb58c.png"
                    );
                  }}
                >
                  <p className={"Community" === feature && "feature-bold"}>
                    Community
                  </p>
                  <p className="feature-desc">
                    The real parent-hood! My Bump Team. Maternal Health Equity
                    partners host local curated events for parents and
                    professionals to form a village before and after the birth
                    of your baby .
                  </p>
                </div>

                <span className="invisible">dcdcdasddawede</span>
              </div>
            </div>

            <a
              href={auth.isAuthenticated ? "/quiz/intro" : "/client/signup"}
              className="button"
            >
              Get Started
            </a>
          </div>
        </div>

        <div className="review-section">
          <h1 className="review-title">A MATCH MADE IN.. My Bump Team</h1>
          <p className="review-line">
            We are with you from pregnancy through the first year of parenthood.
            Meet a few of the wonderful parents who made us a part of their
            journey.
          </p>
          <div className="review-body">
            <div className="reviews">
              <div className="review">
                <p className="review-desc">
                  I had a team of doulas and a midwife throughout my pregnancy.
                  I would highly recommnd it. My pregnancy and birth of my baby
                  girl was so smooth and relaxing. I actually enjoyed it !
                </p>
                <h2 className="reviewer-name">- A. H.</h2>
              </div>
              <div className="review">
                <p className="review-desc">
                  YES! THANK YOU FOR EVERYTHING!! I was about 7 months when I
                  finally decided to add a bump team. I thought I was too late
                  and it wouldn't make a difference, but the postpartum period
                  would have been a lonely, and unnecessarily hard without my
                  team. We're celebrating Asher's birthday soon and I am in awe
                  of how confident I feel being his mama.
                </p>
                <h2 className="reviewer-name">- T. C.</h2>
              </div>
            </div>
            <div className="review-video">
              <video controls className="actual-video">
                <source
                  src="https://res.cloudinary.com/dhhjtuhrd/video/upload/v1686774095/My_Bump_Team_FC3_dycb7j.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>

        {inputModal && <InputModal setInputModal={setInputModal} />}

        <Footer />
      </div>
    </>
  );
};

export default Home;
