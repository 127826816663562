import React, { useContext, useEffect, useState } from "react";
import ClientSideNav from "../../components/ClientSideNav";
import Navbar from "../../components/Navbar";
import "./ClientEducation.css";
import ClientNeedSubscription from "./ClientNeedSubscription";
import authContext from "../../contexts/Login";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/Loading";

const CourseCard = ({ courseId, setLoading }) => {
  const [course, setCourse] = useState("");
  const navigate = useNavigate();
  const auth = useContext(authContext);
  if (!auth.loading && !auth.user?.quizDone) {
    navigate("/quiz/intro");
  }

  const getCourse = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/getCourse/${courseId}`
    );
    setCourse(response.data.course);
  };

  const removeFromPlaylist = async (req, res) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/client/removeFromPlaylist`,
        { course: courseId },
        {
          headers: { authorization: auth.jwt },
        }
      );
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <>
      <div className="course-card">
        <div
          className="cc-picture"
          style={{ background: `url(${course?.poster?.url})` }}
          onClick={() => navigate(`/course/${courseId}/0`)}
        ></div>
        <div
          className="cc-info"
          onClick={() => navigate(`/course/${courseId}/0`)}
        >
          <h1 className="cc-title">{course?.title}</h1>
          <p className="cc-desc">{course?.description}</p>
        </div>
        <div
          className="cc-footer"
          onClick={() => navigate(`/course/${courseId}/0`)}
        >
          {course?.createdBy}
        </div>
        <button className="eclc_button" onClick={removeFromPlaylist}>
          Remove from Playlist
        </button>
      </div>
    </>
  );
};

const ClientEducation = () => {
  const auth = useContext(authContext);
  const [client, setClient] = useState();
  const [playlist, setPlaylist] = useState([]);
  const [loading, setLoading] = useState(false);
  const getClientData = async () => {
    // setLoading(true)
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/getClientData`,
      {
        headers: { authorization: auth.jwt },
      }
    );
    setClient(response.data);
    // getPlaylist(response.data);
    // setLoading(false)
  };

  useEffect(() => {
    getClientData();
    // getPlaylist()
  }, [loading]);
  return (
    <>
      <Navbar />
      <div className="ce-container">
        {auth?.user?.subscriptionStatus !== "active" ? (
          <ClientNeedSubscription />
        ) : (
          <div className="client-education">
            {/* <h2 className="ce-title">Your Course Playlist:</h2>
            <div className="ce-courses">
              <CourseCard />
            </div> */}
            <div className="ce-courses">
              {client?.playlist?.map((courseId) => {
                return (
                  <CourseCard courseId={courseId} setLoading={setLoading} />
                );
              })}
            </div>
          </div>
        )}
        <ClientSideNav />
        {loading && <LoadingScreen />}
      </div>
    </>
  );
};

export default ClientEducation;
