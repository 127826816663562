import React from "react";
import { useNavigate } from "react-router-dom";
import "./QuizIntro.css";
import QuizRedirect from "../../helpers/quizRedirect";

const QuizIntro = () => {
  const navigate = useNavigate();
  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="intro-desc">
        <p>
          Our comprehensive intake assessment considers your risk factors ,
          holistic health needs and desires before generating a personalized
          birth team for your ideal birth experience. Answer each question
          honestly to generate your accurate result.
        </p>
        <button
          className="qi-button"
          onClick={() => navigate("/quiz/demographic")}
        >
          Get Started!
        </button>
      </div>
    </div>
  );
};

export default QuizIntro;
