import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import "./CoursePage.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import authContext from "../../contexts/Login";

const LectureBox = ({ index, title, courseId }) => {
  const navigate = useNavigate();
  return (
    <div
      className="lecture-box"
      onClick={() => navigate(`/course/${courseId}/${index}`)}
    >
      <p>{index + 1}.</p>
      <p>{title}</p>
    </div>
  );
};

const CoursePage = () => {
  const [course, setCourse] = useState("");
  const [lectures, setLectures] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");

  const courseId = useLocation().pathname.split("/")[2];
  const lectureIndex = useLocation().pathname.split("/")[3];

  // const videoRef = useRef(null);
  // const handleLoadedData = () => {
  //   videoRef.current.play();
  // };

  const auth = useContext(authContext);

  const navigate = useNavigate()

  const getCourseLectures = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/course/${courseId}`,
        {
          withCredentials: true,
        }
      );
      setCourse(data.course);
      setLectures(data.lectures);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!auth.loading) {
      if (auth?.user?.subscriptionStatus !== "active") {
        console.log("lol")
        navigate("/subscribe");
        return;
      }
    }

    getCourseLectures();
  }, []);

  useEffect(() => {
    if (lectures.length > 0) {
      setVideoUrl(course?.lectures[Number(lectureIndex)]?.video.url);
    }
  }, [lectures, lectureIndex, course]);

  return (
    <>
      <Navbar />
      <div className="course-page">
        <div className="cp-left">
          <div className="cp-video">
            {videoUrl && (
              <video
                // ref={videoRef}
                autoPlay
                controls
                controlsList="nodownload"
                className="lecture-video"
                // onLoadedData={handleLoadedData}
                src={videoUrl}
                type="video/mp4"
              >
                {/* <source
                // src="https://res.cloudinary.com/dy7ao6r33/video/upload/v1682010354/xedamf1of5nxosqc1nfm.mp4"
                // src={lectures.length > 0 && course?.lectures[Number(lectureIndex)]?.video.url}
                src={videoUrl}
                type="video/mp4"
              /> */}
              </video>
            )}
          </div>
          <div className="cp-info">
            <h2 className="cp-lecture-title">
              {lectures.length > 0 &&
                course?.lectures[Number(lectureIndex)]?.title}
            </h2>
            {/* <h2 className="cp-lecture-title">course title</h2> */}
            <p className="cp-lecture-desc">
              {lectures.length > 0 &&
                course?.lectures[Number(lectureIndex)].description}
            </p>
            {/* <p className="cp-lecture-desc">course description</p> */}
          </div>
        </div>
        <div className="cp-right">
          {/* <LectureBox index={"index"} title={"title"} /> */}
          {lectures.map((lecture, index) => {
            return (
              <LectureBox
                index={index}
                title={lecture.title}
                courseId={courseId}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CoursePage;
