import React, { useContext, useEffect, useState } from "react";
import "./AdminQuizStatistics.css";
import Navbar from "../../components/Navbar";
import AdminSideBar from "./AdminSideBar";
import axios from "axios";
import authContext from "../../contexts/Login";
import { setRef } from "@mui/material";

const questions = [
  { question: "Choose Your Nearest Location", parameter: "location" },
  {
    question: "Will you be a first-time parent?",
    parameter: "firstTimeParent",
  },
  { question: "Age", parameter: "age" },
  { question: "Gender", parameter: "gender" },
  { question: "Affirming Pronouns", parameter: "pronouns" },
  { question: "Relationship Status", parameter: "relationshipStatus" },
  {
    question:
      "Significant Past or Current High Risk Pregnancy Factors (Select All That Apply)",
    parameter: "highRiskPregnancyFactors",
  },
  {
    question:
      "Significant Past or Current Obstetric / Gynecological Health History ( Select All That Apply)",
    parameter: "gynecologicalHealthHistory",
  },
  {
    question: "Any Medical Conditions Developed During Pregnancy:",
    parameter: "medicalConditions",
  },
  {
    question: "How do you feel about your weight and nutrition",
    parameter: "weightNutrition",
  },
  {
    question: "Amount of uninterrupted nightly rest",
    parameter: "sleep",
  },
  {
    question:
      "Do you have someone you can count on for help/support during this pregnancy?",
    parameter: "helpSupport",
  },
  {
    question:
      "Do you have someone you can talk to about stressful things in your life?",
    parameter: "talkTo",
  },
  {
    question: "How are you feeling about being pregnant?",
    parameter: "feeling",
  },
  {
    question: "In the last month, have you felt down, depressed, or hopeless?",
    parameter: "lmFeeling",
  },
  {
    question:
      "During the past month, have you often been bothered by little interest or pleasure in doing things?",
    parameter: "lmBothered",
  },
  {
    question: "I’m seeking support with... ( Select All That Apply )",
    parameter: "supportWith",
  },
  {
    question: "Where do you see yourself giving birth?",
    parameter: "seeYourself",
  },
];

const AdminQuizStatistics = () => {
  const auth = useContext(authContext);
  const [quiz, setQuiz] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/getQuizStatistics`, {
        headers: {
          authorization: auth.jwt,
        },
      })
      .then((res) => {
        setQuiz(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  console.log(quiz);
  return (
    <div>
      <Navbar />
      <div className="admin-quiz-statistics">
        <div className="admin-quiz-statistics-container">
          <h2 className="admin-quiz-statistics-title">Quiz Statistics</h2>
          {quiz &&
            questions.map((question, index) => {
              let quest = quiz[question.parameter];
              let stats = Object.entries(quest.statistics);
              return (
                <div key={index} className="quiz-question-result">
                  <p className="quiz-question">{quest.question}</p>
                  <table className="quiz-stat">
                    {stats.map((stat, index) => {
                      return (
                        <tr key={index}>
                          <td>{stat[0]}:</td>
                          <td>{stat[1]}</td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              );
            })}
        </div>
        <AdminSideBar />
      </div>
    </div>
  );
};

export default AdminQuizStatistics;
