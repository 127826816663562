import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import ClientSideNav from "../../components/ClientSideNav";
import "./ClientChatPage.css";
import authContext from "../../contexts/Login";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FcComments } from "react-icons/fc";
import SpecialistSideNav from "../../components/SpecialistSideNav";
import ChatWarning from "./ChatWarning";

const triggerWords = [
  "email",
  "@gmail.com",
  "phone",
  "call",
  "number",
  "contact",
  "instagram",
  "cashapp",
  "paypal",
  "zelle",
];

const PeopleCard = ({ name, imgUrl, chatURL }) => {
  const auth = useContext(authContext);
  return (
    <a
      href={`/client/chat/${chatURL}`}
      // href={`/${auth.specialist ? "specialist" : "client"}/chat/${chatURL}`}
      className="people_card_chat"
    >
      <img src={imgUrl} alt="" className="pcc_picture" />
      <p>{name}</p>
    </a>
  );
};

function getChatID(selfID, otherID, client = true) {
  try {
    if (client) {
      return `${selfID}:${otherID}`;
    } else {
      return `${otherID}:${selfID}`;
    }
  } catch {
    return "";
  }
}

let called = false;
const ClientChatPage = () => {
  const inputRef = useRef();
  const listRef = useRef();
  const auth = useContext(authContext);
  const [messages, setMessages] = useState([]);
  const [modal, setModal] = useState(false);
  const [socket, setSocket] = useState(null);
  const { id } = useParams();
  const [birthTeam, setBirthTeam] = useState([]);
  const roomID = getChatID(
    auth.user ? auth.user._id : "",
    id,
    !auth.specialist
  );
  async function connectSocket() {
    const socket = await io(
      `${process.env.REACT_APP_API_URL.replace("/api", "")}`,
      {
        query: {
          auth: auth.jwt,
          roomId: roomID,
        },
      }
    );
    socket.on("connect", () => {});
    socket.on("error", () => {
      console.error("error connecting to server");
    });
    socket.on("message", (msg) => {
      if (msg) {
        setMessages((old) => [...old, msg]);
      }
    });

    setSocket(socket);
  }

  function scrollBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }
  async function onMessage(evt) {
    evt.preventDefault();
    const msg = inputRef?.current?.value ?? "";
    if (msg == "") return;
    let triggered = false;
    for (let i = 0; i < triggerWords.length; i++) {
      if (msg.toLowerCase().includes(triggerWords[i])) {
        setModal(true);
        triggered = true;
        break;
      }
    }
    if (!triggered) sendMessage(msg);
  }
  function sendMessage(msg) {
    setMessages((old) => [...old, { message: msg, from: auth.user._id }]);

    socket.emit("message", { message: msg, to: id });
    inputRef.current.value = "";
  }
  function onCloseModal(msg) {
    setModal(false);
    inputRef.current.value = "";
  }
  async function getChats() {
    if (called) return;
    if (messages && messages?.length > 0) {
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/chat/${roomID}`, {
        headers: {
          authorization: auth.jwt,
        },
      })
      .then((res) => {
        const chats = res.data ?? [];
        setMessages((old) => [...chats, ...old]);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  useEffect(() => {
    if (!id) return;
    socket?.disconnect();
    if (auth.user) {
      getChats();
      called = true;
      connectSocket();
    }
  }, [auth.user, id, auth.specialist]);
  useEffect(() => {
    scrollBottom();
  }, [messages]);
  async function getChatMembers() {
    const req1 = axios
      .get(
        `${process.env.REACT_APP_API_URL}/${
          auth.specialist ? "specialist/getClients" : "client/getBirthTeam"
        }`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .catch((err) => {
        console.error(err);
      });
    const req2 = axios
      .get(
        `${process.env.REACT_APP_API_URL}/${
          auth.specialist
            ? "specialist/getInquiryClients"
            : "client/getSpecialistInquiry"
        }`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .catch((err) => {
        console.error(err);
      });
    const req3 = axios
      .get(
        `${process.env.REACT_APP_API_URL}/${
          auth.specialist
            ? "specialist/getFinalisedClients"
            : "client/getFinalisedBirthTeam"
        }`,
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .catch((err) => {
        console.error(err);
      });

    const [res, res2, res3] = await Promise.all([req1, req2, req3]);

    setBirthTeam(
      auth.specialist
        ? [...(res.data ?? []), ...(res2.data ?? []), ...res3.data]
        : [
            ...(res.data.birthTeam ?? []),
            ...(res2.data.specialistInquiry ?? []),
            ...res3.data,
          ]
    );
  }
  useEffect(() => {
    getChatMembers();
  }, [auth.specialist]);

  return (
    <div ref={listRef} className=" client_chat_page">
      <Navbar />
      <div className="ccp_main">
        <div className="ccp_people">
          {birthTeam.map((person, index) => (
            <PeopleCard
              key={index}
              name={person.name}
              imgUrl={person.headshot}
              chatURL={person._id}
            />
          ))}
        </div>
        {id ? (
          <div className="ccp_chat">
            {messages.map((message, key) => {
              return (
                <p
                  key={key}
                  className={
                    message.from === auth.user._id
                      ? "ccp_chat_sent"
                      : "ccp_chat_received"
                  }
                >
                  {message.message}
                </p>
              );
            })}
            <form onSubmit={onMessage}>
              <input ref={inputRef} type="text" className="ccp_chat_input" />
              <button type="submit" hidden />
            </form>
          </div>
        ) : (
          <div className="ccp_chat ccp_rest">
            <FcComments />
            <h2>Select a chat to start messaging!</h2>
          </div>
        )}
      </div>
      {auth.specialist ? <SpecialistSideNav /> : <ClientSideNav />}
      {modal && <ChatWarning onCloseModal={onCloseModal} />}
    </div>
  );
};

export default ClientChatPage;
