import React, { useContext, useEffect, useState } from "react";
import ClientSideNav from "../../components/ClientSideNav";
import Navbar from "../../components/Navbar";
import "../ClientOnboarding/ClientEducation.css";
import ClientNeedSubscription from "../ClientOnboarding/ClientNeedSubscription";
import authContext from "../../contexts/Login";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/Loading";
import SpecialistSideNav from "../../components/SpecialistSideNav";

const CourseCard = ({ courseId, setLoading }) => {
  const [course, setCourse] = useState("");
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const getCourse = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/getCourse/${courseId}`
    );
    setCourse(response.data.course);
  };

  const removeFromPlaylist = async (req, res) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/specialist/removeFromPlaylist`,
        { course: courseId },
        {
          headers: { authorization: auth.jwt },
        }
      );
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(auth);
    getCourse();
  }, []);

  return (
    <>
      <div className="course-card">
        <div
          className="cc-picture"
          style={{ background: `url(${course?.poster?.url})` }}
          onClick={() => navigate(`/course/${courseId}/0`)}
        ></div>
        <div
          className="cc-info"
          onClick={() => navigate(`/course/${courseId}/0`)}
        >
          <h1 className="cc-title">{course?.title}</h1>
          <p className="cc-desc">{course?.description}</p>
        </div>
        <div
          className="cc-footer"
          onClick={() => navigate(`/course/${courseId}/0`)}
        >
          {course?.createdBy}
        </div>
        <button className="eclc_button" onClick={removeFromPlaylist}>
          Remove from Playlist
        </button>
      </div>
    </>
  );
};

const SpecialistEducation = () => {
  const auth = useContext(authContext);
  const [specialist, setSpecialist] = useState();
  const [playlist, setPlaylist] = useState([]);
  const [loading, setLoading] = useState(false);
  const getSpecialistData = async () => {
    // setLoading(true)
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/specialist/getSpecialistData`,
      {
        headers: { authorization: auth.jwt },
      }
    );
    setSpecialist(response.data);
    // getPlaylist(response.data);
    // setLoading(false)
  };

  useEffect(() => {
    getSpecialistData();
    // getPlaylist()
  }, [loading]);
  return (
    <>
      <Navbar />
      <div className="ce-container">
        {auth?.user?.subscriptionStatus !== "active" ? (
          <ClientNeedSubscription />
        ) : (
          <div className="client-education">
            {/* <h2 className="ce-title">Your Course Playlist:</h2>
            <div className="ce-courses">
              <CourseCard />
            </div> */}
            <div className="ce-courses">
              {specialist?.playlist?.map((courseId) => {
                return (
                  <CourseCard courseId={courseId} setLoading={setLoading} />
                );
              })}
            </div>
          </div>
        )}
        <SpecialistSideNav />
        {loading && <LoadingScreen />}
      </div>
    </>
  );
};

export default SpecialistEducation;
