import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP2.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP2 = () => {
  const navigate = useNavigate();
  const [option, setOption] = useState(0);
  const [optionText, setOptionText] = useState("");
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setOptionText(event.currentTarget.textContent);
  };

  const handleNext = async () => {
    quiz.setResults((old) => {
      return {
        ...old,
      firstTimeParent: optionText,
      };
    });
    navigate("/quiz/page3");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-2">
        <h1 className="qp2-title">Will you be a first-time parent?</h1>
        <div className="qp2-options">
          <div
            className={`qp2-option ${option === 1 && "yellow-background"}`}
            onClick={(e) => {
              setOption(1);
              handleClick(e);
            }}
          >
            Yes
          </div>
          <div
            className={`qp2-option ${option === 2 && "yellow-background"}`}
            onClick={(e) => {
              setOption(2);
              handleClick(e);
            }}
          >
            No
          </div>
        </div>
        <div className="qp2-buttons">
          <button
            className="qp2-button"
            onClick={() => navigate("/quiz/page1")}
          >
            Previous Page
          </button>
          <button className="qp2-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP2;
