import React, { useContext, useState } from "react";
import "./ChooseRejectReason.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authContext from "../contexts/Login";
import axios from "axios";

const reasons = [
  "I do not think we were the right fit",
  "I no longer need to add your services",
  "We came across another provider that we will add to our birth team",
  "We are too far for your services",
];

const ChooseRejectSpecialistReason = ({ msg, onCloseModal, specialist }) => {
  const [selectedReasons, setSelectedReasons] = useState(
    new Array(reasons.length).fill(false)
  );
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const setReason = (index, isSelected) => {
    const selections = [...selectedReasons];
    selections[index] = isSelected;
    setSelectedReasons(selections);
  };
  const rejectSpecialist = async () => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/client/rejectSpecialist`,
      {
        specialist,
        reasons: reasons.filter((_reason, index) => {
          return selectedReasons[index];
        }),
      },
      {
        headers: {
          authorization: auth.jwt,
        },
      }
    );
    navigate("/client/find-matches", { replace: true });
  };

  return (
    <div className="choose_reason">
      <div className="cr_container">
        <p className="cr_head">Why did you reject this provider?</p>
        <div className="reject_reasons">
          {reasons.map((reason, index) => {
            return (
              <div key={index} className="reason_container">
                <input
                  onChange={(evt) => {
                    setReason(index, evt.target.checked);
                  }}
                  type="checkbox"
                />
                <p>{reason}</p>
              </div>
            );
          })}
        </div>
        <div className="cr_buttons">
          <button onClick={() => onCloseModal()} className="cr_button cr_red">
            Cancel
          </button>
          <button
            onClick={() => {
              rejectSpecialist();
              toast.success("Thanks for your response");
            }}
            className="cr_button"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseRejectSpecialistReason;
