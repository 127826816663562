import React, { useContext, useEffect, useState } from "react";
import "./ProfilePageModal.css";
import { MdClose } from "react-icons/md";
import axios from "axios";
import authContext from "../contexts/Login";
import { useNavigate } from "react-router-dom";
import ChooseRejectSpecialistReason from "./ChooseRejectSpecialistReason";
import { toast } from "react-toastify";

const ProfilePageModal = ({ id, setProfileModal }) => {
  const auth = useContext(authContext);
  const [profile, setProfile] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const navigate = useNavigate();
  const [timings, setTimings] = useState([]);
  const [availability, setAvailability] = useState();
  const [contract, setContract] = useState();
  const [reviewText, setReviewText] = useState("");

  const isInBirthTeam = auth.user.birthTeam.includes(id);
  const isInFinalisedTeam = auth.user.finalisedBirthTeam.includes(id);

  const getSpecialistData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/specialist/getSpecialistData/${id}`
    );
    setProfile(response.data);
  };

  const addBirthTeam = async (id) => {
    const response = await axios
      .put(
        `${process.env.REACT_APP_API_URL}/client/addBirthTeam`,
        {
          specialist: id,
        },
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .catch((err) => {
        console.error(err);
      });
    if (response?.data?.added) {
      navigate("/client/my-birth-team");
    } else {
      setWaiting(true);
    }
  };

  const saveAvailability = async (e) => {
    e.preventDefault();
    const { data } = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/availability/create-availability`,
        {
          specialist: id,
          timestamp: timings,
        },
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .catch((err) => {
        console.error(err);
      });
    if (data) {
      setAvailability(data);
    }
  };

  const setReview = async (review) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/availability/set-review`,
        {
          specialist: id,
          review,
        },
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .then(({ data }) => {
        setAvailability(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAvailability = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/availability/get-availability`,
      {
        headers: {
          authorization: auth.jwt,
        },
        params: {
          specialist: id,
          client: auth.user._id,
        },
      }
    );
    setAvailability(response.data);
  };
  const getContract = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/contracts/get-contract`,
      {
        headers: {
          authorization: auth.jwt,
        },
        params: {
          specialist: id,
          client: auth.user._id,
        },
      }
    );
    setContract(response.data);
  };

  const setContractStatus = async (status) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/contracts/set-contract-status`,
        {
          specialist: id,
          status,
        },
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      )
      .then(({ data }) => {
        setContract(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const setWaitingValue = () => {
    if (
      auth.user.approvedBirthTeam &&
      auth.user.approvedBirthTeam.includes(id)
    ) {
      setWaiting(true);
    }
  };
  useEffect(() => {
    getSpecialistData();
    getAvailability();
    getContract();
    setWaitingValue();
  }, []);

  if (profile) {
    return (
      <div className="profile_modal">
        <div className="pm_left">
          <div
            className="pm_picture"
            style={{ background: `url(${profile.headshot})` }}
          ></div>
          <div className="pm_info">
            <p className="pmi_head">Location</p>
            <p className="pmi_info">{profile?.location}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Pronouns</p>
            <p className="pmi_info">{profile?.pronouns}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Fee</p>
            <p className="pmi_info">$ {profile?.fee / 100}</p>
          </div>
        </div>
        <div className="pm_right">
          <div className="pr_head">
            <p className="pm_name">{profile?.name}</p>
            <MdClose onClick={() => setProfileModal(false)} />
          </div>
          <p className="pm_specialty">{profile?.specialty}</p>
          <a href={`/client/chat/${id}`}>
            <button className="pm_chat_button">Chat Now</button>
          </a>
          <div className="pm_info">
            <p className="pmi_head">Bio</p>
            <p className="pmi_info">{profile?.bio}</p>
          </div>
          <div className="pm_bottom">
            {!availability && (
              <form
                className="pm_inputs pm_calendar"
                onSubmit={saveAvailability}
              >
                <p className="pminp_head">Select Availability: </p>
                <input
                  className="pminp_in"
                  type="datetime-local"
                  required
                  value={timings}
                  onChange={(e) => setTimings(e.target.value)}
                />
                <button className="pm_add">Save</button>
              </form>
            )}

            {availability && (
              <div>
                <p className="pminp_head">
                  Meet Time:
                  <span>
                    {new Date(availability.timestamp).toLocaleDateString()}{" "}
                    {new Date(availability.timestamp).toLocaleTimeString()}
                  </span>
                </p>
              </div>
            )}

            {availability && new Date(availability.timestamp) < new Date() && (
              <div className="pm_buttons">
                <p>How was your meet?</p>
                {availability.review === 0 && (
                  <>
                    <button onClick={() => setReview(1)} className="pm_add">
                      Positive
                    </button>
                    <button onClick={() => setReview(-1)} className="pm_reject">
                      Not so Positive
                    </button>
                  </>
                )}
                {availability.review === 1 && (
                  <div className="pm_add">Positive</div>
                )}
                {availability.review === -1 && (
                  <>
                    <div className="pm_reject">Not so Positive</div>
                    <button onClick={() => setReview(0)} className="pm_reject">
                      Revert
                    </button>
                  </>
                )}
              </div>
            )}

            <div className="pm_contract">
              {availability?.review !== 0 && !contract && (
                <p>Contract from the specialist side coming soon.</p>
              )}
              {availability?.review !== 0 && contract && (
                <>
                  <p>
                  By clicking on yes you agree to all the terms and conditions of the contract. Please upload your signed contract to the Documents section of your portal.
                  </p>
                  <div className="contract_link">
                    Contract Link:{" "}
                    <a href={contract.document} target="_blank">
                      View Contract
                    </a>{" "}
                  </div>
                  <div className="pm_buttons">
                    {contract.status === 0 && (
                      <>
                        <button
                          onClick={() => {
                            setContractStatus(1);
                          }}
                          className="pm_add"
                        >
                          YES
                        </button>
                        <button
                          onClick={() => {
                            setContractStatus(-1);
                          }}
                          className="pm_reject"
                        >
                          NO
                        </button>
                      </>
                    )}
                    {contract.status === 1 && (
                      <>
                        <div className="pm_add">ACCEPTED</div>
                      </>
                    )}
                    {contract.status === -1 && (
                      <>
                        <button className="pm_reject">NO</button>

                        <button
                          onClick={() => {
                            setContractStatus(0);
                          }}
                          className="pm_reject"
                        >
                          Revert
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            {contract?.status === 1 && (
              <>
                {waiting ? (
                  <p>Waiting for approval from specialist side</p>
                ) : isInFinalisedTeam ? (
                  <></>
                ) : (
                  <div className="pm_buttons">
                    {!isInBirthTeam && (
                      <button
                        className="pm_add"
                        onClick={() => {
                          addBirthTeam(id);
                        }}
                      >
                        Add to Bump Team
                      </button>
                    )}
                    <button
                      onClick={() => setRejectModal(true)}
                      className="pm_reject"
                    >
                      Reject
                    </button>
                  </div>
                )}
              </>
            )}
            {rejectModal && (
              <ChooseRejectSpecialistReason
                specialist={id}
                onCloseModal={() => setRejectModal(false)}
              />
            )}

            {isInFinalisedTeam && (
              <div className="add_review">
                <input
                  value={reviewText}
                  onChange={(evt) => setReviewText(evt.target.value)}
                  type="text"
                  placeholder="Enter Review"
                  className="review_input"
                />
                <button
                  className="add_review_button"
                  onClick={() => {
                    if (reviewText.trim()) {
                      axios
                        .post(
                          `${process.env.REACT_APP_API_URL}/reviews`,
                          {
                            specialist: id,
                            review: reviewText,
                          },
                          {
                            headers: {
                              authorization: auth.jwt,
                            },
                          }
                        )
                        .then(() => {
                          toast.success("Thanks for your review");
                          setReviewText("");
                        });
                    }
                  }}
                >
                  Add Review
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ProfilePageModal;
