import { useContext } from "react";
import { Navigate } from "react-router-dom";
import authContext from "../contexts/Login";

export default function QuizRedirect(){
    const auth = useContext(authContext)
    console.log("calling")
    console.log(auth.user)
    if(auth.user?.quizDone)
    return <Navigate to="/client/find-matches" replace={true} />
    return null
} 