import React, { useContext } from "react";
import authContext from ".";
import LoadingScreen from "../../components/Loading";
import { Navigate } from "react-router-dom";

function AdminProtect({ children }) {
  const auth = useContext(authContext);
  console.log(auth.loading,'loading')
  if (auth.loading) {
    console.log(auth.admin,'from laoding')
    return <LoadingScreen />;
  }
  if (!auth.isAuthenticated) {
    return <Navigate to={"/specialist/login"} />;
  }
  if (!auth.admin) {
    return <Navigate to={"/"} />;
  }
  return <>{children}</>;
}

export default AdminProtect;
