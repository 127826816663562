import React, { useEffect, useState } from "react";
import "./CourseModal.css";
import { MdClose } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingScreen from "../../components/Loading";
import { toast } from "react-hot-toast";

const CmLectureBox = ({ title, description, index, courseId, lectureId, setLoading }) => {
  
  const deleteLecture = async () => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/lecture?courseId=${courseId}&lectureId=${lectureId}`
      );
      toast.success("Lecture deleted successfully")
      window.location.reload();
    } catch (error) {
      console.error(error);
    }

    // await getCourseLectures()
  };
  return (
    <div className="cm-lecture-box">
      <div className="cmlb-left">
        <h4>
          {index} {title}
        </h4>
        <p>{description}</p>
      </div>
      <div className="cmlb-right" onClick={() => deleteLecture()}>
        <AiFillDelete />
      </div>
    </div>
  );
};

const CourseModal = () => {
  const navigate = useNavigate();

  const [course, setCourse] = useState("");
  const [lectures, setLectures] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [video, setVideo] = useState("");
  const [videoPrev, setVideoPrev] = useState("");
  const [loading, setLoading] = useState(false);

  const path = useLocation().pathname.split("/")[3];
  const getCourseLectures = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/course/${path}`,
        {
          withCredentials: true,
        }
      );
      setCourse(data.course);
      setLectures(data.lectures);
    } catch (error) {
      console.error(error);
    }

  };

  const changeVideoHandler = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setVideoPrev(reader.result);
      setVideo(file);
      if (file) {
      }
    };
    reader.readAsDataURL(file);
  };

  const addLectureHandler = async (e) => {
    e.preventDefault();
    const myForm = new FormData();
    // myForm.append("id", path)
    myForm.append("title", title);
    myForm.append("description", description);
    myForm.append("file", video);

    const addLecture = async () => {
      try {
        setLoading(true);

        const config = {
          headers: {
            "Content-type": "multipart/form-data",
          },
          withCredentials: true,
        };
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/course/${path}`,
          myForm,
          config
        );
        setLoading(false);
        toast.success("Lecture added successfully! It might take a few minutes to reflect on the website.")
        // dispatch({ type: 'createCourseSuccess', payload: data.message });
      } catch (error) {
        // dispatch({
        //   type: 'createCourseFail',
        //   payload: error.response.data.message,
        // });
        setLoading(false);
        toast.error("Something went wrong, please try again later");
        console.error(error);
      }
    };
    await addLecture();
  };



  useEffect(() => {
    getCourseLectures();
    // console.log("getting");
  }, [loading]);

  useEffect(() => {
    getCourseLectures();
    // console.log("getting");
  }, []);

  return (
    <>
      <div className="course-modal">
        <div className="cm-top">
          <h3>{course.title}</h3>
          <div
            className="close-modal"
            onClick={() => navigate("/admin/courses")}
          >
            <MdClose />
          </div>
        </div>
        <div className="cm-main">
          <div className="cmm-left">
            <h2>{course.title}</h2>
            <h3>Lectures:</h3>
            <div className="cmm-lecture-list">
              {lectures.length === 0 && (
                <CmLectureBox
                  title={"No Lectures"}
                  description={"Please add a lecture"}
                  // getCourseLectures={getCourseLectures}
                  setLoading={setLoading}
                  // index={"index+1"}
                  // courseId={"courseID"}
                  // lectureId={"lectureID"}
                />
              )}
              {lectures.map((lecture, index) => {
                return (
                  <CmLectureBox
                    title={lecture.title}
                    description={lecture.description}
                    index={index + 1}
                    courseId={path}
                    lectureId={lecture._id}
                  />
                );
              })}
            </div>
          </div>
          <div className="cmm-right">
            <h2>Add Lecture</h2>
            <form onSubmit={addLectureHandler}>
              <div className="cmr-inputs">
                <input
                  type="text"
                  placeholder="Title"
                  className="cmr-input"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Description"
                  className="cmr-input"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <label htmlFor="" className="lecture-upload">
                  <input
                    type="file"
                    accept="video/mp4"
                    onChange={changeVideoHandler}
                  />
                  Select File
                </label>
                {video && (
                  //   <video
                  //     controlsList="nodownload"
                  //     controls
                  //     src={videoPrev}
                  //     style={{ width: "20vw" }}
                  //   ></video>
                  <p>{video.name} Ready for upload</p>
                )}
                <button type="submit">ADD</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading && <LoadingScreen />}
    </>
  );
};

export default CourseModal;
