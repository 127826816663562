import React, { useContext, useState } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../contexts/Login";
import { AiOutlineMenu } from "react-icons/ai";

const NavModal = ({ setNavmodal }) => {
  const auth = useContext(authContext);
  function getDashboardLink() {
    return auth.specialist ? "/specialist/profile" : "/client/profile";
  }

  return (
    <div className="nav_modal_container" onClick={() => setNavmodal(false)}>
      <div className="nav_modal">
        <Link to={"/"} className="nm_item">
          {" "}
          Home{" "}
        </Link>
        <a
          href="https://www.heirbloomco.com/shop"
          target="_blank"
          className="nm_item"
        >
          {" "}
          Shop{" "}
        </a>

        <Link to={"/for-providers"} className="nm_item">
          {" "}
          For Providers{" "}
        </Link>
        <Link to={"/services"} className="nm_item">
          {" "}
          For Parents{" "}
        </Link>
        <Link to={"/impact"} className="nm_item">
          {" "}
          Impact{" "}
        </Link>
        <Link to={"/education"} className="nm_item">
          {" "}
          Education{" "}
        </Link>
        {/* <p> Shop </p> */}
        {/* &nbsp;|&nbsp; */}
        {/* <p> Schedule </p>
        &nbsp;|&nbsp; */}
        {/* <p className='sign-in-button' onClick={() => navigate("/admin/courses")}> Dashboard </p> */}
        <Link
          className="sign-in-button nm_item"
          to={auth.isAuthenticated ? getDashboardLink() : "/choose"}
        >
          {" "}
          {auth.isAuthenticated ? "Dashboard" : "SIGN IN"}{" "}
        </Link>
      </div>
    </div>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const auth = useContext(authContext);
  function getDashboardLink() {
    return auth.specialist ? "/specialist/profile" : "/client/profile";
  }
  const [navModal, setNavmodal] = useState(false);
  return (
    <div className="navbar">
      <Link to={"/"}>
        <div className="logo"></div>
      </Link>
      <div className="options">
        &nbsp;|&nbsp;
        <a href="https://www.heirbloomco.com/shop" target="_blank">
          {" "}
          Shop{" "}
        </a>
        &nbsp;|&nbsp;
        <Link to={"/for-providers"}> For Providers </Link>
        &nbsp;|&nbsp;
        <Link to={"/services"}> For Parents </Link>
        &nbsp;|&nbsp;
        <Link to={"/impact"}> Impact </Link>
        &nbsp;|&nbsp;
        <Link to={"/education"}> Education </Link>
        &nbsp;|&nbsp;
        {/* <p> Shop </p> */}
        {/* &nbsp;|&nbsp; */}
        {/* <p> Schedule </p>
        &nbsp;|&nbsp; */}
        {/* <p className='sign-in-button' onClick={() => navigate("/admin/courses")}> Dashboard </p> */}
        <Link
          className="sign-in-button"
          to={auth.isAuthenticated ? getDashboardLink() : "/choose"}
        >
          {" "}
          {auth.isAuthenticated ? "Dashboard" : "SIGN IN"}{" "}
        </Link>
      </div>
      <AiOutlineMenu className="nav_menu" onClick={() => setNavmodal(true)} />
      {navModal && <NavModal setNavmodal={setNavmodal} />}
    </div>
  );
};

export default Navbar;
