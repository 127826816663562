import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import SideNav from "../../components/ClientSideNav";
import "./ClientFindMatches.css";
import axios from "axios";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import TinderCard from "react-tinder-card";
import authContext from "../../contexts/Login";
import ClientNeedSubscription from "./ClientNeedSubscription";
import { useNavigate } from "react-router-dom";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const specialistCategories = [
  "Choose a Specialty",
  "Doula",
  "Midwife",
  "Reiki Practitioner",
  "Webster Certified Chiropractor",
  "Health & Wellness Coach",
  "Sound Bath Practitioner",
  "Acupuncturist",
  "Pelvic Floor Therapist",
  "International Board Certified Lactation Consultant",
  "Newborn Care Specialist",
];

const ClientFindMatches = () => {
  const auth = useContext(authContext);
  const [specialists, setSpecialists] = useState([]);
  const [specialty, setSpecialty] = useState("");
  const navigate = useNavigate();
  if (!auth.loading && !auth.user?.quizDone) {
    navigate("/quiz/intro");
  }

  const getAllSpecialists = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/matchSpecialists`,
      {
        headers: { authorization: auth.jwt },
      }
    );
    setSpecialists(res.data);
  };

  const getSpecialistByCategory = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}${
        specialty === "Choose a Specialty" || specialty === ""
          ? `/client/matchSpecialists`
          : `/specialist/getSpecialistByCategory/${specialty}`
      }`,
      {
        headers: { authorization: auth.jwt },
      }
    );
    setSpecialists(res.data);
    console.log(res.data);
  };

  useEffect(() => {
    getAllSpecialists();
  }, []);

  useEffect(() => {
    getSpecialistByCategory();
  }, [specialty]);

  const handleSwipeRight = async (tinderCardRef) => {
    if (tinderCardRef.current) {
      await tinderCardRef.current.swipe("right"); // Trigger right swipe
      await tinderCardRef.current.swipe("right"); // Trigger right swipe
    }
  };

  const handleSwipeLeft = async (tinderCardRef) => {
    if (tinderCardRef.current) {
      await tinderCardRef.current.swipe("left"); // Trigger right swipe
      await tinderCardRef.current.swipe("left"); // Trigger right swipe
    }
  };

  return (
    <>
      <Navbar />
      <div className="cfm-container">
        {auth?.user?.subscriptionStatus === "active" && (
          <form
            action="#"
            onChange={(e) => {
              setSpecialty(e.target.value);
            }}
            className="cfm-select"
          >
            <label htmlFor="lang">Specialty:</label>
            <select name="specialty" id="lang">
              {specialistCategories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </form>
        )}
        {auth?.user?.subscriptionStatus === "active" && (
          <button
            className="see_suggestions"
            onClick={() => navigate("/client/quizResult")}
          >
            See Suggestions Based on Quiz
          </button>
        )}
        {auth?.user?.subscriptionStatus === "active" && (
          <div className="cfm-swipe-container">
            <div className="cfm-cards-container">
              {specialists?.map(function (character, index) {
                return (
                  <TinderSpecialist
                    key={index}
                    auth={auth}
                    character={character}
                    handleSwipeLeft={handleSwipeLeft}
                    handleSwipeRight={handleSwipeRight}
                    index={index}
                  />
                );
              })}
            </div>
          </div>
        )}
        {auth?.user?.subscriptionStatus !== "active" && (
          <ClientNeedSubscription />
        )}

        <SideNav />
      </div>
    </>
  );
};

export default ClientFindMatches;

function TinderSpecialist({
  character,
  index,
  handleSwipeLeft,
  handleSwipeRight,
  auth,
}) {
  const tinderCardRef = useRef(null);
  return (
    <TinderCard
      ref={tinderCardRef}
      className="swipe"
      key={index}
      onCardLeftScreen={(text) => {
        if (text === "right") {
          axios
            .put(
              `${process.env.REACT_APP_API_URL}/client/addSpecialistInquiry`,
              {
                specialist: character._id,
              },
              {
                headers: {
                  authorization: auth.jwt,
                },
              }
            )
            .catch((err) => {
              console.error(err);
            });
        }
      }}
    >
      <div className="cfm-card">
        <div
          className="cfmc-photo"
          style={{
            backgroundImage: `url(${character.headshot})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="cfm-name-age">{character?.name}</div>
        <div className="cfm-credentials">
          <div className="cfmc-intro cfmc-option">
            <PermIdentityIcon />
            <p>{character.pronouns || "Founder at Lorem, ipsum dolor."}</p>
          </div>
          <div className="cfmc-education cfmc-option">
            <WorkOutlineIcon />
            <p>{character.specialty || "USC"}</p>
          </div>
          <div className="cfmc-location cfmc-option">
            <LocationOnOutlinedIcon />
            <p>{character.location || "2 miles away"}</p>
          </div>
        </div>
        <div className="cfmc-bottom-nav">
          <p onClick={() => handleSwipeLeft(tinderCardRef)}>
            <CloseIcon />
          </p>
          <p>
            <StarRateRoundedIcon />
          </p>
          <p onClick={() => handleSwipeRight(tinderCardRef)}>
            <FavoriteRoundedIcon />
          </p>
        </div>
        {/* <div className="cfmc-view-profile">
          <p>See Full Profile</p>
        </div> */}
      </div>
    </TinderCard>
  );
}
