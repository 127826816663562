import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP18.css";
import quizContext from "../../contexts/QuizResult";
import authContext from "../../contexts/Login";
import axios from "axios";
import QuizRedirect from "../../helpers/quizRedirect";

const QP18 = () => {
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const options = [];
  const quiz = useContext(quizContext);

  const [option,setOption] = useState("");

  const Risks = [
    {
      name: "Hospital",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1691589366/Hospital_kv3uwb.jpg",
    },
    {
      name: "Birthing Pool",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1691589361/birth_pool_k5lokc.jpg",
    },
    {
      name: "Birthing Center",
      img: "https://res.cloudinary.com/dhhjtuhrd/image/upload/v1691589371/birth_center_jrlrjm.jpg",
    },
  ];

//   const handleChange = (e, risk) => {
//     if (e.target.checked) {
//       if (!options.includes(risk)) {
//         options.push(risk);
//       }
//     } else {
//       if (options.includes(risk)) {
//         options.splice(options.indexOf(risk), 1);
//       }
//     }
//   };

  const handleNext = async () => {
    quiz.setResults((old) => {
      return {
        ...old,
        seeYourself: option,
      };
    });
    await axios.put(
      `${process.env.REACT_APP_API_URL}/client/addToQuizResults`,
      {
        ...quiz.results,
        seeYourself: option,
      },
      {
        headers: {
          authorization: auth.jwt,
        },
      }
    );
    navigate("/client/quizResult");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-18">
        <h1 className="qp18-title">Where do you see yourself giving birth?</h1>
        <div className="qp18-options">
          {Risks.map((risk) => (
            <div className="qp18-option" onClick={() => setOption(risk.name)}>
              <img src={risk.img} alt="" />
              <p className={option === risk.name && "yellow-background"}>{risk.name}</p>
            </div>
          ))}
        </div>

        <div className="qp18-buttons">
          <button
            className="qp18-button"
            onClick={() => navigate("/quiz/page17")}
          >
            Previous Page
          </button>
          <button className="qp18-button" onClick={handleNext}>
            Finish
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP18;
