import React, { useContext, useEffect, useState } from "react";
import "./Signup.css";
import Navbar from "../components/Navbar";
import authContext from "../contexts/Login";
import { useLocation, useNavigate } from "react-router-dom";

const Signup = () => {
  const auth = useContext(authContext);
  const navigate = useNavigate();

  const [input, setIntput] = useState({
    name: "",
    email: "",
    password: "",
  });

  const navigateOut = () => {
    if (path === "/specialist/signup") {
      navigate("/specialist/profile", { replace: true });
    } else {
      navigate("/client/profile", { replace: true });
    }
  };

  const updateInput = (evt) => {
    const { name, value } = evt.target;
    setIntput((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };
  const login = async (evt) => {
    evt.preventDefault();
    const res = await auth.register(
      input,
      path === "/specialist/signup",
      () => {
        navigateOut();
      }
    );
  };

  const location = useLocation();

  const path = location.pathname;
  useEffect(() => {
    if (auth.isAuthenticated) navigateOut();
  }, [auth.loading]);

  return (
    <div className="signup_page_container">
      <Navbar />
      <div className="signup_page">
        <h2 className="signup_title">Welcome to My Bump Team</h2>
        <form onSubmit={login} className="signup_card">
          <div className="sp_input_container">
            <p>Name</p>
            <input
              id="name"
              name="name"
              onChange={updateInput}
              value={input.name}
              type="text"
              placeholder="Name"
            />
          </div>
          <div className="sp_input_container">
            <p>Email</p>
            <input
              id="email"
              onChange={updateInput}
              value={input.email}
              type="text"
              name="email"
              required
              placeholder="Email ID"
            />
          </div>
          <div className="sp_input_container">
            <p>Password</p>
            <input
              id="password"
              onChange={updateInput}
              value={input.password}
              type="password"
              name="password"
              required
              placeholder="Password"
            />
          </div>
          <button type="submit" className="sp_submit">
            Agree and Sign Up
          </button>
        </form>
        <p className="agree">
          By clicking Agree and Sign Up, you agree to the{" "}
          <span onClick={() => navigate("/termsofservice")}>Terms of Service</span> and <span onClick={() => navigate("/privacypolicy")}>Privacy Policy</span>
        </p>
        {path === "/specialist/signup" ? (
          <p className="redirect">
            Already have an account?{" "}
            <span onClick={() => navigate("/specialist/login")}>Login!</span>
          </p>
        ) : (
          <p className="redirect">
            Already have an account?{" "}
            <span onClick={() => navigate("/client/login")}>Login!</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Signup;
