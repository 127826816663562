import React, { useState } from "react";
import "./InputModal.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const InputModal = ({ setInputModal }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [PTBW, setPTBW] = useState(false);
  const [MHPW, setMHPW] = useState(false);
  const navigate = useNavigate();

  const addUser = async () => {
    await localStorage.setItem("name", JSON.stringify(name));

    // const res = await axios.post(
    //   `${process.env.REACT_APP_API_URL}/pl/addUser`,
    //   {
    //     name: name,
    //     email: email,
    //     state: state,
    //     PTBW: PTBW,
    //     MHPW: MHPW,
    //   }
    // );
    // console.log(res);
    toast.success(`Thank You ${name}`);
    window.open("https://view.flodesk.com/pages/64d52df5cb901ce9eca452db", "_blank");
  };

  const handleSubmit = () => {
    if (!name ) {
      toast.error("Please enter your name!");
    } else {
      addUser();
      setInputModal(false);
    }
  };

  return (
    <div className="input_modal">
      <div className="input_card">
        {/* <img
          src="https://res.cloudinary.com/dhhjtuhrd/image/upload/v1679362017/Logo_V2_Logo_black_ukhs8e.png"
          alt=""
          className="mbt_logo"
        /> */}
        <h2 className="im_title">
          Get ready for a personalized experience!
        </h2>
        {/* <p className="im_p">
          Be one of the first few to join MyBumpTeam.com, the only all-in-one
          platform that makes it easier for parents to plan an informed,
          stress-free, powerful birth and postpartum experience.
        </p> */}
        <div className="inputs">
          {/* <p className="im_p">
            Join Rachel, Daniel and{" "}
            <span className="link_to_instagram">872+</span> others on the
            waitlist
          </p> */}
          <input
            type="text"
            placeholder="YOUR NAME"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {/* <input
            type="email"
            placeholder="YOUR EMAIL"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="YOUR STATE"
            value={state}
            onChange={(e) => setState(e.target.value)}
          /> */}
        </div>
        {/* <div className="checks">
          <div className="check">
            <input type="checkbox" onClick={(e) => setPTBW((prev) => !prev)} />
            <p className="im_p">Join the Parent-to-be waitlist</p>
          </div>
          <div className="check">
            <input type="checkbox" onClick={(e) => setMHPW((prev) => !prev)} />
            <p className="im_p">Join the Maternal Health Provider waitlist</p>
          </div>
        </div> */}
        <button className="im_button" onClick={handleSubmit}>
          Push!
        </button>
      </div>
    </div>
  );
};

export default InputModal;
