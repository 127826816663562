import React from "react";
import "./Services.css";
import Navbar from "../../components/Navbar";
import SMCard from "./SMCard";
import ServiceCard from "./ServiceCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

const FeatureCard = ({ feature }) => {
  return (
    <div className="feature_card">
      <p>{feature}</p>
    </div>
  );
};

const Services = () => {
  const servicesList = [
    {
      title: "Doula",
      desc: "Provides physical and emotional support during pregnancy and labor while fiercely and safely advocating for a parent's desired experience.",
    },
    {
      title: "Midwife",
      desc: "Medical provider who uses low-intervention techniques to monitor pregnancy, ensure safe delivery and healthy recovery of parent & baby through the first 6 weeks postpartum.",
    },
    {
      title: "Reiki Practitioner",
      desc: "Reiki is an ancient Japanese method that promotes relaxation and healing, where the practitioner's hands hover over your body, delivering positive energy and alignment to your being. This technique is ideal for those undergoing immense stress due to past or present (or future!) factors.",
    },
    {
      title: "Webster Certified Chiropractors",
      desc: "Trained specifically to manage the changing biomechanics of the pregnant and postpartum body. They help parents decrease discomfort and assists babies into the optimal birthing position for easier delivery and less intervention. Chiropractic care is also ideal for newborn babies  with colic, reflux, breastfeeding difficulties, and sleep disturbances which can often be traced to spinal and cranial misalignments.",
    },
    {
      title: "Health & Wellness Coach",
      desc: "Your coach Initiates nutrition evaluation and guidance, meal planning, and habit tracking for your unique pregnancy,  baby's development and postpartum recovery.",
    },
    {
      title: "Sound Bath Practitioner",
      desc: "The intention of sound baths is to create a therapeutic, meditative, and immersive experience in which sound waves heal the mind and body. Sound bath sessions are best for those who deal with anxiety, depression, chronic pain and illness, and other bodily issues.",
    },
    {
      title: "Acupuncturist",
      desc: "Acupuncture requires thin needles being inserted into medicine re-balances the life force energy of those experiencing blockages due to stress, physical pain, respiratory issues, and other the specific pressure points of the skin. This method of Chinese disorders",
    },
    {
      title: "Pelvic Floor Therapist",
      desc: "An essential team member regardless of how baby was delivered who used techniques to decrease discomfort, strengthen pelvic muscles, and provide flexibility. During pregnancy, PFTs coach you to achieve a more relaxed pelvic floor by coordinating your breath, contractions and pelvic floor to help ease the baby down the birthing canal without increased tension. During postpartum PFTs work on cueing your pelvic floor to appropriately activate again while addressing pelvic pain, pubic symphysis pain, low back pain, incontinence, pain with intercourse, weakness, or diastasis recti.",
    },
    {
      title: "International Board Certified Lactation Consultant",
      desc: "Specializes in the clinical management of breastfeeding and other feeding goals while treating common & serious nursing problems.",
    },
    {
      title: "Newborn Care Specialist",
      desc: "Mothers the mother to recovery and eases the transition to parenthood by providing in-home evidenced-based information on mother-baby bonding, sleep training,  basic newborn care etc.",
    },
  ];
  function vw(percent) {
    var w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    return (percent * w) / 100;
  }

  const leftClick = () => {
    var slider = document.getElementById("sl");
    slider.scrollLeft = slider.scrollLeft - vw(24.1);
  };

  const rightClick = () => {
    var slider = document.getElementById("sl");
    slider.scrollLeft = slider.scrollLeft + vw(24.1);
  };

  const mleftClick = () => {
    var slider = document.getElementById("msl");
    slider.scrollLeft = slider.scrollLeft - vw(72);
  };

  const mrightClick = () => {
    var slider = document.getElementById("msl");
    slider.scrollLeft = slider.scrollLeft + vw(72.1);
  };

  return (
    <div className="service-page">
      <Navbar />
      <div className="services-opening">
        <div className="so-left">
          <p className="so-title">
            We know it takes a village, so let us bring your community to you.
          </p>
          <p className="so-desc">
            Pregnancy and parenthood aren’t meant to be faced alone. My Bump
            Team. not only helps you create your birth (and postpartum) team,
            but we also provide in-person & virtual access to a vibrant
            community of parents and experts that know exactly what you’re going
            through.
          </p>
          {/* <button className="so-cta">Build your Birth Team</button> */}
        </div>
        {/* <div className="so-right">asda</div> */}
      </div>
      <div className="service-mid">
        <p className="sm-title">
          {/* Our approach to a safe, empowered, low-stress full-spectrum experience */}
        </p>
        <div className="sm-cards">
          <SMCard
            className="smc-1"
            picture={
              "https://images.unsplash.com/photo-1584515933487-779824d29309?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            }
            title={"COLLABORATIVE CARE TO SURVIVE"}
            desc={
              "My Bump Team. offers more than just the typical OB/GYN experience. We believe that by integrating clinical and holistic care we provide long term overall wellness. You will have access to an entire team of advocates here for you from pregnancy to baby’s first birthday."
            }
          />
          <SMCard
            picture={
              "https://images.unsplash.com/photo-1568043625493-2b0633c7c491?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            }
            title={"AUTONOMY &  INTUITION TO THRIVE"}
            desc={
              "You know your body best. At My Bump Team. you choose your bump team with personalized data, you hone your inner-voice in local and virtual classes and you manage your birth and track milestones in your parent portal. Power to the pregnant people!"
            }
          />
        </div>
      </div>
      <div className="services-phrase">
        HOW CAN WE HELP MAKE YOUR EXPERIENCE POWERFUL?
      </div>
      <div className="feature_list">
        <FeatureCard feature={"Flexible Payments"} />
        <FeatureCard feature={"Immediate access to team of experts"} />
        <FeatureCard feature={"Personalized plan for holistic care"} />
        <FeatureCard feature={"Creating a birth fund"} />
        <FeatureCard feature={"On Demand e-learning content"} />
        <FeatureCard feature={"Build strong parenthood via local events"} />
      </div>
      <div className="services-phrase">
        OUR TRANSFORMATIVE MATERNAL HEALTH CARE SERVICES BEFORE AND BEYOND
        DELIVERY
      </div>
      <div className="services-main">
        {/* <div className="our-services">
          <p className="os-title">OUR SERVICES</p>
          <p className="os-desc">
            Before serving you, all specialists are vetted extensively and
            regularly take continuing education courses that address
            evidence-based and cultural practices.
          </p>
        </div> */}
        <div className="service-list-container">
          <div className="left arrow" onClick={leftClick}>
            <ChevronLeftIcon />
          </div>
          <div className="services-list" id="sl">
            {servicesList.map((service) => (
              <ServiceCard title={service.title} desc={service.desc} />
            ))}
          </div>
          <div className="right arrow" onClick={rightClick}>
            <ChevronRightIcon />
          </div>
        </div>
        <div className="m-service-list-container">
          <div className="left arrow" onClick={mleftClick}>
            <ChevronLeftIcon />
          </div>
          <div className="services-list" id="msl">
            {servicesList.map((service) => (
              <ServiceCard title={service.title} desc={service.desc} />
            ))}
          </div>
          <div className="right arrow" onClick={mrightClick}>
            <ChevronRightIcon />
          </div>
        </div>
      </div>
      <div className="featured-specialists">
        <div className="fs-info">
          <p className="fs-title">Featured specialists</p>
          <p className="fs-desc">
            Get to know My Bump Team’s preferred providers.
          </p>
        </div>
        <div className="services-owner-section">
          <div className="services-person">
            <div className="person-info-1">
              {/* <div className="person-name">Kanisha</div> */}
              <div className="person-desc">
                Monica, a board-certified licensed acupuncturist and certified
                postpartum doula, joined My Bump Team to provide Traditional
                Chinese Medicine to parents seeking to release tension and
                discomfort from pregnancy through postpartum. Parents who added
                her to their Bump team shared how her services help turn baby to
                avoid a C- section and treat pain during recovery.
              </div>
            </div>
            <div
              className="person-picture"
              style={{
                background:
                  "url(https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686784718/MONICA_cz4fml.png)",
              }}
            ></div>
          </div>
          <div className="services-person">
            <div
              className="person-picture"
              style={{
                background:
                  "url(https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686784733/NYA_octoxx.png)",
              }}
            ></div>
            <div className="person-info-2">
              {/* <div className="person-name">Kanisha</div> */}
              <div className="person-desc">
                Nya is a second degree Reiki Practitioner + Manifestation Coach,
                that uses reiki and womb reiki as a catalyst for self-reflection
                and meaningful change towards birth and postpartum, desires. Her
                practice is shaped by an early career in crisis intervention for
                a suicide hotline. Parents recognize her unique ability to help
                them make strong choices in stressful situations. Note: Nya
                leads community spaces for families to process their birth
                experience.
              </div>
            </div>
          </div>
        </div>
        <div className="bas-button">
          <Link to={"/client/signup"}>
            <button className="btn">Become a Member!</button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
