import React from "react";
import "./AdminSideBar.css";
import { IoIosAddCircle } from "react-icons/io";
import { AiOutlineUser, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdMessage, MdOutlineAnalytics, MdOutlineQuiz, MdPeople } from "react-icons/md";
import { MdLeaderboard } from "react-icons/md";

const AdminSideBar = () => {
  const navigate = useNavigate();

  return (
    <div className="admin-sidebar">
      <div
        className="as-option"
        onClick={() => navigate("/admin/create-course")}
      >
        <IoIosAddCircle />
        <p>Create Course</p>
      </div>
      <div className="as-option" onClick={() => navigate("/admin/courses")}>
        <AiFillEye />
        <p>Courses</p>
      </div>
      <div className="as-option" onClick={() => navigate("/admin/messages")}>
        <MdMessage />
        <p>Messages</p>
      </div>
      <div className="as-option" onClick={() => navigate("/admin/specialists")}>
        <MdPeople />
        <p>Specialists</p>
      </div>
      <div className="as-option" onClick={() => navigate("/admin/clients")}>
        <MdPeople />
        <p>Clients</p>
      </div>
      <div className="as-option" onClick={() => navigate("/admin/orders")}>
        <MdLeaderboard />
        <p>Orders</p>
      </div>
      <div className="as-option" onClick={() => navigate("/admin/quiz-statistics")}>
        <MdOutlineAnalytics />
        <p>Quiz Statistics</p>
      </div>
    </div>
  );
};

export default AdminSideBar;
