import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import SideNav from "../../components/SpecialistSideNav";
import "./SpecialistAccount.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SpecialistAccount = () => {
  const [name, setName] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [subscription, setSubscription] = useState("");
  const [bookingLink, setBookingLink] = useState("");
  const navigate = useNavigate()

  const details = {
    name: name,
    pronouns: pronouns,
    businessName: businessName,
    mailingAddress: mailingAddress,
    contact: contact,
    email: email,
    username: username,
    password: password,
    subscription: subscription,
    bookingLink: bookingLink,
  };

  const submitClicked = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/specialist/info`,
      details
    );
    navigate("/specialist/profile")
  };

  return (
    <>
      <Navbar />
      <div className="sa-container">
        <div className="specialist-acc">
          <div className="sa-input">
            <p>Full Legal Name:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="sa-input">
            <form action="#" onChange={(e) => setPronouns(e.target.value)}>
              <label htmlFor="lang">Pronouns:</label>
              <select name="pronouns" id="lang">
                <option value="He/Him">He/Him</option>
                <option value="She/Her">She/Her</option>
                <option value="They/Them">They/Them</option>
                <option value="Other">Other</option>
              </select>
              {/* <input type="submit" value="Submit" /> */}
            </form>
          </div>
          <div className="sa-input">
            <p>Business Name:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>
          <div className="sa-input">
            <p>Mailing Address:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => setMailingAddress(e.target.value)}
            />
          </div>
          <div className="sa-input">
            <p>Contact:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="sa-input">
            <p>Email:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="sa-input">
            <p>Username:</p>
            <input
              type="text"
              placeholder=""
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="sa-input">
            <p>Password:</p>
            <input
              type="password"
              placeholder=""
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="sa-input">
            <p>Direct Booking Link:</p>
            <input
              type="submit"
              placeholder=""
              onChange={(e) => setBookingLink(e.target.value)}
            />
          </div>
          <div className="sa-input">
            <form action="#" onChange={(e) => setSubscription(e.target.value)}>
              <label htmlFor="lang">Subscription:</label>
              <select name="subscription" id="lang">
                <option value="s1">sample1</option>
                <option value="s2">sample2</option>
                <option value="s3">sample3</option>
                <option value="s4">sample4</option>
              </select>
              {/* <input type="submit" value="Submit" /> */}
            </form>
          </div>
          <div
            className="sa-input"
            onClick={() => {
              submitClicked()
            }}
          >
            <p>Submit</p>
          </div>
        </div>
        <SideNav />
      </div>
    </>
  );
};

export default SpecialistAccount;
