import React from "react";
import "./ClientNeedSubscription.css"
import { useNavigate } from "react-router-dom";

const ClientNeedSubscription = () => {
  
  const navigate = useNavigate();

  return (
    <div className="client-need-sub">
      <h2>This feature is only available for subscribers</h2>
      <button onClick={() => navigate("/subscribe")}>Subscribe Now</button>
    </div>
  );
};

export default ClientNeedSubscription;
