import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QP14.css";
import quizContext from "../../contexts/QuizResult";
import QuizRedirect from "../../helpers/quizRedirect";

const QP14 = () => {
  const navigate = useNavigate();
  const [optionAge, setOptionAge] = useState(0);
  const [optionText, setOptionText] = useState("");
  const quiz = useContext(quizContext);

  const handleClick = (event) => {
    setOptionText(event.currentTarget.textContent);
  };

  const handleNext = () => {
    quiz.setResults((old) => {
      return {
        ...old,
        feeling: optionText,
      };
    });
    navigate("/quiz/page15");
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-14">
        <h1 className="qp14-title">
          How are you feeling about being pregnant?
        </h1>
        <div className="qp14-options">
          <div
            className={`qp14-option ${optionAge === 1 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(1);
              handleClick(e);
            }}
          >
            Very unsatisfied
          </div>
          <div
            className={`qp14-option ${optionAge === 2 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(2);
              handleClick(e);
            }}
          >
            Unsatisfied
          </div>
          <div
            className={`qp14-option ${optionAge === 3 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(3);
              handleClick(e);
            }}
          >
            Neutral
          </div>
          <div
            className={`qp14-option ${optionAge === 4 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(4);
              handleClick(e);
            }}
          >
            Satisfied
          </div>
          <div
            className={`qp14-option ${optionAge === 5 && "yellow-background"}`}
            onClick={(e) => {
              setOptionAge(5);
              handleClick(e);
            }}
          >
            Very Satisfied
          </div>
        </div>

        <div className="qp14-buttons">
          <button
            className="qp14-button"
            onClick={() => navigate("/quiz/page13")}
          >
            Previous Page
          </button>
          <button className="qp14-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP14;
