import React, { useContext, useEffect, useState } from "react";
import "./Signup.css";
import Navbar from "../components/Navbar";
import authContext from "../contexts/Login";
import { useLocation, useNavigate } from "react-router-dom";
const adminEmails = [
  "chiragbhalotia0412@gmail.com",
  "deep@client5.com",
  "hello@mybumpteam.com",
  "deep@specialist1.com",
];
const Login = () => {
  const auth = useContext(authContext);
  const navigate = useNavigate();

  const [input, setIntput] = useState({
    email: "",
    password: "",
  });

  const navigateOut = () => {
    if (path === "/specialist/login") {
      if (adminEmails.includes(input.email))
        navigate("/admin/courses", { replace: true });
      navigate("/specialist/profile", { replace: true });
    } else {
      navigate("/client/profile", { replace: true });
    }
  };

  const updateInput = (evt) => {
    const { name, value } = evt.target;
    setIntput((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };
  const login = async (evt) => {
    evt.preventDefault();
    auth.login(input, path === "/specialist/login", () => {
      navigateOut();
    });
  };
  const location = useLocation();
  useEffect(() => {
    if (auth.isAuthenticated) navigateOut();
  }, [auth.loading]);

  const path = location.pathname;
  return (
    <div className="signup_page_container">
      <Navbar />
      <div className="signup_page">
        <h2 className="signup_title">Welcome to My Bump Team</h2>
        <form onSubmit={login} className="signup_card">
          <div className="sp_input_container">
            <p>Email</p>
            <input
              id="email"
              onChange={updateInput}
              value={input.email}
              type="text"
              name="email"
              required
              placeholder="Email ID"
            />
          </div>
          <div className="sp_input_container">
            <p>Password</p>
            <input
              id="password"
              onChange={updateInput}
              value={input.password}
              type="password"
              name="password"
              required
              placeholder="Password"
            />
          </div>
          <button type="submit" className="sp_submit">
            Submit
          </button>
        </form>
        {path === "/specialist/login" ? (
          <p className="redirect">
            Don't have an account?
            <span onClick={() => navigate("/specialist/signup")}>Sign Up!</span>
          </p>
        ) : (
          <p className="redirect">
            Don't have an account?
            <span onClick={() => navigate("/client/signup")}>Sign Up!</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;
