import React from 'react'
import "./ServiceCard.css"

const ServiceCard = ({title, desc}) => {
  return (
    <div className='service-card'>
        <div className="sc-info">
        {/* <div className="sc-picture"></div> */}
            <div className="sci-title">{title}</div>
            <div className="sci-desc">{desc}</div>
        </div>
        {/* <button className="sc-button">Learn More</button> */}
    </div>
  )
}

export default ServiceCard