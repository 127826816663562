import { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const authContext = createContext();

export default authContext;

const getLocalJWT = () => {
  try {
    const jwt = localStorage.getItem("jwt");

    if (jwt == null || jwt == "null") {
      return null;
    } else {
      return jwt;
    }
  } catch (err) {
    return null;
  }
};
const setLocalJwt = (jwt) => {
  let JWT = jwt;
  try {
    localStorage.setItem("jwt", JWT);
  } catch (err) {
    return null;
  }
};

export const AuthContext = ({ children }) => {
  const [jwt, setJwt] = useState(getLocalJWT());
  const [user, setUser] = useState(null);
  const [specialist, setSpecialist] = useState(false);
  const [loading, setLoading] = useState(!!getLocalJWT());
  const [admin, setAdmin] = useState(false);
  const login = async (
    { email, password },
    specialist = false,
    onSuccess = () => {}
  ) => {
    let error = null;
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/${
          specialist ? "specialist" : "client"
        }/login`,
        {
          email,
          password,
        }
      )
      .then((res) => {
        setJwt(res.data);
        onSuccess();
      })
      .catch((err) => {
        error = err;
        setSpecialist(false);
        toast.error("Email Or Password Incorrect", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    return error;
  };
  const register = async (
    { email, password, name },
    specialist = false,
    onSuccess = () => {}
  ) => {
    let error = null;
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/${
          specialist ? "specialist" : "client"
        }/signup`,
        {
          email,
          password,
          name,
        }
      )
      .then((res) => {
        setJwt(res.data);
        onSuccess();
      })
      .catch((err) => {
        toast.error("An account with the email already exists. Please Login", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setSpecialist(false);
        setSpecialist(false);
        error = err;
      });
    return error;
  };
  const logout = () => {
    setJwt(null);
    setUser(null);
    setSpecialist(false);
    window.location.href = "/choose";
  };

  const fetchUser = async () => {
    setLoading(true);
    try {
      if (jwt) {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/client/me`,
          {
            headers: {
              authorization: jwt,
            },
          }
        );

        if (res?.data?.user) {
          setUser(res.data.user);
          setSpecialist(res.data.specialist);
          setAdmin(res.data.admin);
        } else {
          setUser(null);
          setJwt(null);
          setSpecialist(false);
        }
      }
    } catch (err) {
      setUser(null);
      setJwt(null);
      setLocalJwt(null);
      setSpecialist(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!jwt) {
      setUser(null);
      setLocalJwt(null);
      return;
    }
    if (jwt !== getLocalJWT()) {
      setLocalJwt(jwt);
    }
    if (jwt) fetchUser();
  }, [jwt]);
  console.log(loading, admin);
  return (
    <authContext.Provider
      value={{
        user,
        login,
        register,
        logout,
        loading,
        jwt,
        setJwt,
        admin,
        isAuthenticated: !!jwt,
        specialist,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
