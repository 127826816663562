import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import AdminSideBar from "./AdminSideBar";
import "./CreateCourse.css";
import axios from "axios";
import LoadingScreen from "../../components/Loading";
import { toast } from "react-hot-toast";
import { MdClose } from "react-icons/md";

const CreateCourse = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [image, setImage] = useState("");
  const [imagePrev, setImagePrev] = useState("");
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");

  const changeImageHandler = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePrev(reader.result);
      setImage(file);
    };
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    if (title) {
      myForm.append("title", title);
    }

    if (description) {
      myForm.append("description", description);
    }
    if (createdBy) {
      myForm.append("createdBy", createdBy);
    }
    if (image) {
      myForm.append("file", image);
    }
    const tagsJsonString = JSON.stringify(tags);
    // if (tags) {
      myForm.append("tags", tagsJsonString);
    // }
    for (var key of myForm.entries()) {
      
    }

    const createCourse = async () => {
      try {
        setLoading(true);
        const config = {
          headers: {
            "Content-type": "multipart/form-data",
          },
          withCredentials: true,
        };
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/createcourse`,
          myForm,
          config
        );
        setLoading(false);
        toast.success("course added successfully");
        // dispatch({ type: 'createCourseSuccess', payload: data.message });
      } catch (error) {
        // dispatch({
        //   type: 'createCourseFail',
        //   payload: error.response.data.message,
        // });
        setLoading(false);
        toast.error("Something went wrong, please try again later.");
        console.error(error);
      }
    };

    createCourse();
  };

  const addTag = () => {
    setTags([...tags, tagInput]);
    setTagInput("");
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  return (
    <>
      <Navbar />
      <div className="acc-container">
        <div className="admin-create-course">
          <h2 className="acc-title">Create Course</h2>
          <form onSubmit={submitHandler}>
            <div className="acc-inputs">
              <input
                type="text"
                value={title}
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                type="text"
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
              <input
                type="text"
                value={createdBy}
                placeholder="Creator Name"
                onChange={(e) => setCreatedBy(e.target.value)}
              />
              <div className="add_tag_container">
                <input
                  type="text"
                  placeholder="Add Tag"
                  className="add_tag_input"
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                />
                <span className="add_tag_button" onClick={addTag}>
                  ADD
                </span>
              </div>
              <div className="tags">
                {tags.map((tag) => (
                  <div>
                    <p>{tag}</p> <MdClose onClick={() => removeTag(tag)} />
                  </div>
                ))}
              </div>
              <label htmlFor="" className="poster-upload">
                <input
                  type="file"
                  accept="image/*"
                  required
                  placeholder="Poster"
                  onChange={changeImageHandler}
                />
                Select Poster
              </label>
              {imagePrev && <img className="poster_preview" src={imagePrev} />}

              <button type="submit">CREATE</button>
            </div>
          </form>
        </div>
        <AdminSideBar />
      </div>
      {loading && <LoadingScreen />}
    </>
  );
};

export default CreateCourse;
