import React, { useContext } from "react";
import "./Footer.css";
import {Link, useNavigate} from "react-router-dom"
import authContext from "../contexts/Login";

const Footer = () => {
  const navigate = useNavigate()
  const auth = useContext(authContext)
  return (
    <div className="footer">
      <p onClick={() => navigate("/termsofservice")}>Terms of Service</p>
      <p onClick={() => navigate("/privacypolicy")}>Privacy Policy</p>
      <Link
        to={
          auth.isAuthenticated ? "/specialist/profile" : "/specialist/signup"
        }
      >
        <p>Become a provider</p>
      </Link>
      {/* <p>Contact Us</p> */}
      <a href="mailto:hello@mybumpteam.com" target="_blank">Contact Us</a>
    </div>
  );
};

export default Footer;
