import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./QP7.css";
import { toast } from "react-hot-toast";
import quizContext from "../../contexts/QuizResult";
import axios from "axios";
import authContext from "../../contexts/Login";
import QuizRedirect from "../../helpers/quizRedirect";

const QP7 = () => {
  const navigate = useNavigate();

  const options = [];
  const quiz = useContext(quizContext);

  const auth = useContext(authContext);

  const addSuggestion = async (suggestion) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/client/addToQuizSuggestions`,
        {
          suggestion: suggestion,
        },
        {
          headers: { authorization: auth.jwt },
        }
      );
    } catch (error) {}
  };

  const handleNext = async () => {
    if (options.length > 0) {
      const suggestions = ["Health & Wellness Coach", "Midwife", "Doula"];
      suggestions.map(async (suggestion) => {
        await addSuggestion(suggestion);
      });

      toast.success(
        "Added Health & Wellness Coach, Doula & Midwife based on inputs!"
      );
    }
    quiz.setResults((old) => {
      return {
        ...old,
        highRiskPregnancyFactors: options,
      };
    });
    navigate("/quiz/page8");
  };

  const Risks = [
    "Diabetes",
    "Pre-diabetic",
    "Asthma",
    "High Blood Pressure",
    "Epilepsy",
    "Seizures",
    "Stroke",
    "Heart Attack",
    "Sickle Cell",
    "Alcohol Use",
    "Cigarette Use",
    "Recreational drug use",
    "OTHER",
    "NONE",
  ];

  const handleChange = (e, risk) => {
    if (e.target.checked) {
      if (!options.includes(risk)) {
        options.push(risk);
      }
    } else {
      if (options.includes(risk)) {
        options.splice(options.indexOf(risk), 1);
      }
    }
  };

  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="quiz-page-7">
        <h1 className="qp7-title">
          Significant Past or Current High Risk Pregnancy Factors (Select All
          That Apply){" "}
        </h1>
        <div className="qp7-options">
          {Risks.map((risk) => (
            <div className="qp7-option">
              <input type="checkbox" onChange={(e) => handleChange(e, risk)} />
              <p>{risk}</p>
            </div>
          ))}
        </div>

        <div className="qp7-buttons">
          <button
            className="qp7-button"
            onClick={() => navigate("/quiz/page6")}
          >
            Previous Page
          </button>
          <button className="qp7-button" onClick={handleNext}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default QP7;
