import React, { useContext } from "react";
import authContext from ".";
import LoadingScreen from "../../components/Loading";
import { Navigate } from "react-router-dom";


function AuthProtect({ children,clientOnly=false,specialistOnly=false }) {
  const auth = useContext(authContext);
  if(auth.loading){
    return <LoadingScreen />
  }
  if(!auth.isAuthenticated){
    return <Navigate to={specialistOnly?"/specialist/signup":"/client/signup"} />
  }
  if(clientOnly && auth.specialist){
    return  <Navigate to="/specialist/profile" replace={true} />
  }
  if (specialistOnly && !auth.specialist) {
    return <Navigate to="/client/profile" replace={true} />;
  }
  return <>{children}</>;
}

export default AuthProtect;
