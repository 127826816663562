import React from "react";
import { useNavigate } from "react-router-dom";
import "./QuizDesc.css";
import QuizRedirect from "../../helpers/quizRedirect";

const QuizDesc = ({title, desc, page}) => {
  const navigate = useNavigate();
  return (
    <div className="quiz-container">
      <QuizRedirect />
      <div className="qd">
        <h1 className="qd-title">{title}</h1>
        <p className="qd-desc">{desc}</p>
        <button
          className="qd-button"
          onClick={() => navigate(`/quiz/page${page}`)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default QuizDesc;
