import React, { useContext, useEffect, useRef, useState } from "react";
// import "./ProfilePageModal.css";
import "./AdminSpecialistProfileModal.css";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authContext from "../../contexts/Login";
import ReviewsModal from "./ReviewsModal";
import RejectionsModal from "./RejectionsModal";

const AdminSpecialistProfileModal = ({ id, setProfileModal }) => {
  const payoutRef = useRef();
  const [profile, setProfile] = useState("");
  const auth = useContext(authContext);
  const navigate = useNavigate();
  const [reviewsModal, setReviewsModal] = useState(false);
  const [rejectionsModal, setRejectionsModal] = useState(false);

  const getSpecialistData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/specialist/getSpecialistData/${id}`
    );
    setProfile(response.data);
  };

  const handleApprove = async () => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/specialist/approve/${id}`
    );
    toast.success("Specialist approved");
    setProfileModal(false);
  };

  const handlePayout = async (evt) => {
    try {
      evt.preventDefault();
      console.log(payoutRef.current);
      const amount = Math.floor((payoutRef.current?.value ?? 0) * 100);
      if (amount > profile.payoutPending || amount <= 0) {
        toast.warn("Invalid payout amount!");
        return;
      }
      await axios.post(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/stripe/transfer-specialist`,
        {
          specialistId: id,
          amount,
        },
        {
          headers: {
            authorization: auth.jwt,
          },
        }
      );
      toast.success("Transfer successfull");
      window.location.reload();

    } catch (err) {
      toast.error("Error during transfer");
    }
  };

  useEffect(() => {
    getSpecialistData();
  }, []);

  if (profile) {
    return (
      <div className="profile_modal">
        <div className="pm_left">
          <div
            className="pm_picture"
            style={{ background: `url(${profile.headshot})` }}
          ></div>
          <div className="pm_info">
            <p className="pmi_head">Location</p>
            <p className="pmi_info">{profile?.location}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Pronouns</p>
            <p className="pmi_info">{profile?.pronouns}</p>
          </div>
          {!profile?.approved ? (
            <button className="pm_add pm_approve" onClick={handleApprove}>
              Approve
            </button>
          ) : (
            <p
              className="pm_approve"
              style={{ color: "green", fontWeight: "500" }}
            >
              Approved
            </p>
          )}
          <button
            className="pm_add pm_approve"
            style={{ display: "block" }}
            onClick={() => setReviewsModal(true)}
          >
            See Reviews
          </button>
          <button
            className="pm_add pm_approve"
            onClick={() => setRejectionsModal(true)}
          >
            See Rejections
          </button>
        </div>
        <div className="pm_right">
          <div className="pr_head">
            <p className="pm_name">{profile?.name}</p>
            <MdClose onClick={() => setProfileModal(false)} />
          </div>
          <p className="pm_specialty">{profile?.specialty}</p>
          <div className="pm_info">
            <p className="pmi_head">Bio</p>
            <p className="pmi_info">{profile?.bio}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Business Name</p>
            <p className="pmi_info">{profile?.businessName}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Mailing Address</p>
            <p className="pmi_info">{profile?.mailingAddress}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Contact</p>
            <p className="pmi_info">{profile?.contact}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Email</p>
            <p className="pmi_info">{profile?.email}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Fee</p>
            <p className="pmi_info">{profile?.fee / 100}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Years In Practice</p>
            <p className="pmi_info">{profile?.yearsInPractice}</p>
          </div>
          <div className="pm_info">
            <p className="pmi_head">Documents</p>
            {profile?.documents.map((doc) => (
              <p
                className="pmi_info pmi_doc"
                onClick={() => {
                  window.open(doc.url);
                }}
              >
                {doc.name}
              </p>
            ))}
          </div>
          <div className="pm_info">
            <p className="pmi_head">Payouts:</p>
            <div className="pmi_payouts">
              <p>Payout Pending: ${profile?.payoutPending / 100 || 0}</p>

              <p>Payout Sent: ${profile?.payoutReceived / 100 || 0}</p>
            </div>
            <p className="ip_head">Initiate Payout:</p>
            <form onSubmit={handlePayout} className="initiate_payout">
              <input
                type="number"
                ref={payoutRef}
                placeholder="Enter Amount"
                className="input_payout"
              />
              <button type="submit" className="confirm_payout">
                Confirm Payout
              </button>
            </form>
          </div>
        </div>
        {reviewsModal && (
          <ReviewsModal speciliastId={id} setReviewsModal={setReviewsModal} />
        )}
        {rejectionsModal && (
          <RejectionsModal
            speciliastId={id}
            setRejectionsModal={setRejectionsModal}
          />
        )}
      </div>
    );
  }
};

export default AdminSpecialistProfileModal;
