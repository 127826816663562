import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import SideNav from "../../components/SpecialistSideNav";
import "./SpecialistProfile.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import axios from "axios";
import authContext from "../../contexts/Login";
import { toast } from "react-toastify";
import LoadingScreen from "../../components/Loading";
import SubscribeButton from "../../components/SubscribeButton";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useNavigate } from "react-router-dom";

const SpecialistProfile = () => {
  const [headshot, setHeadshot] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [contact, setContact] = useState("");
  const [subscription, setSubscription] = useState("Inactive");
  const [changePassword, setChangePassword] = useState("");

  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [filePrev, setFilePrev] = useState("");
  const [location, setLocation] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [fee, setFee] = useState("");
  const [bio, setBio] = useState("");
  const [yearsInPractice, setYearsInPractice] = useState("");
  const [training, setTraining] = useState("");
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [servicesOffered, setServicesOffered] = useState("");
  // const [clientTestimonials, setClientTestimonials] = useState([]);
  // const [email, setEmail] = useState("");
  const auth = useContext(authContext);
  const navigate = useNavigate()


  const details = {
    // headshot: headshot,
    location: location,
    specialty: specialty,
    fee: +fee * 100,
    bio: bio,
    training: training,
    languages: languages,
    contact: contact,
    pronouns: pronouns,
    mailingAddress: mailingAddress,
    yearsInPractice: yearsInPractice,
  };

  const specialistCategories = [
    "Doula",
    "Midwife",
    "Reiki Practitioner",
    "Webster Certified Chiropractor",
    "Health & Wellness Coach",
    "Sound Bath Practitioner",
    "Acupuncturist",
    "Pelvic Floor Therapist",
    "International Board Certified Lactation Consultant",
    "Newborn Care Specialist",
  ];
  const submitClicked = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/specialist/infoupdate`,
      details,
      {
        headers: { authorization: auth.jwt },
      }
    );
    toast.success("Info Updated!");
  };

  const uploadHeadshot = async () => {
    setLoading(true);
    try {
      var formData = new FormData();
      formData.append("file", file);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/specialist/updateHeadshot`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // authorization: auth.jwt,
            Authorization: `${auth.jwt}`,
          },
        }
      );
      setFile("");
    } catch (err) {}

    setLoading(false);
  };

  const getSpecialistData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/specialist/getSpecialistData`,
      {
        headers: { authorization: auth.jwt },
      }
    );

    const specialist = response.data;
    setHeadshot(specialist.headshot);
    setPronouns(specialist.pronouns);
    setMailingAddress(specialist.mailingAddress);
    setContact(specialist.contact);
    setLocation(specialist.location);
    setSpecialty(specialist.specialty);
    setFee(specialist.fee / 100);
    setBio(specialist.bio);
    setYearsInPractice(specialist.yearsInPractice);
    setTraining(specialist.training);
    setName(specialist.name);
  };

  useEffect(() => {
    getSpecialistData();
  }, [loading]);

  const changeFileHandler = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // console.log("w1");
    reader.onloadend = () => {
      // console.log("w2");

      // console.log(reader.result);
      // console.log(file);
      setFilePrev(reader.result);
      setFile(file);
      setHeadshot(reader.result);
    };
    setLoading(false);
  };
  const connectToStripe = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/stripe/connect-stripe`,
        {},
        {
          headers: { authorization: auth.jwt },
        }
      );
      window.location.href = response.data.url;
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <>
      <Navbar />
      <div className="sp-container">
        <div className="sp-preview">
          <div
            className="sp-image-container"
            style={{
              background: `url(${headshot})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="sp-info">
            {/* <div className="sp-name-age">{"Name"}</div> */}
            <div className="sp-name-age">{name || "Name"}</div>
            <div className="sp-credentials">
              <div className="spc-intro spc-option">
                <PermIdentityIcon />
                <p>{pronouns || "Pronouns"}</p>
              </div>
              <div className="spc-education spc-option">
                <WorkOutlineIcon />
                <p>{specialty || "Specialty"}</p>
              </div>
              <div className="spc-location spc-option">
                <LocationOnOutlinedIcon />
                <p>{location || "New York"}</p>
              </div>
            </div>
            <div className="line"></div>
            <div className="sp-desc">
              {bio ||
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique eaque nemo cupiditate laborum doloremque suscipit praesentium sunt nesciunt beatae architecto? Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae quam libero, amet est officia exercitationem modi autem culpa eveniet corrupti ipsam ut fugit porro impedit soluta vitae! Veritatis aliquam officiis, perferendis esse possimus itaque est dignissimos debitis sed, expedita similique? Eveniet vel quisquam ipsum, animi rerum ratione vero, culpa odio laborum autem reiciendis! Rerum corrupti tempora dolore! Sint porro rerum temporibus doloribus eligendi qui. Optio iste eius minima corporis, nemo, vitae quo facilis odio est asperiores voluptatibus at dolor iure dignissimos molestias porro eveniet minus placeat repellat, eaque iusto temporibus veritatis incidunt. Itaque ullam dolorum, omnis aperiam nisi cumque porro.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et nam voluptatum veniam doloribus a vel, asperiores ea aliquam iure. Ut saepe voluptatem reprehenderit cupiditate minima. Repellendus maxime commodi, rem quod dolorem eveniet nesciunt fuga non optio adipisci libero est facere expedita quo eos repudiandae alias reprehenderit nostrum, neque, voluptatibus ut. Adipisci doloribus sint vitae, totam cum quas nulla! Inventore aperiam, nihil dolores saepe vitae neque harum iste facilis libero beatae officia autem! Dolores assumenda illo libero maiores, nemo quia eos enim, voluptate iusto fugiat incidunt commodi harum tenetur dicta odit optio quaerat? Necessitatibus odit molestiae dolorum quae natus hic voluptates!"}
            </div>
          </div>
          {/* <div className="bottom-nav">
            <p>
              <CloseIcon />
            </p>
            <p>
              <StarRateRoundedIcon />
            </p>
            <p>
              <FavoriteRoundedIcon />
            </p>
          </div> */}
        </div>
        <div className="specialist-profile">
          <div className="sp-input">
            <p>Headshot:</p>
            {file ? (
              <button className="finalize-headshot" onClick={uploadHeadshot}>
                Finalize
              </button>
            ) : (
              <label htmlFor="file-upload" className="custom-file-upload">
                <input
                  type="file"
                  accept="application/pdf, application/msword, image/*"
                  onChange={changeFileHandler}
                  className="file-input"
                  id="file-upload"
                />
                Add Image
              </label>
            )}
          </div>

          {/* <div className="sp-input">
            <p>Headshot:</p>
            <input
              type="text"
              placeholder=""
              value={headshot}
              onChange={(e) => {
                setHeadshot(e.target.value);
              }}
            />
          </div> */}

          <div className="sp-input">
            <p>Name:</p>
            <input
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className="sp-input">
            <form
              action="#"
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            >
              <label htmlFor="lang">Location:</label>
              <select value={location} name="location" id="lang">
                <option value="None">None</option>
                <option value="Houston">Houston</option>
                <option value="Dallas">Dallas</option>
                <option value="Boston">Boston</option>
                <option value="New York">New York</option>
                <option value="Maryland">Maryland</option>
                <option value="Washington D.C">Washington D.C</option>
              </select>
            </form>
          </div>
          <div className="sp-input">
            <form action="#" onChange={(e) => setPronouns(e.target.value)}>
              <label htmlFor="lang">Pronouns:</label>
              <select value={pronouns} name="pronouns" id="lang">
                <option value="None">None</option>

                <option value="He/Him">He/Him</option>
                <option value="She/Her">She/Her</option>
                <option value="They/Them">They/Them</option>
                <option value="Other">Other</option>
              </select>
              {/* <input type="submit" value="Submit" /> */}
            </form>
          </div>
          <div className="sp-input">
            <form
              action="#"
              onChange={(e) => {
                setSpecialty(e.target.value);
              }}
            >
              <label htmlFor="lang">Specialty:</label>
              <select value={specialty} name="specialty" id="lang">
                <option value="None">None</option>

                {specialistCategories.map((category) => (
                  <option value={category}>{category}</option>
                ))}
                {/* <option value="s1">sample1</option>
                <option value="s2">sample2</option>
                <option value="s3">sample3</option>
                <option value="s4">sample4</option> */}
              </select>
              {/* <input type="submit" value="Submit" /> */}
            </form>
          </div>

          <div className="sp-input">
            <p>Fee:</p>
            <input
              type="text"
              value={fee}
              placeholder=""
              onChange={(e) => {
                setFee(e.target.value);
              }}
            />
          </div>
          <div className="sp-input">
            <p>Bio:</p>
            <input
              value={bio}
              type="text"
              placeholder=""
              onChange={(e) => {
                setBio(e.target.value);
              }}
            />
          </div>
          <div className="sp-input">
            <p>Years in Practice:</p>
            <input
              type="text"
              value={yearsInPractice}
              placeholder=""
              onChange={(e) => {
                setYearsInPractice(e.target.value);
              }}
            />
          </div>
          <div className="sp-input">
            <p>Training:</p>
            <input
              type="text"
              value={training}
              placeholder=""
              onChange={(e) => {
                setTraining(e.target.value);
              }}
            />
          </div>
          <div className="sp-input">
            <p>Mailing Address:</p>
            <input
              type="text"
              value={mailingAddress}
              placeholder=""
              onChange={(e) => setMailingAddress(e.target.value)}
            />
          </div>
          <div className="sp-input">
            <p>Contact:</p>
            <input
              type="text"
              placeholder=""
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="cp-input">
            <p>Change Password:</p>
            <input
              type="password"
              placeholder=""
              value={changePassword}
              onChange={(e) => setChangePassword(e.target.value)}
            />
            <button
              className="sub_button"
              style={{ marginTop: "0px", marginLeft: "5px" }}
              onClick={async () => {
                try {
                  const { data } = await axios.post(
                    `${process.env.REACT_APP_API_URL}/specialist/changePassword`,
                    { password: changePassword },
                    {
                      headers: { authorization: auth.jwt },
                    }
                  );
                  toast.success("Password changed successfully");
                  setChangePassword("");
                  auth.setJwt(data);
                } catch (err) {
                  console.error(err);
                  toast.error("Error changing password");
                }
              }}
            >
              Save
            </button>
          </div>
          <div className="sp-input">
            <p>Subscription Status:</p>
            <p>{subscription}</p>
          </div>
          <button className="sp-submit-button" onClick={submitClicked}>
            Submit
          </button>

          {auth?.admin && <button className="sp-submit-button" onClick={() => navigate("/admin/courses")}>
            Super User Portal
          </button>}
          <button
            className="sp-submit-button sp-logout"
            onClick={() => {
              auth.logout();
            }}
          >
            Logout
          </button>

          <div>
            <SubscribeButton />
          </div>
          {!auth.user.payoutsEnabled && (
            <button onClick={connectToStripe} className="connect_button">
              Connect to Stripe
            </button>
          )}

          <p className="white-space">yes</p>
        </div>
        <SideNav />
        {loading && <LoadingScreen />}
      </div>
    </>
  );
};

export default SpecialistProfile;
