import React from "react";
import { useNavigate } from "react-router-dom";
import "./ClientSideNav.css";

const ClientSideNav = () => {
  const navigate = useNavigate();
  return (
    <div className="sideNav">
      {/* <div className="sn-option" onClick={() => navigate("/client/account")}>
        My Account
      </div> */}
      <div className="sn-option" onClick={() => navigate("/client/profile")}>
        Profile
      </div>
      <div
        className="sn-option"
        onClick={() => navigate("/client/find-matches")}
      >
        Find Matches
      </div>
      <div className="sn-option" onClick={() => navigate("/client/inquiries")}>
        Specialist Inquiries
      </div>
      <div className="sn-option" onClick={() => navigate("/client/chat")}>
        Chat
      </div>
      <div
        className="sn-option"
        onClick={() => navigate("/client/my-birth-team")}
      >
        My Bump Team
      </div>
      <div className="sn-option" onClick={() => navigate("/client/cart")}>
        Cart
      </div>
      <div className="sn-option" onClick={() => navigate("/client/education")}>
        Education
      </div>
      <div className="sn-option" onClick={() => navigate("/client/documents")}>
        Documents
      </div>
      <div className="sn-option" onClick={() => navigate("/client/help")}>
        Help
      </div>
    </div>
  );
};

export default ClientSideNav;
