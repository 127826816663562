import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authContext from "../../contexts/Login";
import axios from "axios";
import LoadingScreen from "../../components/Loading";

const SubscribeModal = ({ setSModal }) => {
  const navigate = useNavigate();
  return (
    <div className="subscribe_modal" onClick={() => setSModal(false)}>
      <p>
        Please <span onClick={() => navigate("/subscribe")}>subscribe</span> to
        access this course.
      </p>
    </div>
  );
};

const EclCard = ({ title, desc, posterUrl, id, creator }) => {
  const navigate = useNavigate();
  const auth = useContext(authContext);
  const [client, setClient] = useState();
  const [sModal, setSModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const getClientData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/getClientData`,
      {
        headers: { authorization: auth.jwt },
      }
    );
    setClient(response.data);
  };

  const getSpecialistData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/specialist/getSpecialistData`,
      {
        headers: { authorization: auth.jwt },
      }
    );
    setClient(response.data);
  };
  const isSpecialist = auth?.specialist;

  const addToPlaylist = async (req, res) => {
    setLoading(true);
    try {
      if (isSpecialist) {
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/specialist/addToPlaylist`,
          { course: id },
          {
            headers: { authorization: auth.jwt },
          }
        );
      } else {
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/client/addToPlaylist`,
          { course: id },
          {
            headers: { authorization: auth.jwt },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (isSpecialist) {
      getSpecialistData();
    } else {
      getClientData();
    }
  }, [loading, auth]);

  return (
    // <div className='ecl-card' onClick={() => navigate(`/course/${id}/0`)}>
    <div className="ecl-card">
      <div
        className="eclc-picture"
        style={{ background: `url(${posterUrl})` }}
        onClick={() => {
          if (client.subscriptionStatus === "active") {
            navigate(`/course/${id}/0`);
          } else {
            setSModal(true);
          }
        }}
      ></div>
      <div
        className="eclc-info"
        onClick={() => {
          if (auth.isAuthenticated) {
            if (auth.user.subscriptionStatus === "active") {
              return navigate(`/course/${id}/0`);
            }
            return navigate(`/subscribe`);
          }
          return navigate(`/client/login`);
        }}
      >
        <h1 className="eclc-title">{title}</h1>
        <p className="eclc-desc">{desc}</p>
        <div className="eclc-footer">
          {/* <p>Made By:</p> */}
          <p>{creator}</p>
        </div>
      </div>
      {!client?.playlist?.includes(id) ? (
        <button className="eclc_button" onClick={() => addToPlaylist()}>
          Add to Playlist
        </button>
      ) : (
        <button className="eclc_button">Added to Playlist</button>
      )}
      {sModal && <SubscribeModal setSModal={setSModal} />}
      {loading && <LoadingScreen />}
    </div>
  );
};

export default EclCard;
