import React from 'react'
import "./EmptyRegionModal.css"

const EmptyRegionModal = ({message}) => {
  return (
    <div className='empty_region'>
        <p>{message}</p>
    </div>
  )
}

export default EmptyRegionModal